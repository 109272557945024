.staff-selection {
    .staff-selection-header {
        text-transform: none;
        font-weight: 700;
        padding-bottom: 0.5rem;
    }

    .staff-selection-dropdown {
        background-color: white;
    }

    .staff-selection-value {
        border-top: 0;
        padding: 0.25rem 0.5rem;
        background: white;
        border-radius: 3px;
        position: relative;

        .value {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto;
            grid-template-areas:
                    "avatar name"
                    "avatar position";
            grid-gap: 0 0.25rem;
            margin-top: 0.25rem;

            .avatar {
                grid-area: avatar;
                display: grid;
                align-items: center;
            }
            .name {
                grid-area: name;
                line-height: 18px;
            }
            .position {
                grid-area: position;
                line-height: 18px;
                font-size: 14px;
                font-weight: 300;
                color: rgba(0,0,0,0.55);
            }
        }

        .remove-icon {
            position: absolute;
            right: 0.5rem;
            top: 25%;

            &:hover {
                cursor: pointer;
            }
        }
    }
}