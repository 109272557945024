.evaluators-thumbnails {
  .value {
    line-height: 0;
    min-height: 36px;
    overflow: unset;

    position: relative;

    .no-evaluator {
      line-height: 2rem;
      color: black;
    }

    .evaluators-more {
      position: absolute;
      bottom: 1.1rem;
      text-transform: initial;
    }
  }

  .react-tooltip {
    display: inline-block;

    &~ .react-tooltip {
      margin-left: -6px;
    }

    .react-avatar {
      &:hover, &:focus {
        z-index: 2;

        + .description {
          opacity: 0.9;
        }
      }
    }

    .description {
      z-index: 1;
      transition: all 0s linear;
      top: 6px;
      height: 1.5rem;
      line-height: 1rem;
    }
  }
}