@import "../../styles/breakpoints";
@import "../../styles/common";

$primary-color: #00A1DF;

.candidates-table {
    border-radius: 3px;
    @include boxShadow();
    background-color: white;

    @include upTo($screen-desktop) {
        margin: 0 0.5rem;
    }
    @include from($screen-desktop) {
        margin: 1rem 1.5rem;
    }

    > .header-mobile {
        padding: 8px 12px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;

        @include from($screen-desktop) {
            display: none;
        }
    }

    > .header {
        padding: 8px 12px;
        border-bottom: 1px solid $header-border-color;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 12px;
        height: 32px;

        .subtitle {
            justify-self: left;

            .interview_info {
                color: $grey-color-light;

                .interview_name {
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                        color: initial;
                    }
                }
            }
        }

        .title {
            font-weight: 600;
            justify-self: center;
            text-transform: capitalize;

            @include upTo($screen-desktop) {
                visibility: hidden;
            }
        }

        .menu {
            justify-self: right;
            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-template-areas: "search filter actions";
            grid-gap: 8px;

            .search {
                grid-area: search;
                display: none;

                width: 28px !important;
                text-align: center;
                top: 2px;

                // for coming soon
                overflow: unset;
                .item {
                    margin-top: 1px;
                    opacity: 0.2;
                }
            }
            .filter {
                grid-area: filter;
                display: none;

                width: 54px;
                text-align: center;
                // position: relative;
                // opacity: 0.2;

                // for coming soon
                overflow: unset;
                .item {
                    opacity: 0.2;

                    svg {
                        top: -2px;
                        left: -4px;
                    }
                }

                svg {
                    position: relative;
                    top: -1px;
                }

                .label {
                    padding-left: 0.25rem;
                    text-transform: uppercase;
                    font-weight: 400;
                    user-select: none;
                }
            }
            .sort {
                grid-area: sort;
                display: none;

                .sort-dropdown {
                    width: 54px;

                    .selector {
                        padding: 0;
                    }

                    .selections {
                        width: 135px;
                        max-height: 100vh;
                    }

                    .firstLine {
                        text-transform: capitalize;
                    }
                }

                svg {
                    position: relative;
                    top: 1px;
                }
            }
            .actions {
                grid-area: actions;

                .actions-dropdown {
                    width: 28px;

                    .placeholder {
                        line-height: 35% !important;
                        font-size: 18px;
                    }

                    .selections {
                        width: 80px;

                        .selection {
                            .firstLine {
                                text-align: right;
                            }
                        }
                    }
                }
            }

            .sort, .actions {
                .sort-dropdown, .actions-dropdown {
                    .selector {
                        font-size: 12px;
                        font-weight: normal;
                        height: 18px;
                        background-color: transparent;

                        &:hover, &:focus {
                            .dropdown-overlay {
                                background: transparent;
                            }
                        }

                        .placeholder {
                            line-height: initial;
                            font-weight: 300;

                            .label {
                                padding-left: 0.25rem;
                                font-weight: 400;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    &.candidates-responded {
        > .header {
            .menu {
                .actions {
                    .actions-dropdown {
                        .selections {
                            width: 80px;
                            a {
                                .firstLine {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }

        ~ .candidates-responded {
            margin-top: calc(-1rem + 1px);

            .title {
                opacity: 0;
            }
        }
    }

    &.candidates-invited {
        ~ .candidates-invited {
            margin-top: calc(-1rem + 1px);

            .title {
                opacity: 0;
            }
        }
    }

    --table-grid-gap: 6px;

    @include from($screen-desktop) {
        --table-grid-gap: 12px;
    }

    --table-avatar: calc(24px + var(--table-grid-gap));
    --table-actions: 36px;

    --table-phone: 242px; // 120px;

    --table-interview-at: 242px;

    --table-score: 24px; // 35px;

    --table-invite-sent-on: 124px;
    --table-deadline: 186px;
    --table-deadline-mobile: 120px;

    --table-recommendation: 124px;
    --table-live-button: 24px;
    --table-evaluation-or-decision: 186px;
    --table-recommendation-mobile: 80px;
    --table-evaluation-or-decision-mobile: 186px;

    --table-rest: calc(
        100%
        - var(--table-avatar)
        - var(--table-actions)
        - var(--table-invite-sent-on)
        - var(--table-deadline-mobile)
        - (4 * var(--table-grid-gap))
    );
    --table-name: var(--table-rest);
    --table-candidate: calc(var(--table-name) + var(--table-actions) + var(--table-grid-gap));

    @include from($screen-desktop) {
        --table-rest: calc(
            100%
            - var(--table-avatar)
            - var(--table-actions)
            - var(--table-phone)
            - var(--table-invite-sent-on)
            - var(--table-live-button)
            - var(--table-deadline)
            - (7 * var(--table-grid-gap))
        );
        --table-name: calc(var(--table-rest) * 1/4);
        --table-email: calc(var(--table-rest) * 3/4);
        --table-candidate: calc(var(--table-name) + var(--table-actions) + var(--table-grid-gap));
    }

    .responded, .evaluating {
        --table-rest: calc(
            100%
            - var(--table-avatar)
            - var(--table-actions)
            - var(--table-recommendation-mobile)
            - var(--table-evaluation-or-decision-mobile)
            - (4 * var(--table-grid-gap))
        );
        --table-name: var(--table-rest);
        --table-candidate: calc(var(--table-name) + var(--table-actions) + var(--table-grid-gap));

        @include from($screen-desktop) {
            --table-rest: calc(
                100%
                - var(--table-avatar)
                - var(--table-actions)
                - var(--table-phone)
                - var(--table-recommendation)
                - var(--table-live-button)
                - var(--table-evaluation-or-decision)
                - (7 * var(--table-grid-gap))
            );
            --table-name: calc(var(--table-rest) * 1/4);
            --table-email: calc(var(--table-rest) * 3/4);
            --table-questions-scores: calc(var(--table-email) + var(--table-phone) + var(--table-grid-gap));
        }
    }

    .live-interview {
        --table-rest: calc(
            100%
            - var(--table-avatar)
            - var(--table-actions)
            - var(--table-interview-at)
            - var(--table-recommendation-mobile)
            - var(--table-evaluation-or-decision-mobile)
            - (5 * var(--table-grid-gap))
        );
        --table-name: var(--table-rest);
        --table-candidate: calc(var(--table-name) + var(--table-actions) + var(--table-grid-gap));

        @include from($screen-desktop) {
            --table-rest: calc(
                100%
                - var(--table-avatar)
                - var(--table-actions)
                - var(--table-interview-at)
                - var(--table-recommendation)
                - var(--table-live-button)
                - var(--table-evaluation-or-decision)
                - (7 * var(--table-grid-gap))
            );
            --table-name: calc(var(--table-rest) * 1/4);
            --table-email: calc(var(--table-rest) * 3/4);
            --table-candidate: calc(var(--table-name) + var(--table-actions) + var(--table-grid-gap));
        }
    }

    .candidate-loading {
        padding: 8px;
        text-align: center;
    }

    .candidate-header {
        font-size: 11px;
        color: #7d8b92;
        text-transform: uppercase;
        display: grid;
        transition: all 0.4s ease-out;
        position: relative;
        grid-gap: var(--table-grid-gap) 0;

        @include from($screen-desktop) {
            grid-gap: 0;
        }

        > * {
            padding: 12px 3px;
            user-select: none;

            @include from($screen-desktop) {
                padding: 12px 0.5rem;
            }

            &:first-child {
                padding-left: 12px;

                @include from($screen-desktop) {
                    padding-left: 18px;
                }
            }

            &:last-child {
                padding-right: 0.75rem;
            }
        }

        &.invited {
            grid-template-columns:
                calc(var(--table-avatar) + 0.5 * var(--table-grid-gap))
                calc(var(--table-candidate) + var(--table-grid-gap))
                calc(var(--table-invite-sent-on) + var(--table-grid-gap))
                // calc(var(--table-live-button) + var(--table-grid-gap))
                calc(var(--table-deadline-mobile) + 0.5 * var(--table-grid-gap));
            grid-template-areas: "header-selection header-candidate header-invite-sent-on header-deadline";

            @include from($screen-desktop) {
                grid-template-columns:
                    calc(var(--table-avatar) + 0.5 * var(--table-grid-gap))
                    calc(var(--table-candidate) + var(--table-grid-gap))
                    calc(var(--table-email) + var(--table-grid-gap))
                    calc(var(--table-phone) + var(--table-grid-gap))
                    calc(var(--table-invite-sent-on) + var(--table-grid-gap))
                    calc(var(--table-live-button) + var(--table-grid-gap))
                    calc(var(--table-deadline) + 0.5 * var(--table-grid-gap));
                grid-template-areas: "header-selection header-candidate header-email header-phone header-invite-sent-on header-live-button header-deadline";
            }
        }

        &.responded, &.evaluating {
            grid-template-columns:
                calc(var(--table-avatar) + 0.5 * var(--table-grid-gap))
                calc(var(--table-candidate) + var(--table-grid-gap))
                calc(var(--table-recommendation-mobile) + var(--table-grid-gap))
                // calc(var(--table-live-button) + var(--table-grid-gap))
                calc(var(--table-evaluation-or-decision-mobile) + 0.5 * var(--table-grid-gap));
            grid-template-areas: "header-selection header-candidate header-recommendation header-evaluation-or-decision";

            @include from($screen-desktop) {
                grid-template-columns:
                    calc(var(--table-avatar) + 0.5 * var(--table-grid-gap))
                    calc(var(--table-candidate) + var(--table-grid-gap))
                    calc(var(--table-questions-scores) + var(--table-grid-gap))
                    calc(var(--table-recommendation) + var(--table-grid-gap))
                    calc(var(--table-live-button) + var(--table-grid-gap))
                    calc(var(--table-evaluation-or-decision) + 0.5 * var(--table-grid-gap));
                grid-template-areas: "header-selection header-candidate header-questions-scores header-recommendation header-live-button header-evaluation-or-decision";
            }
        }

        &.evaluating {
            grid-template-areas: "header-candidate header-candidate header-recommendation header-evaluation-or-decision";

            @include from($screen-desktop) {
                grid-template-areas: "header-candidate header-candidate header-questions-scores header-recommendation header-recommendation header-evaluation-or-decision";
            }

            .header-selection {
                display: none;
            }
        }

        &.live-interview {
            grid-template-columns:
                calc(var(--table-avatar) + 0.5 * var(--table-grid-gap))
                calc(var(--table-candidate) + var(--table-grid-gap))
                calc(var(--table-interview-at) + var(--table-grid-gap))
                calc(var(--table-recommendation-mobile) + var(--table-grid-gap))
                calc(var(--table-evaluation-or-decision-mobile) + 0.5 * var(--table-grid-gap));
            grid-template-areas: "header-selection header-candidate header-interview-at header-recommendation header-evaluation-or-decision";

            @include from($screen-desktop) {
                grid-template-columns:
                    calc(var(--table-avatar) + 0.5 * var(--table-grid-gap))
                    calc(var(--table-candidate) + var(--table-grid-gap))
                    calc(var(--table-email) + var(--table-grid-gap))
                    calc(var(--table-interview-at) + var(--table-grid-gap))
                    calc(var(--table-recommendation) + var(--table-grid-gap))
                    calc(var(--table-live-button) + var(--table-grid-gap))
                    calc(var(--table-evaluation-or-decision) + 0.5 * var(--table-grid-gap));
                grid-template-areas: "header-selection header-candidate header-email header-interview-at header-recommendation header-live-button header-evaluation-or-decision";
            }
        }

        &.live-interview-evaluating {
            grid-template-columns:
                calc(var(--table-avatar) + 0.5 * var(--table-grid-gap))
                calc(var(--table-candidate) + var(--table-grid-gap))
                calc(var(--table-interview-at) + var(--table-grid-gap))
                calc(var(--table-recommendation-mobile) + var(--table-grid-gap))
                calc(var(--table-evaluation-or-decision-mobile) + 0.5 * var(--table-grid-gap));
            grid-template-areas: "header-candidate header-candidate header-interview-at header-recommendation header-evaluation-or-decision";

            @include from($screen-desktop) {
                grid-template-columns:
                    calc(var(--table-avatar) + 0.5 * var(--table-grid-gap))
                    calc(var(--table-candidate) + var(--table-grid-gap))
                    calc(var(--table-email) + var(--table-grid-gap))
                    calc(var(--table-interview-at) + var(--table-grid-gap))
                    calc(var(--table-recommendation) + var(--table-grid-gap))
                    calc(var(--table-live-button) + var(--table-grid-gap))
                    calc(var(--table-evaluation-or-decision) + 0.5 * var(--table-grid-gap));
                grid-template-areas: "header-candidate header-candidate header-interview-at header-interview-at header-recommendation header-recommendation header-evaluation-or-decision";
            }
        }

        .header-selection {
            grid-area: header-selection;
            opacity: 0;

            @include upTo($screen-desktop) {
                opacity: 1;
            }
        }

        .header-candidate {
            grid-area: header-candidate;
        }

        .header-email {
            grid-area: header-email;
            display: none;

            @include from($screen-desktop-s-min) {
                display: block;
            }
        }

        .header-phone {
            grid-area: header-phone;
            display: none;

            @include from($screen-desktop-s-min) {
                display: block;
            }
        }

        .header-interview-at {
            grid-area: header-interview-at;
            display: none;

            @include from($screen-desktop-s-min) {
                display: block;
            }
        }

        .header-questions-scores {
            @include upTo($screen-desktop) {
                display: none;
            }

            grid-area: header-questions-scores;
            padding: 0 0.5rem;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(var(--table-score), 1fr));
            position: relative;

            .header-question-name,
            .header-question-score,
            .header-q-score,
            .header-attr-score,
            .header-total-score {
                padding: 12px calc(0.5 * var(--table-grid-gap));
                text-align: center;

                width: 100%;
                text-overflow: ellipsis;

                .react-tooltip {
                    display: inline-block;
                    background: grey;
                    color: white;
                    width: 14px;
                    border-radius: 50%;
                    font-weight: 400;
                    height: 14px;
                    margin-left: 4px;

                    .item {
                        .label {
                            position: relative;
                            top: -1px;
                        }
                    }

                    .description {
                        padding: 0 5px;
                        top: 0;
                        left: 16px;
                        text-transform: initial;
                        font-weight: 100;
                        text-align: left;

                        .content {
                            font-size: 11px;
                        }
                    }
                }
            }

            .header-question-score {
                position: relative;
                .header-question-score-label {
                    position: absolute;
                    right: calc(50% + 1rem);
                }
            }
        }

        .header-invite-sent-on {
            grid-area: header-invite-sent-on;

            @include upTo($screen-desktop) {
                white-space: nowrap;
            }
        }

        .header-live-button {
            grid-area: header-live-button;
            display: none;

            @include from($screen-desktop-s-min) {
                display: block;
            }
        }

        .header-deadline {
            grid-area: header-deadline;
            text-align: right;
        }

        > .header-recommendation {
            @include upTo($screen-desktop) {
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            grid-area: header-recommendation;
            white-space: initial;
        }

        .header-evaluation-or-decision {
            grid-area: header-evaluation-or-decision;
            text-align: right;
        }

        .header-candidate,
        .header-email,
        .header-phone,
        .header-question-score,
        .header-q-score,
        .header-attr-score,
        .header-total-score,
        .header-invite-sent-on,
        .header-deadline,
        .header-interview-at,
        .header-evaluate-link,
        .header-recommendation,
        .header-evaluation,
        .header-evaluation-or-decision {
            &:hover {
                cursor: pointer;
                background-color: var(--default-background-highlight);
            }
        }

        .sorted {
            color: $primary-color;
        }

        &.selected, &:hover, &:focus {
            .header-selection {
                opacity: 1;
            }
        }
    }

    .candidate-row {
        --candidate-row-height: 24px;

        display: grid;
        padding: 8px 0;
        white-space: nowrap;
        font-size: 14px;
        min-height: var(--candidate-row-height);
        line-height: var(--candidate-row-height);
        align-items: start;
        grid-gap: var(--table-grid-gap);

        &.highlight {
            animation: highlight 5s normal forwards;
        }

        &.empty {
            text-align: center;
            display: block;

            .lower {
                text-transform: lowercase;
                margin: 0;
            }
        }

        &.invited {
            grid-template-columns:
                var(--table-avatar)
                var(--table-name)
                var(--table-actions)
                var(--table-invite-sent-on)
                // var(--table-live)
                var(--table-deadline-mobile);
            grid-template-rows: auto;
            grid-template-areas: "candidate-avatar candidate-name candidate-actions invite-sent-on deadline";

            @include from($screen-desktop) {
                grid-template-columns:
                    var(--table-avatar)
                    var(--table-name)
                    var(--table-actions)
                    var(--table-email)
                    var(--table-phone)
                    var(--table-invite-sent-on)
                    var(--table-live-button)
                    var(--table-deadline);
                grid-template-areas: "candidate-avatar candidate-name candidate-actions email phone invite-sent-on live-button deadline";
            }
        }

        &.responded, &.evaluating {
            grid-template-columns:
                var(--table-avatar)
                var(--table-candidate)
                var(--table-recommendation-mobile)
                var(--table-evaluation-or-decision-mobile);
            grid-template-rows: auto auto;
            grid-template-areas:
                "candidate-avatar candidate-name recommendation decisions"
                "questions-scores questions-scores questions-scores questions-scores";
            border-bottom: 1px solid $header-border-color;

            @include from($screen-desktop) {
                grid-template-columns:
                    var(--table-avatar)
                    var(--table-name)
                    var(--table-actions)
                    var(--table-questions-scores)
                    var(--table-recommendation)
                    var(--table-live-button)
                    var(--table-evaluation-or-decision);
                grid-template-rows: auto;
                grid-gap: var(--table-grid-gap);
                grid-template-areas: "candidate-avatar candidate-name candidate-actions questions-scores recommendation live-button decisions";
                border-bottom: 0;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        &.live-interview {
            grid-template-columns:
                var(--table-avatar)
                var(--table-candidate)
                var(--table-interview-at)
                var(--table-recommendation-mobile)
                var(--table-evaluation-or-decision-mobile);
            grid-template-rows: auto;
            grid-template-areas: "candidate-avatar candidate-name interview-at recommendation decisions";
            border-bottom: 1px solid $header-border-color;

            @include from($screen-desktop) {
                grid-template-columns:
                    var(--table-avatar)
                    var(--table-name)
                    var(--table-actions)
                    var(--table-email)
                    var(--table-interview-at)
                    var(--table-recommendation)
                    var(--table-live-button)
                    var(--table-evaluation-or-decision);
                grid-template-rows: auto;
                grid-gap: var(--table-grid-gap);
                grid-template-areas: "candidate-avatar candidate-name candidate-actions email interview-at recommendation live-button decisions";
                border-bottom: 0;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        &.live-interview-evaluating {
            grid-template-columns:
                var(--table-avatar)
                var(--table-candidate)
                var(--table-interview-at)
                var(--table-recommendation-mobile)
                var(--table-evaluation-or-decision-mobile);
            grid-template-rows: auto;
            grid-template-areas: "candidate-avatar candidate-name interview-at recommendation decisions";
            border-bottom: 1px solid $header-border-color;

            @include from($screen-desktop) {
                grid-template-columns:
                    var(--table-avatar)
                    var(--table-name)
                    var(--table-actions)
                    var(--table-email)
                    var(--table-interview-at)
                    var(--table-recommendation)
                    var(--table-live-button)
                    var(--table-evaluation-or-decision);
                grid-template-rows: auto;
                grid-gap: var(--table-grid-gap);
                grid-template-areas: "candidate-avatar candidate-name candidate-name interview-at interview-at recommendation recommendation decisions";
                border-bottom: 0;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        &:hover {
            background-color: var(--default-background-highlight);

            .candidate-actions {
                .candidate-delete,
                .candidate-edit,
                .candidate-sharing,
                .candidate-extension {
                    opacity: 0.2;
                }
            }
        }

        .action-dropdown {
            display: none;
            position: absolute;
            opacity: 0;
            right: -6px;
            top: -2px;

            .selections {
                width: 140px;
            }
        }

        &:hover {
            .deadline-text,
            .undecided.declined,
            .decided {
                &.has-action-dropdown {
                    margin-right: 2rem;
                }
            }

            .action-dropdown {
                display: inline-block;
                opacity: 1;
            }
        }

        &.selected {
            background-color: var(--default-background-highlight);
        }

        > * {
            &:first-child {
                margin-left: var(--table-grid-gap);
            }
            &:last-child {
                margin-right: 0.75rem;
            }
        }

        .candidate-name, .email, .phone, .invite {
            overflow: hidden;
        }

        .candidate-name, .email, .phone, .invite, .deadline {
            text-overflow: ellipsis;
        }

        @include upTo ($screen-desktop) {
            .email, .phone {
                display: none;
            }
        }

        .candidate-actions {
            grid-area: candidate-actions;
            position: relative;
            display: grid;
            grid-template-columns: repeat(auto-fill, 18px);
            height: var(--candidate-row-height);

            @include upTo($screen-desktop) {
                display: none;
            }

            .candidate-delete {
                .action-tooltip {
                    position: absolute;
                    top: 3px;

                    .icon-trash {
                        width: 17px;
                        height: 18px;
                        left: 2px;
                        position: relative;
                    }

                    .description {
                        right: 20px;
                        top: -2px;
                    }
                }
            }

            .candidate-sharing {
                .action-tooltip {
                    position: absolute;
                    top: 2px;
                    .description {
                        right: 20px;
                        top: -2px;
                    }
                }
            }

            .candidate-edit {
                .action-tooltip {
                    position: absolute;
                    top: 3px;
                    left: 2px;
                    .description {
                        right: 20px;
                        top: -2px;
                    }

                    .item {
                        .label {
                            svg {
                                fill: #444;
                            }
                        }
                    }
                }

            }

            .candidate-extension {
                justify-items: end;

                border: none;
                padding: 0;
                background-color: transparent;

                .action-tooltip {
                    .description {
                        right: 20px;
                        top: -4px;
                    }
                    &.lines-2 {
                        .description {
                            top: -11px;
                        }
                    }
                }
            }

            .candidate-delete,
            .candidate-edit,
            .candidate-sharing,
            .candidate-extension {
                display: grid;
                align-content: center;
                opacity: 0;
                position: relative;
                height: var(--candidate-row-height);

                .icon-extend-time, .icon-share {
                    z-index: 2;
                }

                &.updated {
                    opacity: 1;

                    .icon-extend-time {
                        .icon-highlight {
                            fill: $primary-color;
                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }

                &.inactive {
                    cursor: default;
                    opacity: 0.5;
                    &:hover {
                        opacity: 0.5;
                    }
                }

                &.alwaysHide {
                    display: none;
                    opacity: 0;
                    cursor: default;
                    &:hover {
                        opacity: 0;
                        cursor: default;
                    }
                }

                .action-tooltip {
                    .label {
                        height: 16px;
                        width: 16px;
                    }

                    .content {
                        line-height: 14px;
                        .tooltip-sentence {
                            display: block;
                        }
                    }
                }
            }
        }

        .candidate-avatar {
            grid-area: candidate-avatar;
        }

        .candidate-name {
            grid-area: candidate-name;

            &.test-candidate {
                font-style: italic;
            }

            @include from($screen-desktop-s-min) {
                margin: 0;
            }

            .cv-link {
                margin-left: 4px;
                display: inline-block;
                position: relative;
                top: 1px;
            }
        }

        .email {
            grid-area: email;
            font-size: 14px;
            line-height: 14px;
            margin: 0 6px;
            display: none;

            @include from($screen-desktop-s-min) {
                display: block;
                font-size: inherit;
                line-height: inherit;
                margin: 0;
            }
        }

        .phone {
            grid-area: phone;
            font-size: 14px;
            margin: 0 6px;
            display: none;

            @include from($screen-desktop-s-min) {
                display: block;
                font-size: inherit;
                margin: 0;
            }
        }

        .interview-at {
            grid-area: interview-at;
            font-size: 14px;
            margin: 0 6px;
            display: none;

            @include from($screen-desktop-s-min) {
                display: block;
                font-size: inherit;
                margin: 0;
            }

            .interview-url {
                color: $primary-color;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .evaluate-link {
            grid-area: evaluate-link;
            font-size: 14px;
            margin: 0 6px;
            display: none;

            @include from($screen-desktop-s-min) {
                display: block;
                font-size: inherit;
                margin: 0;

                .react-button {
                    padding: 0.25rem 0.5rem;
                }
            }

            .interview_url {
                display: inline;
                height: 1.5rem;
                color: $primary-color;

                &:hover {
                    cursor: pointer;
                    border-bottom: 1px solid $primary-color;
                }
            }
        }

        .invite-sent-on {
            grid-area: invite-sent-on;
            display: inline-block;

            .send-invite {
                display: inline-block;
            }

            .sent-on-full {
                @include upTo($screen-desktop) {
                    display: none;
                }
            }

            .sent-on-simpler {
                @include from($screen-desktop) {
                    display: none;
                }
            }

            .invitation-email-status {
                display: inline-block;
                margin-right: 0.25rem;
                min-width: 19px;
                min-height: 16px;

                .react-tooltip {
                    .icon-mail {
                        top: 1px;
                    }

                    .icon-mail-delivered, .icon-mail-open {
                        position: absolute;
                        top: 3px;
                        display: inline-block;
                    }

                    .description {
                        top: 0;
                        line-height: 14px;
                    }
                }
            }
        }

        .live-button {
            grid-area: live-button;
            text-align: center;

            @include upTo($screen-desktop) {
                display: none;
            }

            .live-button-link {
                cursor: pointer;
                user-select: none;

                &.inactive {
                    cursor: default;

                    .react-button {
                        cursor: default !important;
                    }

                    &.scheduled {
                        opacity: 0.5;
                    }

                    &.unscheduled {
                        display: none;
                    }
                }

                .react-tooltip {
                    .label {
                        padding: 0.25rem 0.4rem;
                        line-height: 1.2rem;
                        height: 1.5rem;
                        border-color: $disabled-color;

                        svg {
                            path {
                                fill: $active-color;
                            }
                        }

                        &:hover {
                            border-color: $active-color;
                        }

                        &.active, &.info {
                            svg {
                                path {
                                    fill: white;
                                }
                            }
                        }
                    }

                    .description {
                        line-height: 12px;
                        top: 1px;
                        right: calc(100% + 2px);
                    }
                }
            }
        }

        .deadline {
            grid-area: deadline;
            text-align: right;
            color: $grey-color;
            position: relative;

            .deadline-text {
                position: relative;
                display: inline-block;
                transition: all .2s ease-out;

                .react-tooltip {
                    display: inline-block;
                    margin-left: .25rem;
                    width: .75rem;

                    .item {
                        position: relative;
                        top: 1px;
                    }

                    .description {
                        top: 0;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }

            &.deadline-updated {
                color: initial;
            }

            .action-dropdown {
                color: initial;
            }
        }

        .questions-scores {
            grid-area: questions-scores;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(var(--table-score), 1fr));

            @include upTo($screen-desktop) {
                font-size: 12px;
            }

            &.loading {
                height: 1.5rem;
            }

            .question-score, .q-score, .attr-score, .total-score {
                text-align: center;

                @include upTo($screen-desktop) {
                    display: grid;
                    grid-template-rows: auto auto;
                }

                .score-label {
                    font-weight: 400;
                    position: relative;

                    .score-label-q {
                        position: absolute;
                        left: calc(50% - 1rem);
                    }

                    @include from($screen-desktop) {
                        display: none;
                    }
                }
            }
        }

        > .recommendation {
            grid-area: recommendation;
            white-space: initial;

            @include upTo($screen-desktop) {
                line-height: 1.5rem;
            }

            &.single {
                grid-template-columns: auto;
                justify-items: start;

                .recommendation-label {
                    display: inline-block;
                    text-transform: uppercase;
                    font-size: 11px;
                    color: white;
                    font-weight: 600;
                    padding: 2px;
                    border-radius: 3px;
                    line-height: 1em;

                    &.yes {
                        background: $active-color;
                    }

                    &.maybe {
                        background: $pending-color;
                    }

                    &.no {
                        background: $warning-color;
                    }

                    &.in-progress {
                        background: $grey-color;
                    }
                }
            }

            .evaluation-in-progress {
                .badge {
                    line-height: 6px;
                }
                ~ .evaluation-in-progress {
                    margin-left: 0;
                }
            }
        }

        .decisions {
            grid-area: decisions;
            text-align: right;
            height: var(--candidate-row-height);
            position: relative;

            .react-button {
                text-transform: capitalize;
                padding: 0.25rem 0.4rem;
                line-height: 1rem;
                width: auto;
                height: 1.5rem;

                &.undecided {
                    &.accepted, &.declined {
                        text-transform: uppercase;
                        border-color: $disabled-color;
                    }

                    &:hover {
                        &.accepted {
                            border-color: $active-color;
                            // background-color: $active-color;
                            // color: white;
                        }

                        &.declined {
                            border-color: $warning-color;
                            // background-color: $warning-color;
                            // color: white;
                        }
                    }
                }

                &.decided {
                    background: none;
                    border: none;
                    cursor: default;
                    padding: 0;

                    svg {
                        margin-right: 0.25rem;
                        display: inline-block;
                        vertical-align: top;
                        path {
                            stroke: white;
                            fill: white;
                        }
                    }

                    &.accepted, &.declined {
                        svg {
                            transform: scale(0.85);
                        }
                    }

                    &.accepted {
                        svg {
                            path {
                                stroke: $active-color;
                                fill: $active-color;
                            }
                        }
                    }

                    &.declined {
                        svg {
                            margin-top: 1px;
                            path {
                                stroke: $warning-color;
                                fill: $warning-color;
                            }
                        }
                    }
                }

                &.evaluate {
                    width: 94px;
                    display: inline-block;
                    text-align: center;
                    text-transform: none;

                    svg {
                        display: inline-block;
                        margin-right: 0.25rem;
                        path {
                            fill: $active-color;
                        }
                    }

                    .button-text {
                        border-bottom: 1px solid rgba($active-color, 0.2);
                    }
                }
            }
        }

        .edit-candidate {
            position: absolute;
            display: inline-block;
            top: -8px;
            right: -12px;

            .selector {
                height: 40px;
                width: 4px;
                background-color: $primary-color;
                text-align: center;
                padding: 0;

                .placeholder {
                    font-weight: 400;
                    top: -3px;
                    left: 7px;
                }
            }

            .selections {
                width: 140px;
                color: black;
                right: 4px;

                .firstLine {
                    text-align: right;
                }
            }
        }
    }
}

.dialog {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas:
        "dialog-message dialog-message"
        "cancel-link confirm-link";
    grid-gap: 1rem;

    .dialog-message {
        grid-area: dialog-message;

        .attention {
            font-weight: 700;
        }

        .customTextField.reason_other {
            margin-left: 1.5rem;
        }
    }

    .cancel-link {
        grid-area: cancel-link;
        color: $primary-color;
        align-self: center;
        justify-self: right;
        user-select: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .confirm-link {
        grid-area: confirm-link;
        text-transform: capitalize;
    }
}

.live-interview-modal {
    .overlay-form {
        .modal-title {
            padding-bottom: 0;
        }
        .container-form {
            padding-left: 0;
            padding-right: 0;
            overflow: hidden;


        }
    }
}
