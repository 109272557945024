.candidates-import {
    .candidates-import-form {
        display: grid;
        grid-template-rows: repeat(4, auto);
        grid-gap: 1rem;
    }
    .submit-button {
        &.is-loading {
            text-align: left;
            .loader {
                top: -5px;
            }
        }
    }
}