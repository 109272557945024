@import "../../styles/breakpoints";
@import "../../styles/common";

.job-deadline {
    .deadline-header {
        text-transform: none;
        font-weight: 700;
        padding-bottom: 0;
    }
    .deadline-selector {
        border-bottom: 1px solid $disabled-color;
        margin-bottom: 1rem;

        .deadline-type {
            font-size: 14px;
            display: inline-block;
            user-select: none;
            margin-right: 1rem;
            text-transform: capitalize;
            position: relative;
            top: 1px;
            border-bottom: 1px solid $primary-color;

            &.active {
                line-height: 2rem;
                font-weight: 400;
            }

            &:not(.active) {
                line-height: 0.7rem;
                color: $primary-color;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .ongoing-deadline-days {
        background-color: white;

        --floating-padding: 8px;

        .selector {
            .firstLine {
                margin-top: 4px;
                left: 4px;
            }
        }
    }

    .deadline-description {
        font-size: 14px;
        padding-top: .25rem;

        &.deadline-none {
            margin-top: -.75rem;
        }
    }
}
