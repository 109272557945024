@import "../../styles/breakpoints";

.evaluator-selection {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 16px;
    align-items: center;
    margin-bottom: 16px;

    @include from($screen-mobile-l-min) {
        grid-template-columns: 300px 60px;
        grid-template-rows: auto;
    }

    .add-button {
        align-self: end;
    }
}