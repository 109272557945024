
$primary-color: #00A1DF;

.custom-chip {
    background-color: $primary-color;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    border-radius: 1rem;
    padding: 0 0.5rem;
    display: inline-block;
    user-select: none;

    &:last-child {
        margin-right: 0;
    }

    .icon {
        margin-right: 0.25rem;
        position: relative;
        top: 2px;

        svg {
            path {
                fill: rgba(255,255,255,0.75);
            }
        }
    }

    .value {
        color: white;
        font-size: 14px;
        position: relative;
        top: -1px;
    }

    .remove {
        margin-left: 0.25rem;
        position: relative;
        top: 2px;
        svg {
            cursor: pointer;
            transform: scale(0.75);
            path {
                fill: white;
            }
        }
    }
}