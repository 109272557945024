@import "../../styles/common";
@import "../../styles/breakpoints";

$primary-color: #00a3d1;

.sub-nav-container {
    position: relative;
    background: white;
    display: grid;
    grid-template-rows: auto;
    @include boxShadow();
    grid-template-columns: auto 1fr;
    grid-template-areas: "subnavmain subnavright";

    @include from($screen-desktop) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: "blank subnavmain subnavright";
        @include sticky($top: $header-height, $z-index: 5);

        &.integration {
            @include sticky($top: 0, $z-index: 5);
        }
    }


    .sub-nav-main {
        grid-area: subnavmain;
    }

    .sub-nav-right {
        grid-area: subnavright;
        display: grid;
        justify-content: right;
        align-content: center;
        margin-right: 8px;
    }
}

.sub-nav-item {
    cursor: pointer;
    height: 100%;
    text-align: center;
    line-height: 42px;
    margin: 0 10px;
    color: $primary-color;
    user-select: none;
    border: 0;
    background: transparent;
    display: inline-block;

    &.react-button {
        height: auto;
        line-height: 16px;
        color: white;
    }

    &.selected {
        font-weight: 600;
        border-bottom: 2px solid $primary-color;
    }

    &:hover, &:focus {
        filter: brightness(120%);
    }
}