@import "../../styles/breakpoints";
@import "../../styles/common";

$primary-color: #00a3d1;

.staff-details {
    .title {
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 16px;
    }

    .staff-form {
        display: grid;
        grid-template-rows: repeat(8, auto);
        grid-template-columns: repeat(2, calc(50% - 8px));
        grid-gap: 0 16px;
        grid-template-areas:
            "picture picture"
            "email email"
            "role login_modes"
            "first_name last_name"
            "phone position"
            "business_unit location"
            "send_invitation_email send_invitation_email";

        @include from($screen-desktop) {
            grid-template-rows: repeat(7, auto);
            grid-template-columns: 128px 1fr 1fr;
            grid-template-areas:
                "picture email email"
                "picture role login_modes"
                "picture first_name last_name"
                "picture phone position"
                "picture business_unit location"
                "picture send_invitation_email send_invitation_email";
        }

        .icon-profile {
            display: none;
        }

        .picture {
            grid-area: picture;
            display: grid;
            grid-template-columns: 128px 1fr;
            grid-template-rows: auto;
            grid-gap: 16px;
            justify-items: left;
            align-items: end;

            @include from($screen-desktop) {
                grid-template-columns: auto;
                grid-template-rows: 128px 1fr;
                align-items: start;
            }

            .picture-upload {
                width: 128px;
                height: 128px;
                position: relative;

                .placeholder {
                    display: none;

                    + .icon-profile {
                        display: inline-block;
                        width: 128px;
                        height: 128px;
                        border: 1px solid $primary-color;
                        border-radius: 51%;

                        path {
                            transform: scale(6);
                            stroke-width: 0.2px;
                            stroke: $primary-color;
                            transform-origin: 0px -4px;
                        }
                    }
                }

                > .loaded {
                    width: 128px;
                    height: 128px;
                    border-radius: 51%;
                    border: 1px solid #E6E6E6;
                    position: relative;
                    overflow: hidden;
                    display: grid;
                    align-items: center;
                    justify-items: center;

                    img {
                        position: absolute;
                    }
                }

                .image-remove {
                    color: red;
                    position: absolute;
                    top: 10px;
                    right: 6px;

                    border-radius: 50%;
                    background-color: red;
                    width: 20px;
                    height: 20px;
                    text-align: center;

                    svg {
                        cursor: pointer;
                        path {
                            fill: white;
                        }
                    }
                }

                .uploads {
                    position: absolute;
                    bottom: 8px;
                    right: 4px;
                    border: 2px solid white;
                    border-radius: 51%;

                    .image-button {
                        width: 24px;
                        height: 24px;
                        display: grid;
                        align-content: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        border-radius: 51%;
                        transition: all 0.2s linear;
                        cursor: pointer;
                        padding-top: 1px;

                        &:hover, &:focus {
                            filter: brightness(110%);
                        }

                        &.upload {
                            background-color: #59c427;
                        }

                        .icon {
                            transition: all 0.2s linear;

                            &.icon-edit {
                                position: relative;
                                top: 2px;
                            }

                            svg {
                                path {
                                    fill: white;
                                }
                            }
                        }

                        &.remove {
                            background-color: red;
                        }

                        input[type="file"] {
                            position: absolute;
                            height: 24px;
                            width: 24px;
                            right: 0;
                            bottom: 0;
                            opacity: 0;
                            border-radius: 51%;
                        }

                        input::-webkit-file-upload-button {
                            cursor: pointer;
                        }
                    }
                }
            }

            .upload-error {
                color: red;
                line-height: 40px;
            }
        }

        .first_name {
            grid-area: first_name;
        }

        .last_name {
            grid-area: last_name;
        }

        .position {
            grid-area: position;
        }

        .business_unit {
            grid-area: business_unit;
        }

        .location {
            grid-area: location;
        }

        .email {
            grid-area: email;
        }

        .phone {
            grid-area: phone;
        }

        .role {
            grid-area: role;
            width: 100%;

            .role-label {
                font-family: Roboto, Helvetica, Arial, sans-serif;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.54);
            }

            .role-dropdown {
                font-family: Roboto, Helvetica, Arial, sans-serif;
                padding-top: 0;
                width: 100%;

                .placeholder {
                    margin-left: 3px;
                }

                .selector {
                    border-bottom-color: rgba(0, 0, 0, 0.1);

                    &:hover, &:focus {
                        border-bottom-color: inherit;
                    }

                    .placeholder {
                        text-transform: capitalize;
                    }

                    .dropdown-overlay {
                        border-radius: 3px 3px 0 0;
                        // background-color: rgba(0, 0, 0, 0.1);
                        background-color: transparent;
                    }

                    &.selecting {
                        .placeholder {
                            color: $primary-color;
                        }
                    }
                }

                .firstLine {
                    text-transform: capitalize;
                }
            }
        }

        .login_modes {
            grid-area: login_modes;
        }

        .send_invitation_email {
            grid-area: send_invitation_email;
        }

        > div {
            margin-bottom: 8px;
            width: 100%;
        }
    }

    .staff-submit {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr auto;
        grid-template-areas: "status-container submit-button";
        margin-top: 16px;
        grid-gap: 16px;

        .status-container {
            grid-area: status-container;
            align-self: center;
            justify-self: end;
        }

        .submit-button {
            grid-area: submit-button;
            display: grid;
            justify-self: end;
        }
    }


}
