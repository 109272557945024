@import "../../styles/common";

$width-small: 120px;
$width-medium: 300px;
$avatar-width: 40px;
$arrow-width: 20px;
$padding-size: 8px;
$border-color: #7d8b92;

.react-dropdown {
    --floating-padding: 18px;
    font-family: var(--font-face);
    font-size: 0;
    position: relative;
    border-bottom: 1px solid transparent;

    /* components */
    .selector, .selection {
        &.disabled {
            cursor: default !important;
            color: rgba(0,0,0,0.25);
            .placeholder {
                color: #BBB !important;
            }

            &:hover, &:focus {
                border-color: transparent !important;
                background: transparent;
            }
        }

        div[class^="dropdown-overlay-"] {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            margin-top: 0;
        }
    }

    .selector {
        font-size: initial;
        cursor: pointer;
        position: relative;
        user-select: none;
        padding: 4px 7px;
        height: 34px;

        width: 100%;
        border: 0;
        background: none;
        display: block;
        color: inherit;

        border-bottom: 1px solid transparent;

        .secondLine {
            top: 24px;
        }

        .arrow {
            font-size: 10px;
            justify-self: right;
            float: right;
            position: relative;
            top: 25%;
        }

        .placeholder {
            position: absolute;
            left: 8px;
            transition: all 0.1s linear;

            top: 2px;
            line-height: 30px;
        }

        &.selected {
            &:not(.persistLabel) {
                .placeholder {
                    display: none;
                }
            }
            &.persistLabel {

            }
        }
    }

    &.underlined {
        border-bottom-color: $border-color;

        &.selecting {
            border-bottom-color: var(--primary-color);
        }

        &:focus {
            border-color: var(--primary-color);
        }

        &.disabled {
            border-bottom-color: $disabled-color;
        }

        .selector {
            &:hover {
                border-color: $border-color;
            }

            &:focus {
                border-color: var(--primary-color);
            }

            &.selecting {
                border-color: var(--primary-color);
            }
        }
    }

    .selections {
        font-size: initial;
        opacity: 0;
        display: none;
        transition: all 0.2s ease-in-out;
        height: 0;
        position: absolute;
        background: white;
        z-index: 10;
        margin-top: 1px;
        margin-bottom: 0;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        width: 100%;

        &.open {
            opacity: 1;
            display: block;
            transition: all 0.2s ease-in-out;
            height: auto;
            max-height: 150px;
            overflow-y: auto;
        }

        &.top-left {
            top: 0;
            left: 0;
            margin-top: 0;
        }

        &.top-right {
            top: 0;
            right: 0;
            margin-top: 0;
        }

        &.bottom-left {
            bottom: 0;
            left: 0;
        }

        &.bottom-right {
            bottom: 0;
            right: 0;
        }

        .manual-input {
            padding: 0.25rem 0.4rem;

            .manual-input-field {
                .input-field {
                    input {
                        padding: 0;
                        height: 2rem;
                        @include placeholder-styling();
                    }
                }
            }
        }

        .selection {
            padding: $padding-size;
            user-select: none;
            border: 0;
            border-bottom: 1px solid rgba(0,0,0,0.05);
            position: relative;
            height: 34px;
            display: block;
            background-color: transparent;
            width: 100%;
            color: inherit;

            &:last-of-type {
                border: 0;
            }

            &.selected {
                background: var(--selected-color);
            }

            &:hover, &:focus, &.highlighted {
                cursor: pointer;
                background: var(--hover-color);
            }

            .item-avatar {
                top: 8px;
            }

            &.groupName {
                background-color: transparent;
                cursor: default;
                height: 26px;

                &:hover, &:focus, &.highlighted {
                    cursor: default;
                    background-color: transparent;
                }

                .firstLine {
                    font-size: 14px;
                    font-weight: 700;
                    top: 4px;
                    left: 4px;
                    opacity: 0.8;
                    color: var(--primary-color);
                }
            }
        }
    }

    &.small {
        .selector {
            font-size: 14px;
        }

        .selections {
            font-size: 14px;
        }
    }

    /* common styles */
    .item-avatar {
        position: absolute;
        display: block;
        left: 8px;
        top: 4px;

        .icon {
            top: 0;
            left: 0;
        }
    }

    .firstLine {
        font-weight: 400;
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        position: absolute;
        left: 8px;
        top: 8px;
        width: calc(100% - 7px - 7px - 10px);
        text-align: left;
    }

    .secondLine {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.55);
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        position: absolute;
        left: 8px;
        top: 26px;
        width: calc(100% - 48px - 7px - 7px - 10px);
        text-align: left;
    }

    /* options */
    .withAvatar {
        .placeholder,
        .firstLine,
        .secondLine {
            left: 48px;
        }

        .placeholder {
            top: 7px;
        }

        .firstLine,
        .secondLine {
            width: calc(100% - 48px - 7px - 7px - 10px);
        }
    }

    .selector {
        &.withSecondLine {
            height: 44px;

            .firstLine {
                top: 6px;
            }
        }
    }

    .selection {
        &.withSecondLine {
            height: 54px;
        }
    }

    /* styles */
    &:not(.popup) {
        .selector {
            .placeholder {
                color: #7d8b92;
            }
        }
    }

    &.popup {
        .selections {
            position: absolute;
            border-radius: 3px;
            overflow: auto;
        }
    }

    &.no-border {
        border-bottom: 0;

        .selector {
            border-color: transparent;
        }
    }

    &.floating-label {
        .selector {
            padding-top: calc(4px + var(--floating-padding));
            height: calc(34px + var(--floating-padding));

            &.withSecondLine {
                height: calc(44px + var(--floating-padding));
            }

            .item-avatar {
                top: calc(4px + var(--floating-padding));
            }

            .placeholder {
                top: calc(2px + var(--floating-padding));
            }

            &.withAvatar {
                .placeholder {
                    top: calc(7px + var(--floating-padding));
                }
            }

            .firstLine {
                top: calc(7px + var(--floating-padding));
            }

            .secondLine {
                top: calc(24px + var(--floating-padding));
            }

            .dropdown-overlay {
                height: calc(100% - var(--floating-padding));
                margin-top: var(--floating-padding);
            }

            &:focus {
                .dropdown-overlay {
                    height: calc(100% - 18px);
                    margin-top: 18px;
                }
            }

            &.selecting, &.selected {
                .placeholder {
                    display: inline-block;
                    top: 0;
                    left: 0;
                    font-size: var(--field-label-size); // 12.8px;
                    line-height: 1em;
                    align-content: start;

                    .react-tooltip {
                        display: inline-block;
                        margin-left: 0.2rem;
                        z-index: 5;
                    }
                }
            }

            &.selecting {
                .placeholder {
                    color: var(--primary-color);
                }
            }
        }

        &.withSecondLine {
            .firstLine {
                top: calc(6px + var(--floating-padding));
            }
        }

        &.fixed-label {
            .selector {
                .placeholder {
                    display: block;
                    top: 4px;
                    left: 3px;
                    font-size: var(--field-label-size); // 12.8px;
                    line-height: 1em;
                    align-content: start;
                }
            }
        }
    }
}