.react-tooltip {
    position: relative;

    .description {
        color: white;
        display: block;
        background-color: grey;
        position: absolute;
        font-size: 10px;
        top: 20px;
        white-space: nowrap;
        user-select: none;

        width: 0;
        padding: 5px 0;
        opacity: 0;
        transition: all 0s linear;

        .content {
            width: 0;
            overflow: hidden;
        }
    }

    * {
        &:hover, &:focus {
            + .description {
                width: auto;
                padding: 5px;
                opacity: 1;
                transition: all 0.2s linear;
                z-index: 1;

                .content {
                    width: 100%;
                }
            }
        }
    }

    &.onLeft {
        .description {
            border-radius: 3px;
            top: -5px;
            right: calc(100% + 4px);
            overflow: hidden;
            text-align: right;
        }

        &.lines-2 {
            .description {
                top: -11px;
            }
        }

        &.lines-3 {
            .description {
                top: -19px;
            }
        }

        &.lines-4 {
            .description {
                top: -27px;
            }
        }
    }

    &.onRight {
        .description {
            top: 1px;
            left: calc(100% + 2px);
            border-radius: 3px;
        }
    }

    &.onBottomLeft {
        .description {
            border-radius: 3px;
            top: -5px;
            right: calc(100% + 4px);
            overflow: hidden;
            text-align: right;
        }

        &.lines-2 {
            .description {
                top: -11px;
            }
        }

        &.lines-3 {
            .description {
                top: -19px;
            }
        }

        &.lines-4 {
            .description {
                top: -27px;
            }
        }
    }

    &.onBottom {
        .description {
            border-radius: 3px;
            overflow: hidden;
            transition: all 0.2s linear;
            padding: 2px 5px;
            height: 0;
            width: auto;
        }

        .item {
            &:hover, &:focus {
                + .description {
                    transition: all 0.2s linear;
                    height: auto;
                }
            }
        }
    }

    &.onTop {
        .description {
            border-radius: 3px;
            overflow: hidden;
            transition: all 0.2s linear;
            padding: 2px 5px;
            width: auto;
            top: -150%;
            right: 0;
        }
        .item {
            line-height: 100%;
        }
    }
}