@import "../../styles/breakpoints";
@import "../../styles/common";

.login-page {
    min-height: 100vh;
    width: 100vw;
    display: grid;
    align-items: center;

    @include upTo($screen-desktop) {
        min-height: -webkit-fill-available; /* mobile viewport bug fix */
    }

    background: #004acf;
    background: linear-gradient(45deg, #004acf 0%, #00a3d1 100%);

    .form {
        background: white;
        box-shadow:
            0 8px 10px 1px rgba(0,0,0,0.14),
            0 3px 14px 2px rgba(0,0,0,0.12),
            0 5px 5px -3px rgba(0,0,0,0.3);
        display: grid;
        grid-template-rows: repeat(10, auto);
        grid-template-areas: "logo" "header" "subheader" "email" "password" "error" "submit" "confirmation" "footer" "success";
        padding: 2rem;
        align-items: center;
        justify-items: center;
        margin: 2rem auto;
        min-width: 347px;

        @include upTo(433px) {
            max-width: calc(100vw - 2rem);
        }

        @include from(433px) {
            max-width: 400px;
        }

        @include from($screen-desktop-s-min) {
            padding: 3rem;
            max-width: 400px;
            width: calc(100% * 2/3);

            min-height: 400px;

            @include from($screen-desktop-l-min) {
                width: calc(100% / 3);
            }
        }

        .form-logo {
            grid-area: logo;
            background: url('../../images/logo-criteria-color.png') no-repeat;
            width: 150px;
            height: 50px;
            background-size: contain;
            margin-top: 1rem;
            margin-bottom: 3rem;
        }

        .loader {
            top: 50px;
            left: 0px;
        }

        .unsupported-browser {
            text-align: center
        }

        .form-header {
            grid-area: header;
            font-size: 1.6em;
            font-weight: 600;
            padding-bottom: 0.2rem;
        }

        .login-form {
            width: 100%;
        }

        .form-subheader {
            grid-area: subheader;
            text-align: center;
            padding-bottom: 1rem;
        }

        .login-with-oauth-button {
            display: block;
            text-align: center;
            margin-bottom: 1rem;
        }

        .login-with-smartrecruiters-button {
            display: block;
            margin-bottom: 1rem;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            font-size: 14px;
            padding: 0;
        }

        .srLogo{
            display: flex;
            width: 38px;
            height: 38px;
            background-color: #ffffff;
            margin: 2px 0 0 2px;
            background-image: url("../../images/logo-smartrecruiters3.png");
            background-size: contain;
            border: solid white 4px;
            border-radius: 2px 0px 0px 2px;
        }

        .sr-button-inner-div{
            display: flex;
        }

        .sr-login-text-and-logo-container{
            display: flex;
            flex-grow: 1;
            position: relative;
            text-align: center;
            justify-content: center;
        }

        .sr-login-text{
            display: flex;
            position: relative;
            margin-top: 9px;
            font-weight: 400;
        }

        .sr-login-logo {
            position: relative;
            display: flex;
            background-image: url("../../images/logo-smartrecruiters2.png");
            background-size: contain;
            background-repeat: no-repeat; /* Do not repeat the image */
            margin-top: 14.5px;
            margin-left: 4px;
            width: 115px;
            height: 17.25px;
        }

        .or-section {
            display: flex;
            text-align: center;
            margin-bottom: 0.5rem;
            
            hr {
                width:50%;
                text-align:left;
                margin-left: 0;
                height: 1px;
                background-color: #BABBBD;
                border: none;
            }

            .oauth-hr-left {
                margin-inline-end: 20px;
            }
            
            .oauth-hr-right {
                margin-inline-start: 20px;
            }

        }

        .email {
            grid-area: email;
            width: 100%;
            margin-bottom: 0.5rem;

            + .password {
                margin-bottom: 1rem;
            }
        }

        .email-field {
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;
        }

        .password-verification-toggle {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            text-align: center;
            margin-bottom: 1rem;
            align-items: center;
            font-weight: bold;
            position: relative;

            .active-background {
                width: 50%;
                background-color: $highlight-color;
                border-radius: 0.25rem;
                user-select: none;
                cursor: default;

                position: absolute;
                top: 0;
                bottom: 0;
                transition: all 0.05s linear;

                &.password_active {
                    left: 0;
                    right: 50%;
                }

                &.email_verification_code_active {
                    left: 50%;
                    right: 0;
                }
            }

            .password-toggle, .verification-toggle {
                cursor: pointer;
                height: 2rem;
                line-height: 2rem;
                color: grey;
                border-radius: 0.25rem;
                position: relative;
                z-index: 10;

                transition: color 0s linear 0.05s;

                &.active {
                    color: white;
                }
            }
        }

        .password {
            grid-area: password;
            width: 100%;
            margin-bottom: 0.5rem;
        }

        .verification-code-message {
            font-weight: 400;
            margin-bottom: 1rem;
        }

        .verification_code {
            margin-bottom: 0.5rem;
            text-transform: uppercase;
        }

        .email, .password {
            label {
                padding-left: 0px;
            }
        }

        .link {
            &:hover {
                text-decoration: underline;
            }
        }

        .form-submit {
            grid-area: submit;
            margin-bottom: 0.5rem;
            margin-bottom: 1rem;
            width: 100%;
        }

        .form-error, .form-confirmation {
            margin-bottom: 1rem;
            text-align: center;
        }

        .form-error {
            grid-area: error;
            color: $warning-color;
        }

        .form-confirmation {
            grid-area: confirmation;
            color: $primary-color;
        }

        .form-footer {
            grid-area: footer;
            font-size: 14px;
            text-align: center;
        }

        .icon-tick-with-circle {
            height: 20px;
            width: 20px;

            g {
                transform: scale(0.25);
                stroke-width: 5px;
            }
        }

        .check-email, .invalid-email {
            text-align: center;
            margin: 3rem 0 3rem;
        }

        .check-email {
            color: $active-color;
        }

        .invalid-email {
            color: $warning-color;
        }

        .forgot-password-confirm {
            text-align: center;
            margin: 0.5rem 0 3rem;
            color: $active-color;
        }

        .login-success {
            grid-area: success;
            position: relative;
            height: 120px;
        }
    }
}