@import "../../../../styles/breakpoints";
@import "../../../../styles/common";

.email-template-preview {
  position: relative;
  height: 100%;

  .header {
    display: grid;
    grid-template-columns: auto 30px 32px;
    align-items: center;
    background-color: $grey-color;
    padding: 1rem;

    &.hide-settings {
      grid-template-columns: auto;
    }

    .subject {
      font-weight: 400;
      color: white;

      overflow-x: hidden;
      overflow-y: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .action {
      justify-self: end;

      path {
        fill: white;
      }

      &.refresh-preview {
        transform: scale(1.5);
        transition: transform 0.25s linear;

        .icon-refresh {
          margin-left: 0;
        }

        &:hover {
          transform: scale(1.5) rotate(-90deg);
        }
      }

      &.preview-settings-link {
        height: 18px;
        transition: transform 0.25s linear;

        &:hover {
          cursor: pointer;
          transform: rotate(90deg);
        }
      }
    }
  }

  .preview-iframe {
    width: 100%;
    height: calc(100% - 56px);
    border: 0;
    background-color: white;

    @include boxShadow();
  }
}