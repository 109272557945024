@import "../../styles/common";
@import "../../styles/breakpoints";

$size-default: 50px;
$border-width-default: 0px;
$margin-width-default: 6px;
$border-radius: 3px;
$highlight-color: #59c427;

@mixin dimension($width: $size-default, $height: $size-default, $border-width: $border-width-default) {
    width: calc(#{$width} + #{2 * $border-width});
    height: calc(#{$height} + #{2 * $border-width});
}

@mixin thumbnailDimension($width: $size-default, $height: $size-default, $margin: $margin-width-default) {
    width: calc(#{$width} - #{2 * $margin});
    height: calc(#{$height} - #{2 * $margin});
    margin: $margin;
}

.question-thumbnail {
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
    @include boxShadow();

    display: inline-block;
    margin-right: 0.25rem;

    &:last-child {
        margin-right: 0;
    }

    @include dimension();

    &.clickable {
        cursor: pointer;
    }

    &.selectable {
        $border-width: 2px;

        border: $border-width solid transparent;
        @include dimension($border-width: $border-width);

        .text-thumbnail {
            background-color: white;
            @include thumbnailDimension($margin: 0px);
        }

        .media-thumbnail {
            border-radius: $border-width;
        }
    }

    &.selected {
        border: 2px solid $highlight-color;
    }

    &.tags-unselected {
        .media-thumbnail {
            filter: saturate(0);
            opacity: 0.2;
        }
    }

    .text-thumbnail {
        position: absolute;
        text-align: center;
        font-size: 6px;
        line-height: 8px;
        overflow: hidden;
        user-select: none;
        display: grid;
        align-content: center;
        justify-content: center;
        word-break: break-word;

        border-radius: $border-radius;

        padding: 3px;
        @include thumbnailDimension();

    }

    &.large {
        $width_mobile: 80px;
        $height_mobile: 60px;

        @include dimension($width_mobile, $height_mobile);
        .text-thumbnail {
            @include thumbnailDimension($width_mobile, $height_mobile);
        }

        @include from($screen-desktop) {
            $width: 120px;
            $height: 90px;

            @include dimension($width, $height);
            .text-thumbnail {
                background: transparent;
                @include thumbnailDimension($width, $height);
            }
        }
    }

    .text-thumbnail {
        .large {
            font-size: 14px;
        }
    }

    .media-thumbnail {
        position: absolute;
        background-size: cover;
        background-position: center;

        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: $border-radius;

        .icon-profile {
            width: 46px;
            height: 39px;
            margin-top: 11px;
            margin-left: 4px;

            path {
                transform: scale(2);
                stroke-width: 0.75;
                stroke: rgba(0,0,0,0.3);
            }
        }
    }

    .video-thumbnail {
        height: 100%;
        width: calc(100% * 16/9);
        min-width: 100%;
        min-height: calc(100% * 9/16);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .play-button {
        &:before {
            content: "\25B6";
            position: absolute;
            color: white;
            font-size: 14px;
            bottom: 0;
            left: 4px;
            text-shadow: 2px 1px 8px black;
            // filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, 0.7));
        }

        &.audio {
            &:before {
                color: #00A1DF;
            }
        }
    }

    .question-number {
        position: absolute;
        border-top-left-radius: $border-radius;
        padding: 4px;
        background: rgba(0,0,0,0.2);
        font-size: 12px;
        color: white;
        font-weight: 400;
        text-shadow: 0 1px 3px rgba(100, 100, 100, 0.7);
        line-height: 12px;
        user-select: none;
    }

    .remove {
        position: absolute;
        font-size: 0;
        top: 3px;
        right: 3px;
        cursor: pointer;

        svg {
            transform: scale(0.75);
            path {
                fill: white;
            }
        }

        &:hover {
            svg {
                path {
                    fill: red;
                }
            }
        }

    }
}