@import "../../../../styles/breakpoints";
@import "../../../../styles/common";

.ratings-tooltip {
    // grid-area: tooltip;
    cursor: pointer;
    position: absolute;
    // align-self: flex-start;
    display: inline-block;
    padding-left: 4px;

    .icon {
        font-size: 12px;
        background: #00a3d1;
        border-radius: 51%;
        width: 16px;
        height: 16px;
        display: inline-block;
        text-align: center;
        color: white;
        font-weight: 500;
        line-height: 17px;
    }

    .tooltip {
        position: relative;
        border: 1px solid lightgrey;
        background: white;
        width: 270px;
        padding: 10px;
        font-size: 13px;
        z-index: 1;
        line-height: 1.2em;
        border-radius: 3px;

        right: -80px;
        left: 0;

        grid-template-columns: auto;
        grid-template-rows: repeat(5, auto);
        grid-gap: 8px;

        display: none;

        .rating-paragraph {
            .icon-star {
                width: 14px;
                height: 12px;
                margin-right: 1px;

                path {
                    fill: $active-color;
                }
            }
            .description {
                display: block;
            }
        }
    }

    &:hover {
        .tooltip {
            display: block;
        }
    }
}