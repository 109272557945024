$primary-color: #00a3d1;

.search-dialog {
    .overlay-form {
        height: 80vh;
        width: 60vw;
    }

    .react-search {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
        grid-template-areas: "search-field" "search-results";
        grid-gap: 1rem;
        height: 100%;

        > .search-field {
            grid-area: search-field;
            padding: 0 1rem;
        }

        .search-results {
            grid-area: search-results;
            overflow: auto;
            padding: 0 1rem 1rem;
            height: 100%;

            .no-results {

            }

            .results-count {

            }

            .items {
                .item {
                    display: grid;
                    grid-template-rows: auto;
                    grid-template-columns: calc((100% - 1rem)/3) calc((100% - 1rem)/3*2);
                    grid-template-areas: "job-id-and-title candidates";
                    grid-gap: 1rem;
                    padding: 0.5rem 0;

                    border-bottom: 1px solid #eee;

                    width: 98%; // gives extra space for scrollbar

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }

                    .job-id-and-title {
                        grid-area: job-id-and-title;
                        display: grid;
                        grid-template-rows: auto 1fr;
                        background: transparent;

                        .id {
                            height: 1rem;
                        }

                        .title {
                            font-size: 20px;
                            font-weight: 400;
                            color: $primary-color;

                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }

                    .candidates {
                        grid-area: candidates;

                        .candidate {
                            display: grid;
                            height: 3rem;
                            margin-bottom: 0.5rem;

                            grid-template-rows: auto auto auto;
                            grid-template-columns: auto 1fr;
                            grid-gap: 0 0.5rem;
                            grid-template-areas:
                                "avatar name"
                                "avatar email"
                                "avatar phone";

                            .candidate-avatar {
                                grid-area: avatar;
                                align-self: center;
                            }

                            .candidate-name {
                                grid-area: name;
                                font-weight: 400;
                                height: 1.2rem;
                                white-space: nowrap;
                                color: $primary-color;

                                &:hover {
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
                            }

                            .candidate-email {
                                grid-area: email;
                                font-size: 14px;
                                line-height: 14px;
                                color: #888;
                                white-space: nowrap;
                            }

                            .candidate-phone {
                                grid-area: phone;

                                font-size: 14px;
                                line-height: 14px;
                                color: #888;
                            }
                        }
                    }
                }
            }
        }
    }
}
