@import "../../../styles/breakpoints";
@import "../../../styles/common";

.step-emails {
    .email-template {
        background: white;
        padding: 1rem;
        display: grid;
        grid-gap: 1rem;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 2rem;
        }

        .header {
            font-weight: bold;
            display: block;
        }
    }
}