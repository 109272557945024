@import "../../styles/common";

$success-color: $primary-color;
$fail-color: $warning-color;

.share-candidate-response {
    .candidate-labels {
        .label {
            font-size: 12px;
            color: grey;
            margin-left: 4px;
        }
        .value {
            background: $primary-color;
            border-radius: 20px;
            padding: 2px 8px;
            color: white;
            display: inline-block;
            margin-right: 3px;
            margin-bottom: 1px;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .send-to {
        margin: 0.5rem 0;
    }

    .message {
        margin-bottom: 0.5rem;
        textarea {
            min-height: 100px;
            max-height: 200px;
            resize: none;
        }
    }

    .actions {
        text-align: right;
        display: grid;
        grid-template-columns: 1fr auto;

        .status-message {
            background: transparent;
            color: $warning-color !important;
            line-height: 2rem;
        }

        &.success {
            .status {
                color: $success-color;
            }
        }

        &.fail {
            .status {
                color: $fail-color;
            }
        }
    }

}