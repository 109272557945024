$screen-mobile: 320px;
// $screen-mobile-m-min: 321px;
// $screen-mobile-m-max: 479px;
$screen-mobile-l-min: 480px;
// $screen-mobile-l-max: 768px;

$screen-desktop: 769px;
$screen-desktop-s-min: 769px;
$screen-desktop-s-max: 1024px;
$screen-desktop-l-min: 1025px;

$medium: 900px;
$large: 1024px;

@mixin from($breakpoint) {
    @media (min-width: $breakpoint) {
        @content;
    }
}

@mixin between($breakpoint-bottom, $breakpoint-top) {
    @media (min-width: $breakpoint-bottom) and (max-width: ($breakpoint-top - 1px)) {
        @content;
    }
}

@mixin upTo($breakpoint) {
    @media (max-width: ($breakpoint - 1px)) {
        @content;
    }
}