$colors-list: #F29691 #92D6C2 #CFD696 #FACA82 #D7ADE0;
$size: 32px;
$size-m: 38px;
$size-ml: 44px;
$size-l: 64px;
$size-xl: 88px;
$border-size: 2px;
$primary-color: #00a3d1;

.react-avatar {
    box-shadow: none;
    position: relative;
    border-radius: 51%;
    width: calc(#{$size} + (2 * #{$border-size}));
    height: calc(#{$size} + (2 * #{$border-size}));
    border: $border-size solid transparent;
    display: inline-block;
    margin-left: -2px;
    user-select: none;

    .name {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        width: 100%;
        height: 100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }

    .img {
        // top: 1px;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        border-radius: 51%;
        position: absolute;
        transition: all 0.5s ease-in-out;
        background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
        // opacity: 0;
    }

    &.bordered {
        background: #ffffff;
        border: $border-size solid white;
    }

    &.active {
        border: $border-size solid $primary-color;
    }

    &.xs {
        width: 24px;
        height: 24px;

        .icon {
            svg {
                margin-left: 1px;
                margin-top: 4px;
                path {
                    transform: scale(0.75);
                }
            }
        }

        .name {
            font-size: 12px;
        }

        .badge {
            width: 10px;
            height: 10px;
            line-height: 8px;
        }
    }

    &.m {
        width: calc(#{$size-m} + (2 * #{$border-size}));
        height: calc(#{$size-m} + (2 * #{$border-size}));

        .icon {
            svg {
                margin-top: 8px;
                margin-left: 2px;
                path {
                    transform: scale(1.45);
                    stroke-width: 0.8px;
                }
            }
        }

        .name {
            font-size: 18px;
        }
    }

    &.ml {
        width: calc(#{$size-ml} + (2 * #{$border-size}));
        height: calc(#{$size-ml} + (2 * #{$border-size}));

        .icon {
            svg {
                margin-top: 9px;
                margin-left: 3px;
                path {
                    transform: scale(1.7);
                    stroke-width: 0.7px;
                }
            }
        }

        .name {
            font-size: 20px;
        }
    }

    &.l {
        width: calc(#{$size-l} + (2 * #{$border-size}));
        height: calc(#{$size-l} + (2 * #{$border-size}));

        .icon {
            border: 2px solid $primary-color;
            svg {
                margin-left: 3px;
                margin-top: 11px;
                path {
                    transform: scale(2.5);
                    stroke: $primary-color;
                    stroke-width: 0.8px;
                }
            }
        }

        .name {
            font-size: calc(#{$size-l} / 2);
        }
    }

    &.xl {
        border: 0;
        width: $size-xl;
        height: $size-xl;

        .icon {
            border: 2px solid $primary-color;
            svg {
                margin-left: 0px;
                margin-top: 9px;
                path {
                    transform: scale(4);
                    stroke: $primary-color;
                    stroke-width: 0.5px;
                }
            }
        }

        .name {
            font-size: calc(#{$size-xl} / 2);
        }
    }

    @each $current-color in $colors-list {
        $i: index($colors-list, $current-color);
        .bg-#{$i} {
            background-color: $current-color;
        }
    }

    .icon {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 51%;
        overflow: hidden;
        border: 1px solid $primary-color;
        background-color: white;

        svg {
            width: 100%;
            height: 100%;
            margin-top: 6px;
            margin-left: 2px;

            path {
                transform: scale(1.25);
                stroke: $primary-color;
            }
        }
    }

    .selection {
        display: grid;
        justify-items: center;
        align-items: center;
        height: 100%;
        width: 100%;
        border-radius: 51%;
        position: absolute;
        margin-left: 1px;
    }

    .name {
        border-radius: 51%;
    }

    .img {
        position: absolute;
        background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
        transition: all 0.5s ease-in-out;
        border-radius: 51%;
        background-position: center;
    }

    ~ .react-avatar {
        margin-left: -8px;
    }

    .badge {
        position: absolute;
        right: -2px;
        bottom: -2px;
        width: 14px;
        height: 14px;
        line-height: 14px;
        text-align: center;
        color: white;
        border-radius: 51%;
        background: $primary-color;
        font-size: 10px;
        font-weight: normal;
    }

    &.selectable, &.selected {
        .name, .img, .badge {
            display: none;
        }

        .selection {
            background-color: transparent;

            .icon-highlight {
                fill: rgba(255,255,255,1);
            }
        }
    }
}
