@import "../../../styles/breakpoints";

.evaluators-final-comments {
    --evaluators-final-comments-label-font-size: 16px;
    --evaluators-final-comments-value-font-size: 14px;

    .evaluator-final-comment {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-gap: 0 0.25rem;
        grid-template-areas:
                "evaluator-avatar evaluator-name"
                "blank final-comment-text";
        margin-bottom: 0.5rem;

        .evaluator-avatar {
            grid-area: evaluator-avatar;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: auto auto;
        }

        .evaluator-name {
            grid-area: evaluator-name;
            font-weight: 400;
            font-size: var(--evaluators-final-comments-label-font-size);
        }

        .final-comment-text {
            grid-area: final-comment-text;
            font-size: var(--evaluators-final-comments-value-font-size);
        }
    }
}