@import "../../styles/breakpoints";
@import "../../styles/common";

.candidate-profile-container {
    display: grid;
    background-color: $default-background-color;

    --candidate-panel-width: 166px;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: "panel" "profile";

    @include from($screen-desktop) {
        --candidate-panel-width: 166px;
        grid-template-columns: var(--candidate-panel-width) 1fr;
        grid-template-rows: auto;
        grid-template-areas: "panel profile";
        background-color: $default-background-color;

        @include contentOverflow();
        @include sticky($z-index: 5);

        &.full-page {
            @include contentOverflow(100%);

            .candidates-panel {
                height: 100%;
            }
        }
    }

    &.has-status-panel {
        @include upTo($screen-desktop) {
            grid-template-columns: auto;
            grid-template-rows: auto auto 1fr;

            grid-template-areas: "status-panel" "panel" "profile";
        }

        @include from($screen-desktop) {
            grid-template-rows: auto 1fr;

            grid-template-areas:
                "panel status-panel"
                "panel profile";
        }
    }

    .candidates-panel {
        grid-area: panel;
    }

    @include from ($screen-desktop) {
        &.integration {
            height: 100vh;

            .candidates-panel {
                height: 100vh;
            }
        }
    }

    .status-panel {
        grid-area: status-panel;
    }

    .candidate-profile {
        grid-area: profile;

        @include upTo($screen-desktop) {
            background-color: white;
            padding: 0 1rem 1rem;
        }

        @include from($screen-desktop) {
            padding: 1rem 1rem 2rem 1rem;
        }

        .submitted {
            margin-bottom: 1rem;
            padding: 0.5rem;
            background: $primary-color;
            color: white;
            user-select: none;
            border-radius: 6px;
            @include boxShadow();

            .unsubmit {
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .label-value-tile {
            border-top: 0;
            padding: 0;

            .value {
                text-transform: none;
            }
        }

        .section-title {
            font-weight: 600;
            padding-bottom: 0.5rem;
        }

        .header {
            font-size: 12px;
            color: $grey-color;
            text-transform: uppercase;
            line-height: 22px;
        }

        .evaluations-panel {
            padding-bottom: 1rem;
        }

        .assessment-and-comments {
            grid-gap: 0.5rem;
            padding: 1rem;
            display: grid;
            background: white;
            border-radius: 6px;
            @include boxShadow();

            @include from($screen-desktop) {
                --grid-gap: 1rem;
                grid-gap: var(--grid-gap);
                --total-width: calc(100% - var(--grid-gap));
                grid-template-columns: var(--total-width);
                grid-template-rows: auto;
                grid-template-areas: "comments";

                &.has-assessment {
                    grid-template-columns: calc(var(--total-width)*2/3) calc(var(--total-width)/3);
                    grid-template-areas: "assessment comments";
                }

                &.has-decisions {
                    grid-template-columns: calc(var(--total-width)*2/3) calc(var(--total-width)/3);
                    grid-template-areas: "recommendation-or-decision comments";
                }

                &.has-assessment.has-decisions {
                    grid-template-rows: repeat(2, auto);
                    grid-template-areas: "assessment comments" "recommendation-or-decision recommendation-or-decision";
                }

            }

            .attributes-scoring, .scoring-table {
                @include upTo($screen-desktop) {
                    padding-bottom: 0.5rem;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                }

                @include from($screen-desktop) {
                    grid-area: assessment;
                }
            }

            .evaluators-final-comments, .final-comment {
                @include upTo($screen-desktop) {
                    padding-bottom: 0.5rem;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                }

                @include from($screen-desktop) {
                    grid-area: comments;
                }
            }

            .recommendation, .decision {
                @include from($screen-desktop) {
                    grid-area: recommendation-or-decision;
                }
            }
        }

        .evaluation-submit-button {
            margin-top: 1rem;
            width: 230px;
        }
    }
}
