.job-summary {
  padding: 1rem;

  .draft-message {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    align-content: center;
    justify-content: center;

    color: black;
  }

  &.manager {
    display: grid;
    grid-template-areas:
      "invited received evaluated accepted"
      "deadline deadline evaluators evaluators"
      "evaluate evaluate evaluate evaluate"
  }

  &.evaluator {
    display: grid;
    grid-template-areas:
      "received evaluated deadline"
      "evaluate evaluate evaluate";

    .deadline {
      border-top: 0;
      padding-top: 0;
    }
  }

  .invited, .received, .evaluated, .accepted {
    border-top: 0;
    padding-top: 0;
  }

  .invited {
    grid-area: invited;
  }

  .received {
    grid-area: received;
  }

  .evaluated {
    grid-area: evaluated;
  }

  .accepted {
    grid-area: accepted;
  }

  .deadline {
    grid-area: deadline;
  }

  .evaluators-thumbnails {
    grid-area: evaluators;
  }

  .evaluate {
    grid-area: evaluate;
    justify-self: end;
  }
}