@import "../../../styles/breakpoints";
@import "../../../styles/common";

.candidate-responses {
    --candidate-responses-label-font-size: 16px;
    --candidate-responses-value-font-size: 14px;

    .evaluation-item {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: auto;
        grid-gap: 0.5rem;
        grid-template-areas: "question" "response";
        padding-top: 1rem;

        &:first-child {
            padding-top: 0;
        }

        .question {
            grid-area: question;
            font-size: var(--candidate-responses-label-font-size);
        }

        .response-and-evaluations {
            --grid-gap: 1rem;
            --total-width: calc(100% - var(--grid-gap));
            display: grid;
            grid-gap: var(--grid-gap);
            padding: 1rem;
            background: white;
            border-radius: 6px;
            @include boxShadow();

            grid-template-rows: repeat(2, auto);
            grid-template-columns: auto;
            grid-template-areas: "response" "evaluations";

            @include from($screen-desktop) {
                grid-template-rows: auto;
                grid-template-columns: calc(var(--total-width) * 2 / 3) calc(var(--total-width) / 3);
                grid-template-areas: "response evaluations";
            }

            .response {
                grid-area: response;
                position: relative;

                &.response-video {
                    .media-preview {
                        display: grid;
                        justify-items: center;
                    }

                    .transcript-text-container {
                        &.transcript-display-true {
                            background: #F6F7F7;
                            padding-bottom: 1rem;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            z-index: 10;
                            opacity: 0.95;
                            overflow-x: hidden;
                            overflow-y: auto;

                            .transcript-toggle {
                                position: sticky;
                                top: 0.75rem;
                                height: 0;
                                width: calc(100% - 0.75rem);
                                display: grid;
                                justify-items: right;

                                svg {
                                    path {
                                        fill: black;
                                    }
                                }

                                &:hover, &:focus {
                                    svg {
                                        path {
                                            fill: $primary-color;
                                        }
                                    }
                                }
                            }
                        }

                        &.transcript-display-false {
                            .transcript-toggle {
                                position: absolute;
                                top: 0.5rem;
                                right: 0.5rem;
                                width: 1.5rem;
                                height: 1.5rem;
                                display: grid;
                                align-items: center;
                                justify-items: center;
                                z-index: 1;

                                border-radius: 50%;
                                background-color: rgba(255, 255, 255, 0.8);

                                &:hover, &:focus {
                                    background-color: rgba(255, 255, 255, 1);
                                }
                            }

                            .transcript-text {
                                display: none;
                            }
                        }

                        .transcript-toggle {
                            &:hover, &:focus {
                                cursor: pointer;
                            }
                        }

                        .transcript-text {
                            display: block;
                            padding: 1rem 2rem 1rem 1rem;
                        }
                    }

                    .response-pending {
                        color: rgba(0, 0, 0, 0.25);
                        user-select: none;
                    }

                    .view-raw-recording {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .react-tooltip {
                            margin-left: 3px;
                        }
                    }
                }

                &.response-text {
                    .response-content {
                        position: relative;
                        height: 100%;
                        max-height: 35rem;
                        padding: 1rem;
                        font-size: var(--candidate-responses-value-font-size);
                        background: #F6F7F7;
                        white-space: pre-wrap;
                        overflow: auto;
                        @include scrolling-shadows($background-color: #F6F7F7);
                    }

                    .word-count {
                        position: absolute;
                        bottom: .5rem;
                        right: .5rem;

                        .item {
                            opacity: .25;

                            &:hover {
                                opacity: .5;
                                transition: .5s;
                            }
                        }

                        .description {
                            font-size: 14px;
                            top: -200%;
                            text-align: right;
                        }
                    }
                }

                &.response-document {
                    .response-document-link {
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .evaluations {
                grid-area: evaluations;

                &.evaluator {
                    --row-count: 1;

                    &.has-rating, &.has-evaluation-guide {
                        --row-count: 2;

                        &.has-evaluation-guide {
                            --row-count: 3;
                        }
                    }

                    display: grid;
                    grid-template-columns: auto;
                    grid-template-rows: repeat(var(--row-count), auto) 1fr;
                    grid-gap: 0.25rem;

                    .ratings-title {
                        display: inline-block;
                        padding-bottom: 0;
                        position: relative;
                        font-weight: 600;
                    }

                    .evaluator-comment {
                        textarea {
                            min-height: 100px;
                            height: calc(100% - 16px) !important;
                            resize: none;
                            overflow: auto !important;
                            background-color: $default-foreground-color;
                        }
                    }
                }

                .evaluation {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    grid-template-rows: auto 1fr;
                    grid-gap: 0 0.25rem;
                    grid-template-areas: "evaluator-avatar evaluator-name" "blank notes-ratings";
                    margin-bottom: 0.5rem;
                    align-items: center;

                    .evaluator-avatar {
                        grid-area: evaluator-avatar;
                        display: grid;
                        grid-template-rows: auto;
                        grid-template-columns: auto auto;
                    }

                    .evaluator-name {
                        grid-area: evaluator-name;
                        font-weight: 400;
                        font-size: var(--candidate-responses-label-font-size);
                    }

                    .notes-and-ratings {
                        grid-area: notes-ratings;
                    }
                }

                .evaluation-guide {
                    background-color: #f9fafa;
                    padding: 0.5rem;
                    margin-bottom: 0.5rem;
                    @include boxShadow();

                    .guide-header {
                        padding-bottom: 0;
                        font-size: 14px;
                    }

                    .guide-content {
                        font-size: 12px;
                    }
                }

                .notes-and-ratings {
                    font-size: var(--candidate-responses-value-font-size);

                    .ratings {
                        margin: 4px 2px 0 0;
                        width: 70px;
                        height: 1rem;

                        label {
                            margin-top: -4px;

                            .icon-star {
                                width: 12px;
                            }
                        }
                    }

                    .notes {
                        display: inline;
                        padding-left: 0.25rem;
                        word-break: break-word;
                    }
                }
            }
        }
    }
}
