@import "../../styles/common";

.typed-dropdown {
  --default-border-color: #7d8b92;
  --active-border-color: #00a3d1;

  font-size: 14px;
  position: relative;

  .selection-label {
    font-size: 0.8rem;
    color: $grey-color;
    padding-left: 0.2rem;

    &.error {
      color: $warning-color;
    }
  }

  .selection-value {
    border-bottom: 1px solid $grey-color;
    padding: 1px 4px 1px;
    transition: all 0.1s linear;

    &.focused, &:hover {
      border-color: $primary-color;
      border-width: 2px;
      padding-bottom: 0;
      background-color: $default-background-color;
    }

    &.error {
      border-color: $warning-color;
    }

    &.multiple-selection {
      .selection {
        display: inline-block;
        background-color: $primary-color;
        color: $default-foreground-color;
        border-radius: 0.75rem;
        padding: 0 20px 0 8px;
        height: 1.5rem;
        line-height: 1.5rem;
        margin: 0 4px 2px 0;
        user-select: none;

        position: relative;

        &.is_email {
          background-color: $active-color-dark;

          .clear {
            &:hover {
              .icon-cross {
                fill: $active-color-dark;
              }
            }
          }
        }

        &.is_error {
          background-color: $warning-color;

          .clear {
            &:hover {
              .icon-cross {
                fill: $warning-color;
              }
            }
          }
        }

        .clear {
          position: absolute;
          top: 5px;
          right: 4px;
          border-radius: 50%;
          width: 14px;
          height: 14px;

          .icon-cross {
            position: relative;
            top: -3px;
            left: 1px;
            transform: scale(0.75);

            fill: white;
          }
        }

        + .value {
          margin-left: 2px;
        }
      }
    }

    &.single-selection {
      .selection {
        font-weight: 400;
        font-size: 16px;

        display: grid;
        grid-template-columns: 1fr auto;
        height: 1.75rem;
        line-height: 1.75rem;
      }
    }

    .selection {
      .clear {
        cursor: pointer;
        transition: all 0.1s linear;

        &:hover {
          background: white;

          .icon-cross {
            fill: $primary-color;
          }
        }
      }
    }

    #value {
      display: inline-block;
      width: auto;
      margin: 0;
      border: 0;
      padding: 0;
      height: 1.75rem;
      font-size: 14px;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .helper-text {
    display: block;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    margin-top: -1px;
    margin-left: 3px;
  }

  .suggestions {
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: rgba(0,0,0,0.05);
    max-height: 230px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.1s linear;

    position: absolute;
    z-index: 20;
    background-color: $default-foreground-color;

    width: 100%;

    .suggestion, .dropdown-item {
      height: 2rem;
    }

    &.staff {
      width: 200px;

      .suggestion {
        height: 50px;
      }
    }

    .suggestion {
      &:hover {
        background-color: $secondary-background-color;
      }
      &.selected {
        background-color: var(--selected-color);
      }
    }
  }

  &.edit-mode {
    .selection-value {
      border-bottom-color: transparent;

      &:hover {
        border-bottom-color: var(--default-border-color);

        .selection {
          .clear {
            display: initial;
          }
        }
      }

      &.focused {
        background-color: transparent;
        border-bottom-color: var(--active-border-color);

        .selection {
          .clear {
            display: initial;
          }
        }

      }
      #value {
        height: 43px;
        line-height: 43px;
        font-weight: 100;
      }
      .selection {
        .selection-name, .clear {
          height: 43px;
          line-height: 43px;
          background-color: transparent;
        }
        .selection-name {
          font-size: 14px;
          font-weight: 100;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .clear {
          display: none;
        }
      }
    }
  }
}
