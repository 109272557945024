.customToggleField {
    height: 33px;
    position: relative;
}

// Original source: http://cssdeck.com/labs/css3-toggle-switch

$switch-off-color: rgba(0,0,0,0.25);
$switch-disabled-color: rgba(0,0,0,0.1);
$label-disabled-color: rgba(0,0,0,0.2);

input[type="checkbox"] {
    &.switch {
        &:empty {
            margin-left: -999px;
            + label {
                position: relative;
                float: left;
                line-height: 28px;
                text-indent: 20px;
                margin: 4px 0;
                cursor: pointer;
                user-select: none;
                width: 100%;

                &:before, &:after {
                    position: absolute;
                    display: block;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    content: ' ';
                    width: 40px;
                    background-color: $switch-off-color;
                    border-radius: 12px;
                    // -webkit-transition: all 100ms ease-in;
                    transition: all 100ms ease-in;
                    border: none;
                    transform: none;
                    height: 24px;
                    margin-top: 2px;
                }

                &:after {
                    width: 20px;
                    height: 20px;
                    top: 2px;
                    margin-left: 2px;
                    background-color: #fff;
                    border-radius: 100%;
                }
            }
        }
        &:checked {
            + label {
                &:before {
                    background-color: var(--active-color);
                }
                &:after {
                    margin-left: 18px;
                }
            }
        }

        &:disabled, &.disabled {
            + label {
                cursor: default;
                color: $label-disabled-color;
                &:before {
                    border: 0;
                    background-color: $switch-disabled-color;
                }
            }

            &:checked {
                + label {
                    &:before {
                        background-color: var(--active-disabled-color);
                    }
                }
            }
        }

        &.small {
            &:empty {
                + label {
                    text-indent: 8px;

                    &:before, &:after {
                        width: 26px;
                        height: 14px;
                        margin-top: 6px;
                    }

                    &:after {
                        margin-left: 1px;
                        width: 12px;
                        height: 12px;
                        top: 1px;
                    }
                }
            }
            &:checked {
                + label {
                    &:after {
                        margin-left: 13px;
                    }
                }
            }
        }

        &.xsmall {
            &:empty {
                + label {
                    text-indent: 0px;
                    font-size: 14px;

                    &:before, &:after {
                        width: 18px;
                        height: 11px;
                        margin-top: 8px;
                    }

                    &:after {
                        margin-left: 1px;
                        width: 9px;
                        height: 9px;
                        top: 1px;
                    }
                }
            }

            &:checked {
                + label {
                    &:after {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}

// Tri-state toggle
// Original source: https://codepen.io/Davide_sd/pen/AmazD
.switch { // wrapper
    /* height */
    $h: 2rem;
    /* width */
    $w: $h * 3;
    /* toggle dimensions */
    $tw: $h * 0.9;
    /* font size */
    $fs: $h * 0.5;

    /* colors: si, ?, no*/
    $colors: #008000, #ff6c00, #ff0000;

    @mixin borderColor($i) {
        background: nth($colors, $i);
    }

    @mixin animation($time) {
        animation: $time ease-in-out infinite;
    }

    .wrapper {
        width: $w;
        height: $h;
        position: relative;
        margin: 2rem auto;
        border-radius: $h/2;
        background: gray;

        & .toggle {
            width: $tw;
            height: $tw;
            position: absolute;
            left: $w / 3 - ($tw - $h) / 2;
            top: -($tw - $h) / 2;
            border-radius: 50%;
            box-sizing: border-box;
            @include borderColor(2);

            transition:
                    all 100ms linear;
        }

        & label {
            cursor: pointer;
            width: $h;
            height: $h;
            position: absolute;
            margin: 0;
            padding: 0;
            z-index: 1;
            display: inline-block;

            text-align: center;
            line-height: $h;
            text-transform: uppercase;
            font-family: 'Lato', sans-serif;
            font-size: $fs;
            font-weight: bold;
            // color: $text-color;
            // @include animation($ta);
        }

        & input {
            position: absolute;
            left: 0;
            margin: 0;
            padding: 0;
            opacity: 0;
        }
    }

    #yes_radio:checked {
        // background: red;

        ~ .toggle {
            @include borderColor(1);
            left: -($tw - $h) / 2;
        }
    }

    #no_radio:checked {
        // background: red;

        ~ .toggle {
            @include borderColor(3);
            left: $w * 2 / 3 - ($tw - $h) / 2;
        }
    }

    #maybe_radio, #maybe-lbl {
        left: 100% * 1 / 3;
    }

    #no_radio, #no-lbl {
        left: 100% * 2 / 3;
    }
}

