@import "../../../styles/breakpoints";
@import "../../../styles/common";

.status-panel {
  color: white;
  line-height: 2rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;

  &.pending {
    background: $pending-color;
  }

  &.completed {
    background: $highlight-color;
  }

  @include from($screen-desktop) {
    @include sticky();
  }
}