@import "../../../styles/common";

$padding-size: 8px;

.dropdown-item {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    "avatar firstLine"
    "avatar secondLine";
  grid-gap: 0 0.5rem;
  padding: 0.1rem 0.20rem;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  height: 50px;

  .item-avatar {
    grid-area: avatar;
    align-self: center;
  }

  .firstLine {
    grid-area: firstLine;
    font-weight: 400;
    font-size: 16px;
    height: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    top: 3px;
  }

  .secondLine {
    grid-area: secondLine;
    font-size: 14px;
    height: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    top: -2px;
  }

  div[class^="dropdown-overlay-"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin-top: 0;
  }
}
