@import "../../styles/breakpoints";
@import "../../styles/common";

$width: 10vw;
$thumbnails-offset: 0px;
$display-text-offset: 3rem;

.media-preview {
    &.landscape {
        .overlay-form {
            $width: 90vw;
            width: $width;

            .question-thumbnails {
                width: calc(#{$width} - #{$thumbnails-offset});
            }

            @include from($screen-desktop) {
                $width: 70vw;
                width: 70vw;

                .question-thumbnails {
                    width: calc(#{$width} - #{$thumbnails-offset});
                }
            }
            @include from($screen-desktop-l-min) {
                $width: 50vw;
                width: 50vw;

                .question-thumbnails {
                    width: calc(#{$width} - #{$thumbnails-offset});
                }
            }
        }
    }

    &.portrait {
        .overlay-form {
            height: 80vh;
            width: auto;

            .question-thumbnails {
                width: auto;
            }

            .container-form {
                video {
                    height: 80vh;
                    width: auto;
                }
            }
        }
    }

    &.has-thumbnails {
        .overlay-form {
            background: black;
            border-radius: 0;
        }
    }

    .overlay-form {
        border: 0;
        padding: 0;
        background: transparent;

        .close-icon {
            z-index: 1;
            svg {
                path {
                    fill: #FFFFFF;
                    filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, 0.7));
                }
            }

            &:hover {
                svg {
                    path {
                        fill: $primary-color;
                    }
                }
            }
        }
    }

    .question-thumbnails {
        padding: 0.5rem 0.5rem 0;
        height: calc(54px + 0.5rem);

        .thumbnails {
            overflow: auto;
            white-space: nowrap;
        }
    }

    .display-text {
        color: rgba(255, 255, 255, 0.85);
        text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
        padding: 0.5rem;
        font-size: 14px;
        line-height: 1.25rem;

        position: relative;
        z-index: 1;

        top: $display-text-offset;
        margin-top: calc(-1 * #{$display-text-offset});
        height: $display-text-offset;

        background: transparent;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.75) 50%, transparent 100%);

    }

    .media-container {
        background: black;

        // materialize override
        height: initial;
        overflow: initial;
        padding-bottom: initial;
        position: relative;

        video {
            outline: none;
            display: grid;

            @include from($screen-desktop-l-min) {
                min-width: 480px;
            }
        }
    }

    &.audio {
        background: transparent;
        height: 100%;
        align-items: end;

        .media-container {
            background: transparent;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 40px;
            grid-gap: 0.5rem;

            audio {
                width: 100%;
                outline-width: 0;
            }

            .media-speed-control {
                height: 100%;
                bottom: 0;
                right: 0;
                background-color: #F6F7F7;
                border-radius: 1rem;

                .playback-speed {
                    color: #000;

                    &.active {
                        height: 100%;
                        display: grid;
                        align-items: center;
                        font-weight: normal;
                    }
                }

                &.selection-active {
                    .playback-speed {
                        &:first-child {
                            border-radius: 1rem 1rem 0 0;
                        }
                        &:last-child {
                            border-radius: 0 0 1rem 1rem;
                        }

                        &.active {
                            height: auto;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    /*.audio-only {
        position: absolute;
        display: grid;
        align-self: center;
        justify-self: center;
        color: white;
    }*/

    &.inline:not(.audio) {
        background-color: #333;
        /*position: relative;

        .audio-only {
            height: calc(100% - (#{$display-text-offset} * 2));
            width: 100%;
            top: $display-text-offset;
            left: 0;
            bottom: $display-text-offset;
            justify-items: center;
            align-items: center;
        }*/
    }

    .media-speed-control {
        position: absolute;
        bottom: 35px;
        right: 17px;
        color: white;
        opacity: 1;
        font-size: 14px;

        &:hover {
            background-color: rgba(0,0,0,0.5);
        }

        .playback-speed {
            text-align: center;
            width: 40px;
            user-select: none;
            cursor: pointer;
            color: rgba(255,255,255,0.8);
            display: none;

            &.active {
                display: block;
                font-weight: bold;
            }
        }

        &.selection-active {
            .playback-speed {
                display: block;
                background-color: rgba(0,0,0,0.5);

                &.active {
                    color: rgba(255,255,255,1);
                }

                &:hover {
                    color: rgba(255,255,255,1);
                }
            }
        }
    }
}
