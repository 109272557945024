@import "../../../styles/common";

.step-templates {
    padding-top: 1rem;
    height: 100%;

    display: grid;
    grid-gap: 1rem;

    .step-templates-header {
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1.6rem;
    }
    .step-template-selection {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-gap: 1rem;
        min-height: 150px;

        .templates-dropdown {
            align-self: start;
            .placeholder {
                text-transform: uppercase;
            }
        }
    }
    /*.separator {
        position: relative;
        margin: 0.5rem 0;
        user-select: none;

        .line {
            display: block;
            width: 100%;
            max-height: 1px;
            text-align: center;
            background-color: rgba(0,0,0,0.3);
        }

        .or {
            position: absolute;
            top: -11px;
            display: grid;
            width: 100%;
            justify-content: center;

            span {
                background: white;
                padding: 0 0.5rem;
            }
        }
    }*/

    .step-template-proceed {
        text-align: center;
        font-size: 14px;

        .link {
            padding-left: 0.25rem;
            font-size: initial;
            color: $primary-color;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .react-button {
        text-align: center;
    }
}