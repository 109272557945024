@import "../../../styles/breakpoints";

.job-stage-header {
  position: relative;
  margin: 1rem 0.75rem 0.5rem;

  @include from($screen-desktop) {
    margin: 1rem 1.5rem;
  }

  .job-stage-title {
    font-weight: 700;
    text-transform: capitalize;

    @include from($screen-desktop) {
      text-align: center;
    }
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;

    .short-link {
      margin-right: 0.5rem;
    }

    .invite {
      height: 1.8rem;
      line-height: 0.9rem;
      font-size: 14px;
    }

    .actions {

    }
  }

  .job-stage-details {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, auto);
    grid-template-areas:
        "candidates deadline"
        "evaluators evaluators";
    justify-content: center;
    justify-items: center;

    @include from($screen-desktop) {
      grid-template-rows: auto;
      grid-template-columns: repeat(3, auto);
      grid-template-areas: "candidates deadline evaluators";
    }

    .label-value-tile {
      border-top: none;
      padding-right: 1rem;

      .value {
        font-weight: 600;
      }
    }

    .candidates {
      grid-area: candidates;

      .candidates-label {
        text-transform: uppercase;
        font-size: 11px;
        color: #7d8b92;
        display: inline-block;
        padding-top: 0.8rem;
        line-height: 1em;
      }

      .tiles {
        display: flex;
        justify-content: center;

        .label-value-tile {
          border-top: 0;
          padding-top: 0;

          .label {
            min-height: 0;
            font-size: 10px;
            white-space: nowrap;
          }

          &.declined {
            @include upTo($screen-desktop) {
              display: none;
            }
          }
        }
      }
    }

    .deadline {
      grid-area: deadline;
      padding-left: 1rem;
      padding-right: 0;

      .value {
        margin-top: 0.35rem;
        @include upTo($screen-desktop) {
          white-space: normal;
        }
      }
    }

    .evaluators-thumbnails {
      grid-area: evaluators;
      padding-right: 0;

      @include upTo($screen-desktop) {
        justify-self: start;
      }

      @include from($screen-desktop) {
        padding-left: 2rem;
      }

      .value {
        margin-top: 0.25rem;

        .no-evaluator {
          line-height: 1.5rem;
        }

        .react-avatar {
          &.bordered {
            background: #f2f2f2;
            border-color: transparent;
          }
        }
      }
    }
  }

  ~ .job-stage-header {
    margin-top: 2rem;
  }
}