@import "../../styles/common";

.attributes {
    margin-left: 24px;

    input {
        margin: 0 0 4px 0;
        padding: 0 0 0 4px;
        height: 30px;
        font-size: 14px;
        width: 150px;
        font-weight: 300;
        display: block;

        &.editable {
            color: inherit;
        }

        &.read-only {
            display: block;
            border-bottom-color: transparent;
            background: transparent;
            text-overflow: ellipsis;
        }
    }

    .action {
        font-size: 12px;
        color: $primary-color;
        text-decoration: underline;
        margin-left: 4px;

        &.disabled {
            opacity: 0;
        }
    }
}
