@import "../../../styles/common";
@import "../../../styles/breakpoints";

$background-color: white;
$header-color: #7d8b92;
$header-bottom-border-color: lightgrey;
$content-bottom-border-color: rgba(0, 0, 0, 0.05);
$highlight-color: rgba(0, 163, 209, 0.08);
$primary-color: #00A1DF;

.company-staff {
    @include from($screen-desktop) {
        @include contentOverflow($height: $content-height-after-header-and-subheader);
        padding: 2rem;
        position: relative;
    }

    &.has-intercom {
        padding-bottom: 6rem;
    }

    ol {
        &.collection {
            margin: 0px;
            padding: 0px;
            background-color: $background-color;
            font-size: 14px;

            border: 1px solid #eee;
            border-bottom: none;
            border-radius: 3px;
            position: relative;
            box-shadow: 0 1px 0px 0px rgba(69, 90, 100, 0.05), 0 1px 1px 0px rgba(69, 90, 100, 0.1), 0 1px 5px 0px rgba(69, 90, 100, 0.05);

            li {
                list-style: none;
            }

            * {
                box-sizing: border-box;
            }

            /* The maximum column width, that can wrap */
            .item-container {
                display: grid;
                grid-gap: 0 8px;
                padding: 8px 16px 8px 8px;
                color: initial;

                grid-template-columns:
                    48px /* avatar */
                    1fr /* details */
                    48px; /*actions */

                @include from($screen-desktop) {
                    padding: 8px 16px;
                    grid-template-columns:
                        48px /* avatar */
                        minmax(160px, 4fr) /* details */
                        2fr /* role */
                        2fr /* position */
                        2fr /* department */
                        2fr /* location */
                        48px; /*actions */
                }

                @include from($screen-desktop-l-min) {

                }

                &:first-child {
                    font-size: 11px;
                    letter-spacing: 0;
                    line-height: 1;
                    text-transform: uppercase;
                    color: $header-color;
                    font-weight: 300;
                    border-bottom: 1px solid $header-bottom-border-color;

                    display: none;

                    @include from($screen-desktop) {
                        display: grid;
                    }
                }

                &:not(:first-child) {
                    border-bottom: 1px solid $content-bottom-border-color;
                    &:hover {
                        background-color: $highlight-color;
                    }

                    .position, .department, .location {
                        display: none;
                        @include from($screen-desktop) {
                            display: grid;
                        }
                    }

                    .role {
                        display: none;
                        @include from($screen-desktop) {
                            display: inline-block;
                        }
                    }

                    .role {
                        text-transform: capitalize;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }

                .attribute-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(var(--column-width-min), 1fr));
                    grid-gap: 0 8px;
                }

                /* In order to maximize row lines, only display one line for a cell */
                .attribute {
                    padding: 2px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    border: 0;
                    text-align: left;

                    display: grid;
                    align-content: start;
                }

                .details {
                    // --column-width-min: 8em;
                    line-height: 16px;

                    .full-name {
                        font-weight: 600;
                    }
                }

                .role {
                    overflow: visible;

                    .login-mode-tooltip {
                        display: inline-block;

                        .login-mode-tooltip-icon {
                            background: $grey_color;
                            color: white;
                            border-radius: 0.5rem;
                            padding: 0 0.5rem;
                            font-size: 0.8rem;
                            margin-right: 0.25rem;
                            cursor: default;
                            user-select: none;
                        }
                    }
                }

                .actions {
                    justify-self: end;

                    .react-dropdown {
                        .selections {
                            width: 190px;

                            .selection {
                                .firstLine {
                                    text-align: right;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

.custom-modal {
    &.email-confirmation {
        .overlay-form {
            width: 100%;
            @include from($screen-desktop) {
                width: 500px;
            }

            .confirm-dialog {
                .message {
                    text-align: center;

                    .success {
                        color: $active-color-dark;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    &.email-sent {
        .confirm-dialog {
            .message {
                text-align: center;
            }

            .success {
                color: $active-color-dark;
            }
        }
    }
}
