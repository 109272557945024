@import "../../../styles/common";

.branding {
    --padding-size: 1rem;
    padding: var(--padding-size);
    @include contentOverflow($height: calc(100vh - 60px - 44px));

    // DEFAULT COLORS

    $interview-page-bg: #f6f7f7;

    --link-color: #00a3d1;

    --button-background-color: #00a3d1;
    --button-color: white;

    --icon-highlight-color: #00a3d1;

    --welcome-logo: url('./alcami-brand-logo@2x.png');
    //// --welcome-logo-height: 80;
    --interview-logo: url('./alcami-brand-logo@2x.png');
    //// --interview-logo-height: 60px;

    --welcome-background-image: url('./alcami-brand-loginPageBg.jpg');
    //// --welcome-background-position: right center;
    --welcome-message-background-color: rgba(255, 255, 255, 0.8);
    --welcome-message-font-size: 32px;
    --welcome-message-font-weight: 700;
    --welcome-message-color: #0000ff;

    --welcome-details-background-color: #{$interview-page-bg};
    --welcome-details-color: var(--color-5-rgb);
    --welcome-details-link-color: var(--link-color);
    --welcome-details-label-color: #6f6f6f;
    --welcome-details-value-color: inherit;

    --welcome-position-description-background-color: rgba(255, 255, 255, 0.05);
    --welcome-position-description-icon-color: #1993ab;
    --welcome-position-description-border-color: rgba(140, 140, 140, 0.5);
    --welcome-position-description-color: var(--color-5-rgb);

    --welcome-details-start-button-background-color: var(--button-background-color);
    --welcome-details-start-button-color: var(--button-color);

    --welcome-form-header-color: black;

    --breadcrumbs-active-color: #00a3d1;
    --breadcrumbs-active-border-color: var(--breadcrumbs-active-color);

    --loader-color: var(--link-color);


    // The variables below can be overridden for a brand if needed, but they typically aren't.

    //// --welcome-logo-margin-correction-factor: 0;  // ← 0 ⇒ no adjustment; 1 ⇒ logo flush with top of page

    //// --welcome-message-font-weight: 700;
    //// --welcome-message-font-size: #{$base-size * 4.5};
    //// --welcome-message-text-transform: uppercase;
    //// --welcome-message-first-line-override-color: inherit;

    // --welcome-details-powered-by-link-color  ← unset ⇒ same as --welcome-details-link-color

    //// --welcome-form-header-font-weight: 800;
    //// --welcome-form-header-text-transform: uppercase;
    //// --welcome-form-header-letter-spacing: 2px;

    --interview-header-background-color: white;
    --interview-header-color: inherit;

    // --link-hover-color  ← unset ⇒ same as --link-color
    //// --link-hover-filter: brightness(120%);
    // --welcome-details-link-hover-color  ← unset ⇒ same as --welcome-details-link-color
    // --welcome-details-link-hover-filter  ← unset ⇒ same as --link-hover-filter

    --breadcrumbs-active-background-color: inherit; // set this for a button effect on the active breadcrumb
    //// --breadcrumbs-active-label-padding: 0; // if the above is set, you probably want to set a value here, too.

    --timer-background-color: #474747;
    --timer-progress-color: var(--button-background-color);
    --timer-color: var(--button-color);

    .branding-container {
        display: grid;
        grid-template-columns: 120px auto;
        grid-template-rows: auto 1fr;
        grid-template-areas: "branding-settings branding-settings"
            "branding-preview branding-preview";
        grid-gap: 1rem;
        @include boxShadow();

        background-color: white;
        padding: var(--padding-size);

        .branding-settings {
            grid-area: branding-settings;
            display: grid;
            grid-template-columns: 400px 1fr auto;
            grid-gap: 0.5rem;
            align-items: center;
            position: relative;

            .branding-name {
                .placeholder {
                    .label {
                        text-transform: uppercase;
                    }
                }
                .firstLine {
                    .branding-name {
                        max-width: 325px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .icon-star {
                            width: 16px;
                            height: 16px;
                            position: relative;
                            left: 0;
                            top: 2px;
                            margin-right: 4px;

                            path {
                                fill: $active-color;
                            }
                        }
                    }
                }
                .selector {
                    &.disabled {
                        .firstLine {
                            .branding-name {
                                .icon-star {
                                    path {
                                        fill: $disabled-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .selections {
                    &.open {
                        max-height: 325px;
                    }
                }
            }

            .edit-branding {
                position: absolute;
                left: 22.5em;
                top: 1.6rem;
                cursor: pointer;

                svg {
                    fill: $primary-color;
                }

                &:hover, &:focus {
                    cursor: pointer;
                    filter: brightness(110%);
                }

                &.disabled-true,
                &.disabled-true:hover,
                &.disabled-true:focus {
                    cursor: default;
                    filter: none;
                    svg {
                        fill: $disabled-color;
                    }
                }
            }

            .add-button {
                display: grid;
                justify-self: left;
            }

            .delete-button {
                position: absolute;
                left: 21rem;
                top: 1.6rem;
                width: 16px;

                svg {
                    g {
                        fill: $primary-color;
                    }
                }

                &:hover, &:focus {
                    cursor: pointer;
                    filter: brightness(110%);
                }
            }

            .edit-button {
                display: grid;
                justify-self: right;
                width: 70px;
            }

            .cancel-button {
                display: grid;
                justify-self: right;
                padding-top: 0.25rem;
            }

            .save-button {
                display: grid;
                justify-self: right;
                position: relative;
                width: 70px;

                .loader {
                    top: -4px;
                    div {
                        background: white;
                    }
                }
            }

            .color-picker-toggle {
                position: absolute;
                right: 0.75rem;
                top: 5rem;
                z-index: 1;
                padding: 0 0.25rem;
                height: 26px;
                background: rgba(255, 255, 255, 0.8);
                border-radius: 3px;

                label {
                    margin: 0;
                }
            }
        }

        .branding-properties, .branding-properties-advanced {
            display: none;
            // grid-template-rows: repeat(6, auto);
            grid-gap: 0.5rem;
            align-content: start;

            .property-group {
                font-weight: 700;
                font-size: 1.2rem;
                margin: 0.5rem 0 0;
                display: block;

                &:nth-child(1) {
                    margin-top: 0;
                }
            }

            .customFileUpload {
                input {
                    padding: 0 0.25rem;
                }
            }
        }

        .branding-properties {
            grid-area: branding-properties;
        }

        .branding-properties-advanced {
            display: none;
            grid-area: branding-properties-advanced;
            grid-template-columns: auto;
            grid-gap: 1rem;
            width: 50%;

            .advanced-settings-link {
                color: transparent;

                &.shown {
                    color: initial;
                }

                &:hover {
                    color: initial;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .branding-preview {
            grid-area: branding-preview;
            box-shadow:
                rgba(0, 0, 0, 0.063) 0 0.4px 1.4px,
                rgba(0, 0, 0, 0.08) 0 1.6px 5.1px,
                rgba(0, 0, 0, 0.094) 0 4.9px 15.5px,
                rgba(0, 0, 0, 0.15) 0 29px 80px;
            height: 720px;
        }

        &.edit-mode-true {
            grid-template-rows: auto 1fr auto;
            grid-template-areas: "branding-settings branding-settings"
                "branding-properties branding-preview"
                "branding-properties-advanced branding-properties-advanced";

            .branding-settings {
                grid-template-columns: 400px 1fr auto auto;
            }

            .branding-properties, .branding-properties-advanced {
                display: grid;
            }
        }
    }

    + * {
        .new-branding-name {
            // height: 44px;

            .helper-text {
                color: $warning-color;
            }
        }

        .default-branding-toggle {
            padding-top: 0.5rem;
        }
    }

    .customTextField {
        label {
            text-transform: uppercase;
        }

        .input-field {
            input {
                background: transparent;
            }

            .helper-text {
                color: $primary-color;
            }
        }
    }
}
