@import "../../styles/common";
@import "../../styles/breakpoints";

$basic-background: #f9fafa;
$primary-color: #00A1DF;
$header-height: 60px;

.job-draft {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        "job-panel"
        "progress-panel";
    top: 0;
    background: $basic-background;
    height: calc(100vh - #{$header-height});

    @include from($screen-desktop) {
        padding: 0;
        grid-template-columns: 1fr 192px;
        grid-template-rows: auto;
        grid-template-areas:
            "job-panel progress-panel";
    }

    @include from($screen-desktop-l-min) {
        grid-template-columns: 1fr 192px;
        grid-template-rows: auto;
        grid-template-areas:
            "job-panel progress-panel";
    }

    .react-container {
        &.loading {
            height: 100%;
            width: 100%;
            position: relative;
        }
    }

    .job-panel {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
        grid-template-areas: "header-panel" "main-panel";
        position: relative;

        @include between($screen-desktop, $screen-desktop-l-min) {
            @include contentOverflow();
            @include no-scroll-bar();
        }

        @include from($screen-desktop-l-min) {
            grid-template-columns: 176px 1fr;
            grid-template-rows: auto;
            grid-template-areas: "header-panel main-panel";
        }

        .header-panel {
            grid-area: header-panel;
            padding: 1rem 1rem 0;

            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto auto auto;
            grid-gap: 8px 16px;
            align-items: center;
            grid-template-areas:
                "step-number"
                "step-name"
                "step-description";
            background: $basic-background;

            @include from($screen-desktop) {
                padding: 2rem 2rem 0rem;

                @include from($screen-desktop-l-min) {
                    display: block;
                    background: #f2f3f4;
                    border-right: 1px solid #e0e3e5;
                    height: calc(100vh - 60px);
                    overflow: hidden;
                }
            }

            .step-number {
                grid-area: step-number;
                font-size: 0.8rem;
                text-transform: uppercase;
            }
            .step-name {
                grid-area: step-name;
                font-size: 1.1rem;
                font-weight: 700;
                line-height: 1.6rem;
                width: auto;

                @include from($screen-desktop) {
                    @include from($screen-desktop-l-min) {
                        margin: 0.4rem 0 1rem;
                        width: 0px;
                    }
                }
            }

            .step-description {
                grid-area: step-description;
                .sub {
                    display: block;
                    font-size: 0.8rem;
                    word-break: break-word;
                }

                .link {
                    color: $primary-color;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .main-panel {
            grid-area: main-panel;
            background: $basic-background;

            padding: 1rem;

            @include from($screen-desktop) {
                padding: 1rem 2rem 2rem;

                @include from($screen-desktop-l-min) {
                    padding: 2rem;
                    @include contentOverflow();
                    @include no-scroll-bar();
                }
            }

            &.main-panel-job-details {
                @include upTo($screen-desktop) {
                    padding-bottom: 0;
                }
                @include from($screen-desktop-l-min) {
                    padding: 2rem 2rem 0;
                }
            }

            &.main-panel-questions {
                @include from($screen-desktop) {
                    padding: 1rem 2rem 0;

                    @include from($screen-desktop-l-min) {
                        padding-top: 2rem;
                        padding-bottom: 2rem;
                    }
                }
            }
        }
    }

    .progress-panel {
        grid-area: progress-panel;
        padding: 1rem;
        position: relative;
        display: grid;
        grid-template-rows: 1fr auto;
        background: $basic-background;

        @include from($screen-desktop) {
            position: relative;
            background: #fff;
            padding: 2rem 1.5rem;
            border-left: 1px solid #eaecee;

            @include contentOverflow();
        }

        .steps-panel {
            display: none;

            @include from($screen-desktop) {
                display: block;
                height: 100%;
                overflow: hidden;
            }

            .job-name {
                font-size: 1.1rem;
                font-weight: 700;
                line-height: 1.5rem;
                padding-right: 7px;
                border-bottom: 1px solid #eaecee;
                transition: all 0.2s linear;
                word-break: break-word;

                &.blank {
                    padding-bottom: 0;
                    border-bottom: 0;
                }

                &.no-delete {
                    padding-bottom: 1rem;
                }

                .job-delete {
                    display: block;
                    margin-bottom: 1rem;

                    .delete-link {
                        color: red;
                        text-decoration: underline;
                        font-size: 14px;
                        font-weight: normal;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }

            .steps {

            }
        }

        .actions-panel {
            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-gap: 16px;

            &.step-1 {
                grid-template-columns: auto;
            }

            .react-button {
                margin-right: 0;

                &.final {
                    padding: 0;
                }
            }

            @include from($screen-desktop) {
                grid-gap: 8px;
                padding-bottom: 4rem;
            }

            .prev {
                .label {
                    @include from($screen-desktop) {
                        display: none;
                    }
                }
                .icon-play {
                    transform: scale(0.6) translate(-10px, 5px) rotate(180deg);
                    @include from($screen-desktop) {
                        transform: scale(0.6) translate(-15px, 5px) rotate(180deg);
                    }
                }
            }

            .next {
                .label {
                }
                .icon-play {
                    transform: scale(0.6) translate(10px, 5px);
                }
            }
        }
    }

    &.jobadder,
    &.no-header {
        .header-panel {
            @include from($screen-desktop-l-min) {
                height: 100vh;
            }
        }

        .job-panel {
            @include from($screen-desktop) {
                height: 100vh;
            }
        }

        .main-panel {
            @include from($screen-desktop-l-min) {
                height: 100vh;
            }
        }

        .progress-panel {
            @include from($screen-desktop) {
                height: 100vh;

                .actions-panel {
                    padding-bottom: 0;
                }
            }
        }
    }

    &.jobadder {
        --jobadder-height: 533px;

        @include from($screen-desktop) {
            .job-panel,
            .progress-panel {
                height: var(--jobadder-height);

                .custom-modal {
                    &.question-selection {
                        max-height: var(--jobadder-height);

                        .overlay-form {
                            max-height: calc(var(--jobadder-height) - 2rem);

                            .container-form {
                                max-height: calc(var(--jobadder-height) - 2rem);

                                .library-filter-container {
                                    height: calc(var(--jobadder-height) - 2rem - 49px - 2rem);
                                }

                                .library-list {
                                    padding-bottom: 1rem;
                                }
                            }
                        }
                    }

                    &.add-item {
                        max-height: var(--jobadder-height);
                    }
                }
            }
        }
    }
}

.job-draft-steps {
    .step {
        display: grid;
        grid-template-columns: auto 1fr;

        .step-info {
            width: 22px;
            height: 22px;
            line-height: 23px;
            font-size: 14px;
            background-color: #7d8b92;
            color: #fff;
            font-weight: 400;
            padding: 0;
            margin-right: 8px;
            border-radius: 11px;
            display: inline-block;
            text-align: center;
        }

        .step-name {
            display: inline-block;
            color: #455a64;
        }

        &.active {
            .step-info {
                background-color: #5ccc29;
            }
            .step-name {
                font-weight: 700;
            }
        }

        &.completed {
            .step-info {
                background-color: #5ccc29;
                .step-icon {
                    display: block;
                    margin-top: -1px;

                    .icon-tick-thin {
                        transform: scale(1.25);
                    }
                }
            }
        }
    }
}