/* Create-React-App CSS reset per @csstools/normalize.css. */
@import-normalize;

/* Box-sizing reset */
html {
    box-sizing: border-box;
    padding: env(safe-area-inset);
}

*, *:before, *:after {
    box-sizing: inherit;
}

/* fonts */
@font-face {
    font-family: 'Lato';
    src: url('fonts/LatoLatin-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('fonts/LatoLatin-Bold.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@import "../../../react/src/styles/breakpoints";
@import "../../../react/src/styles/common";

@include default-scroll-bar();
@include default-placeholder();

html {
    height: 100%;

    line-height: 1.5;
    font-family: var(--font-face);
    font-weight: 300;
    font-size: 16px;
}

body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    @include no-scroll-bar();
}

button, input, select, textarea {
    color: inherit;
    font: inherit;
}

a {
    color: var(--primary-color);
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

a.disabled {
    color: #ddd !important;
}

strong {
    font-weight: 900;
}

img {
    max-width: 100%;
}




button.link {
    display: inline;
    text-align: start;
    background: none;
    border: none;
    padding: 0;
    color: $primary-color;

    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &.disabled {
        color: $disabled-color;
        cursor: default;
        user-select: none;

        &:hover {
            text-decoration: none;
        }
    }
}

/* Common */
.error img {
    margin-bottom: 1rem;
}

.error button {
    margin-top: 1rem;
}

/***** JOB CREATION ****/

.step {
    margin: 0.8rem 0;
}

/* Drag n Drop */
.dndPlaceholder {
    height: 70px !important;
    display: block;
    background-color: #eee;
    list-style: none;
}

.dndDraggingSource {
    display: none;
}

/* Step 1 */
.ql-toolbar, .ql-container {
    background: #fff;
    border: none !important;
    outline: none !important;
}

/**** JOB CREATION - END ****/

.status {
    padding: 0 8px 0 30px;
    line-height: 2;
    position: fixed;
    top: 15px;
    right: 65px;
    z-index: 100;
    display: block;
    background: url(../images/ico-tick.png) 8px 5px no-repeat;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 2px;
}

.status .spinner {
    position: absolute;
    left: 5px;
    top: 4px;
}


.evaluation-guide {
    white-space: pre-line;
}

.attribute {
    border-bottom: 1px solid #eaecee;
    padding: 1em 1em;
    border-right: 1px solid #eaecee;
    white-space: nowrap;
}

@media only screen and (max-width: 1350px) {
    .attribute {
        text-align: center;
    }
}

.view-container {
    width: 100%;
    height: 100%;
}

.navigation-directive {
    position: sticky;
    top: 0;
    z-index: 10;
}


.library-html_content {
    // Matches Quill's declared styles.
    h1 { font-size: 2rem; }
    h2 { font-size: 1.5rem; }
    h3 { font-size: 1.17rem; }
    h4 { font-size: 1rem; }

    // Matches Quill's declared styles.
    h1, h2, h3, h4 {
        margin: 0;
        padding: 0;
    }

    // Override Quill's declared margin: 0; padding: 0 with the useragent
    // defaults for `p`.
    .ql-editor p {
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;

        &:first-child {
            margin-block-start: 0 !important;
        }
        &:last-child {
            margin-block-end: 0 !important;
        }
    }

    // Matches the desired style in the applicants app.
    h1, h2, h3, h4 {
        font-weight: 400;
    }

    // Override Materialize defaults.
    h1, h2, h3, h4 {
        line-height: inherit;
    }

    // Override fucked-up Materialize defaults.
    ul {
        padding-left: inherit;
        list-style-type: inherit;
        padding-inline-start: 1.5rem;

        li {
            padding-left: inherit;
            list-style-type: inherit;
            padding-inline-start: 0.5rem;
        }
    }
}


@import "../components/Button/style.scss";
