@import "../../styles/breakpoints";

$top-navigation-height: 60px;
$padding-height: 24px;

.react-container {
    &.loading {
        position: fixed;

        width: 100%;
        height: 100%;

        /*@include from($screen-desktop) {
            width: calc(100% - #{2 * $padding-height});
            height: calc(100% - #{$top-navigation-height} - #{2 * $padding-height});
        }*/
    }

    .page-error {
        width: 100%;
        padding: 2rem;
        text-align: center;
    }
}