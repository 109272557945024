@import "../../styles/common";
@import "../../styles/breakpoints";

$primary-color: #00A1DF;
$inactive-color: rgba(0,0,0,0.25);
$border-color: #eee;

.library {
    display: grid;
    margin: 0;
    padding: 1rem 1rem;
    grid-gap: 1rem;

    @include from($screen-desktop) {
        margin: 0;
        padding: 2rem 2rem 0;

        @include from(1600px) {
            max-width: 1600px;
            margin: 0 auto;
        }
    }

    &.question {
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
        grid-template-areas: "search" "list";

        @include from($screen-desktop) {
            grid-template-columns: auto;
            grid-template-rows: auto 1fr;
        }

        &.has-filters {
            @include from($screen-desktop) {
                grid-template-rows: auto 1fr;
                grid-template-columns: calc(120px + 1rem) auto;
                grid-template-areas:
                    "filters search"
                    "filters list";
            }
        }
    }

    &.message {
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
        grid-template-areas: "search" "list";
    }

    .library-search {
        grid-area: search;

        @include from($screen-desktop) {
            @include from(1600px) {
                max-width: 1600px;
            }
        }

        .has-video-checkbox {
            input {
                position: relative;
                top: -2px;
            }

            .label {
                text-transform: none;
            }
        }
    }

    .library-filter-container {
        grid-area: filters;

        @include upTo($screen-desktop) {
            display: none;
        }

        @include from($screen-desktop) {
            @include sticky($top: 2rem, $z-index: 5);
            @include contentOverflow($height: calc(100vh - 104px - 4rem));
        }

        .library-filter {
            @include from($screen-desktop) {
                width: 120px;
            }

            .filter-header {
                display: block;
                margin-bottom: 0.75rem;
                line-height: 14px;

                .filter-name {
                    font-size: 14px;
                    color: #6a7b83;
                    font-weight: 400;
                    display: block;
                }
            }

            .customToggleField {
                label {
                    text-transform: capitalize;
                }
            }

            .tags-list {
                padding-bottom: 1rem;

                .tag-list {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 50%);
                    grid-template-rows: auto;

                    @include from($screen-desktop) {
                        grid-template-columns: auto;
                    }

                    .video-tag-all {
                        text-decoration: underline;
                        color: $primary-color;
                        font-size: 13px;
                        cursor: pointer;
                        display: inline;
                    }

                    .customCheckbox {
                        font-size: 14px;
                        line-height: 14px;
                        margin-bottom: 4px;

                        .label {
                            margin-left: 9px;
                        }
                    }
                }
            }

            .categories-list {
                .category-list {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 50%);
                    grid-template-rows: auto;

                    @include from($screen-desktop) {
                        grid-template-columns: 120px;
                    }

                    .group-name {
                        text-transform: uppercase;
                        font-size: 10px;
                        color: $grey-color;
                        font-weight: 400;
                        padding: 8px 0;
                    }

                    .category {
                        padding-bottom: .25rem;

                        .category-name {
                            // text-transform: capitalize;
                            font-size: 14px;
                            color: $inactive-color;
                            cursor: default;
                            display: inline-block;
                            line-height: 1rem;
                            word-break: break-word;
                            text-decoration: none;

                            &.active {
                                color: var(--primary-color);
                                cursor: pointer;
                                text-decoration: underline;

                                &.selected {
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .library-list {
        grid-area: list;
        @include from($screen-desktop) {
            padding-bottom: 2rem;
        }

        .group-name {
            text-transform: uppercase;
            font-size: 10px;
            color: $grey-color;
            font-weight: 400;
        }

        .category-name {
            // text-transform: capitalize;
            font-weight: 400;
            padding: 0.75rem 0 0.5rem;

            @include upTo($screen-desktop) {
                @include sticky();
                background-color: $secondary-background-color;
            }
        }

        .question-item-container {
            &.filtered {
                display: none;
            }
            .question-item {
                .remove {
                    cursor: pointer;
                }
            }
        }

        .no-result {
            @include boxShadow();
            border: 1px solid $border-color;
            display: block;
            padding: 1rem;
            background: white;
        }
    }
}

.criteria {
    .library .library-filter-container .library-filter .categories-list .category-list .category .category-name {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
