@keyframes pop {
    0% { opacity: 0; }
    5% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 0; }
}

.short-link {
    max-width: 150px;
    display: inline-block;
    position: relative;
    border-radius: 3px;

    .short-link-content {
        border: 1px solid white;
        padding: 0 8px;
        display: block;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 20px;
        line-height: 26px;
        font-weight: 400;
        font-size: 14px;
    }
    .icon-copy {
        cursor: pointer;
        display: inline-block;
        position: absolute;
        top: 0px;
        right: 0px;
        background: white;
        padding: 6px 3px 5px 4px;
    }
    .outcome {
        position: absolute;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        user-select: none;
        color: white;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        display: none;
        top: 0;
        left: 0;
        bottom: 0;
        height: 28px;
        line-height: 28px;
        border-radius: 3px;

        &.shown {
            display: block;
            animation: pop 2s normal forwards;
        }
    }
}