@import "../../styles/common";

$primary-color: #00A1DF;

.status-message {
    transition: all 0.25s linear;

    .close-icon {
        cursor: pointer;
        right: 0.5rem;
        position: absolute;

        path {
            fill: white;
        }
    }

    &.inline {
        opacity: 0;
        background: transparent;

        &.info, .info {
            color: $active-color-dark;
        }

        &.success, .success {
            color: $primary-color;
        }

        &.warning, .warning {
            color: $warning-color;
        }

        &.message-hide {
            opacity: 0;
        }

        &.message-show {
            opacity: 1;
        }
    }

    &.bar {
        height: auto;
        color: white !important;
        transition: all 0.2s linear;
        padding: 4px 8px;
        background-color: $primary-color;

        &.info {
            background-color: $active-color-dark;
        }

        &.warning {
            background-color: $warning-color;
        }

        &.message-hide {
            height: 0;
            padding: 0 8px;
            opacity: 0;
        }
    }

    &.fixed-bar {
        position: fixed;
        background-color: $primary-color;
        width: 100%;
        z-index: 100;
        top: 0;
        left: 0;
        padding: 8px 16px;
        box-shadow: 0px 2px rgba(0,0,0,0.25);
        transition: all 0.2s linear;

        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 8px;
        font-weight: 400;

        &.info {
            color: white;
            background-color: $active-color-dark;
        }
        &.warning {
            color: white;
            background-color: $warning-color;
        }

        &.message-hide {
            margin-top: -40px;
        }

        &.message-show {
            margin-top: 0px;
        }
    }

    a {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
}