@import "../../../styles/common";

.customFileUpload {
    background: white;
    font-family: var(--font-face);

    .file-title {
        text-transform: uppercase;
        padding: 1px 0 0 0.2rem;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        user-select: none;
        position: relative;

        .info-tooltip {
            display: inline-block;
            position: absolute;
            right: 0;

            .info-tooltip-icon {
                display: inline-block;
                width: 16px;
                height: 16px;
                text-align: center;
                border-radius: 50%;
                background-color: rgba(0,0,0,0.4);
                color: white;
                font-size: 12px;
            }

            .description {
                top: 0;
                left: 22px;
                background-color: white;
                @include boxShadow($width-1: 5px, $width-2: 10px);
                color: black;
                padding: 0.75rem 1rem;
                text-transform: none;
                font-size: 14px;
                z-index: 2;
                display: none;

                .list-header {
                    font-size: 16px;
                    font-weight: 400;
                }

                .list-item {
                    margin-right: 4px;
                }
            }

            * {
                &:hover, &:focus {
                    + .description {
                        display: block;
                    }
                }
            }
        }
    }

    .preview {
        @include chequered-background();
        font-size: 0;
        margin-bottom: 0.5rem;
    }

    .file-upload-button {
        position: relative;

        .upload-button {
            height: 2rem;
            line-height: 1.2rem;
            font-size: 0.8rem;
            text-align: center;
            border-radius: 3px;

            &:hover {
                cursor: pointer;
            }
        }

        input {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            border-radius: 51%;
            cursor: pointer;
        }
    }

}