$shade-color: #7d8b92;
$border-color: rgba(0, 0, 0, 0.08);

.label-value-tile {
    border-top: 1px solid $border-color;
    padding: 0.8em 0.4em 0.8em 0;

    .label {
        font-size: 11px;
        line-height: 1;
        text-transform: uppercase;
        color: $shade-color;
        display: block;
        min-height: 1em;
    }
    .value {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4;
        min-height: 22px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }
    .subLabel {
        font-size: 11px;
        font-weight: 400;
        display: block;
    }
}