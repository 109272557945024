@import "../../../styles/breakpoints";
@import "../../../styles/common";

.interview-templates {
    margin: 0;
    padding: 1rem 1rem 0;
    background: #f2f2f2;
    min-height: 100vh;

    @include from($screen-desktop) {
        padding: 2rem 2rem 0;
    }

    &.has-navigation-menu {
        min-height: $content-height-after-header-and-subheader; // calc(100vh - 104px);

        @include from($screen-desktop) {
            @include contentOverflow($height: $content-height-after-header-and-subheader);
        }
    }

    .interview-templates-header {
        background-color: white;
        border-bottom: 1px solid $header-border-color;
    }

    .header-row, .interview-template-row {
        display: grid;

        @include from($screen-desktop) {
            grid-template-columns: 2fr repeat(3, 1.5fr) repeat(2, 1fr) calc(60px + 1rem);
        }

        .created-at,
        .jobs-associated,
        .questions {
            @include upTo($screen-desktop) {
                display: none;
            }
        }
    }

    .header-row {
        @include upTo($screen-desktop) {
            display: none;
        }

        .action {
            justify-self: end;
            padding-top: 7px;
            padding-bottom: 0;

            .new-button {
                height: 20px;
                padding: 2px 4px;
                background-color: $active-color;
                border-radius: 10px;
                transition: all 0.1s linear;

                svg {
                    transform: scale(0.8);
                    position: relative;
                    top: 2px;
                }

                &:hover {
                    cursor: pointer;
                    padding-left: 8px;
                    transition: all 0.1s linear;

                    &:before {
                        content: "Add";
                        color: white;
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .header-column {
        padding: 0.5rem;

        font-size: 11px;
        color: #7d8b92;
        text-transform: uppercase;
        user-select: none;
    }
}

.interview-template-row {
    position: relative;
    padding: 0.5rem 0;
    outline: none;
    background-color: $default-foreground-color;
    margin-bottom: 1px;
    border-radius: 3px;
    align-items: center;

    font-size: 14px;

    @include upTo($screen-desktop) {
        grid-template-columns: 1fr auto;
        grid-template-areas:
            "name actions"
            "created-by actions"
            "terminology actions";

        .template-name {
            grid-area: name;
        }

        .terminology {
            grid-area: terminology;
        }

        .created-by {
            grid-area: created-by;
        }

        .interview-template-actions {
            grid-area: actions;
        }
    }

    .template-name {
        font-weight: 400;
        padding-left: 0.5rem;
    }

    .terminology,
    .created-at,
    .created-by,
    .jobs-associated,
    .questions,
    .interview-template-actions {
        padding: 0 0.5rem;
    }

    .terminology {
        text-transform: capitalize;
    }

    .created-by {
        .by {
            @include from($screen-desktop) {
                display: none;
            }
        }

        .name {
            @include upTo($screen-desktop) {
                font-weight: 400;
            }
        }
    }

    .interview-template-actions {
        justify-self: end;
        padding-right: 0.5rem;

        .react-dropdown {
            .selections {
                width: 80px;
            }
        }

        .selected {
            .icon-tick-thin {
                width: 11px;
                height: 9px;

                path {
                    stroke: $active-color;
                    transform: scale(1.25);
                }
            }
        }
    }
}

.no-items {
    padding: 2rem;
    text-align: center;

    .create-button {
        display: inline-block;
    }
}