@import "../../../styles/common";

.confirm-dialog-response {
  .confirm-dialog {
    > * {
      text-align: center;
    }
    .message {
      display: grid;
      grid-template-rows: 7rem auto;
      grid-template-columns: auto;
      align-items: center;
      justify-items: center;

      word-break: break-word;
    }
    .actions {
      padding-bottom: 0;

      button {
        margin-top: 0;
      }
    }
  }

  &.error {
    .message {
      color: $warning-color;
    }
  }
}