@import "../../../../styles/breakpoints";
@import "../../../../styles/common";

.question-item {
    display: grid;
    grid-gap: 0.5rem;
    padding: 0.5rem;
    outline: none;
    // user-select: none;
    background: white;
    margin-bottom: 1px;
    border-radius: 3px;
    @include boxShadow();

    --single-time-width: 70px;
    --times-width: var(--single-time-width);

    &.highlighted {
        background-color: rgba($highlight-color, 0.2);
        animation: highlight 2s ease-out;
    }

    &.selecting {
        grid-template-columns: 1fr auto;
        grid-template-areas: "question-container action";

        .action {
            display: grid;
            grid-template-columns: repeat(2, auto);
        }
    }
    &.editing {
        grid-template-columns: 20px 1fr 14px;
        grid-template-areas: "row-index question-container action";
        background: transparent;

        --single-time-width: 96px;

        &.has-question-number {
            grid-template-columns: 20px 20px 1fr 14px;
            grid-template-areas: "row-index question-number question-container action";

            &.has-times {
                grid-template-columns: 20px 20px 1fr var(--times-width) 14px;
                grid-template-areas: "row-index question-number question-container times action";
            }
        }

        &.has-times {
            grid-template-columns: 20px 1fr var(--times-width) 14px;
            grid-template-areas: "row-index question-container times action";
        }
    }
    &.viewing {
        grid-template-columns: 1fr;
        grid-template-areas: "question-container";
        border: 1px solid rgba(0,0,0,0.1);
        border-bottom: 0;

        &.has-question-number {
            grid-template-columns: 20px 1fr;
            grid-template-areas: "question-number question-container";

            &.has-times {
                grid-template-columns: 20px 1fr var(--times-width);
                grid-template-areas: "question-number question-container times";
            }
        }

        &.has-times {
            grid-template-columns: 1fr var(--times-width);
            grid-template-areas: "question-container times";
        }
    }
    &.listing {
        grid-template-columns: 1fr 42px;
        grid-template-areas: "question-container action";
    }
    &.updating {
        grid-template-columns: 1fr;
        grid-template-areas: "question-container";
        border: 1px solid rgba(0,0,0,0.1);
        border-bottom: 0;

        --single-time-width: 96px;

        &.has-question-number {
            grid-template-columns: 20px 1fr;
            grid-template-areas: "question-number question-container";

            &.has-times {
                grid-template-columns: 20px 1fr var(--times-width);
                grid-template-areas: "question-number question-container times";
            }
        }

        &.has-times {
            grid-template-columns: 1fr var(--times-width);
            grid-template-areas: "question-container times";
        }
    }

    &.is-dragging {
        background-color: white;
    }

    .row-index {
        grid-area: row-index;

        .dragHandle {
            width: 100%;
            display: block;
            text-align: center;
            height: 100%;
            outline: none;
            padding-top: 4px;
        }
    }

    .question-number {
        grid-area: question-number;
        background: var(--primary-color);
        border-radius: 0.25rem;
        color: white;
        width: 16px;
        margin-right: 4px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        user-select: none;
        margin-top: 4px;
    }

    .question-container {
        grid-area: question-container;
        display: grid;
        font-size: 14px;
        grid-gap: 0.25rem;
        position: relative;
        grid-template-rows: min-content;

        .practice {
            display: inline-block;
            justify-self: start;
            user-select: none;

            > span {
                font-size: 11px;
                font-weight: 400;
                background: var(--primary-color);
                padding: 0.05rem 0.25rem;
                color: white;
                text-transform: uppercase;
                border-radius: 3px;
            }

            .swap-link {
                display: inline-block;
                color: var(--primary-color);
                font-size: 11px;
                margin-left: 6px;
                user-select: none;

                span {
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }

        .question-text {
            font-weight: 600;
            line-height: 1.5rem;

            @include no-scroll-bar();

            .response-type-tooltip {
                display: inline-block;
                position: relative;
                top: 4px;

                &.document-tooltip {
                    top: 3px;
                }

                [class^="icon"] {
                    top: -1px;
                }

                .icon-show-video {
                    transform: scale(0.75);
                    position: relative;
                }

                .description {
                    top: -2px;
                    padding: 2px 5px;
                    height: 1.25rem;
                    line-height: 1rem;
                }
            }

            .attachment-tooltip {
                display: inline-block;
                position: relative;
                top: 2px;

                .item {
                    margin-right: 2px;
                }

                .description {
                    top: -1px;
                    padding: 2px 5px;
                    height: 1.25rem;
                    line-height: 1rem;
                }
            }

            .scoring-disabled-tooltip {
                display: inline-block;
                position: relative;
                left: -2px;

                .description {
                    top: 2px;
                    padding: 2px 5px;
                    height: 1.25rem;
                    line-height: 1rem;
                }
            }

            .quill {
                border-bottom-width: 0 !important;

                .ql-toolbar {
                    display: none;
                }
                .ql-container {
                    border-bottom-width: 0 !important;
                    .ql-editor {
                        padding: 0;
                        height: 64px;
                        overflow: auto;
                        @include scrolling-shadows();
                    }
                }
            }
        }

        .question-thumbnails-container {
            .options-link {
                color: $primary-color;
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;
                display: inline;

                svg {
                    transform: scale(0.70) translate(-3px, 8px) rotate(90deg);

                    polygon {
                        fill: #6a7b83;
                    }
                }

                &.closed {
                    svg {
                        transform: scale(0.70) translate(-1px, 5px);
                    }

                    + .question-thumbnails {
                        display: none;
                    }
                }

                &.always-open {
                    cursor: default;
                    text-decoration: none;
                }
            }
        }

        .thumbnail-and-content {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 0 0.25rem;

            &.content-only {
                grid-template-columns: 1fr;
            }

            .content {
                font-size: 14px;
                font-family: inherit;
                margin: 0;
                max-height: 100px;
                line-height: normal;
                overflow: auto;

                .library-html_content {
                    line-height: normal;
                    background: $default-background-color;
                    padding: 0.5rem;

                    > p {
                        &:first-child {
                            margin-block-start: 0;
                        }

                        &:last-child {
                            margin-block-end: 0;
                        }
                    }
                }
            }
        }

        .evaluation-guide {
            padding-top: 8px;

            resize: vertical !important;
            outline: none;

            textarea {
                resize: none;
            }
        }

        .label-value-tile {
            .value {
                white-space: inherit;
                text-transform: none;
                font-weight: normal;
                font-size: 14px;
                word-break: break-word;
            }
        }
    }

    .times {
        grid-area: times;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto 1fr;
        grid-template-areas: "prep-time" "answer-time" "no-of-attempts";
        margin-top: 4px;

        .react-dropdown {
            --floating-padding: 0.75rem;
        }

        .prep-time {
            grid-area: prep-time;

            + .answer-time {
                margin-top: 0.5rem;
            }
        }

        .answer-time {
            grid-area: answer-time;
        }

        .no-of-attempts {
            margin-top: 0.5rem;
            grid-area: no-of-attempts;
        }

        .prep-time, .answer-time, .no-of-attempts {
            .selector {
                .firstLine {
                    left: 0;
                }
            }
        }
    }

    .action {
        grid-area: action;
        text-align: right;

        .react-button {
            width: 42px;
            height: 32px;
            line-height: inherit;
            font-size: 14px;

            padding: 0;
        }

        .icon-tick-thin {
            width: 11px;
            height: 9px;

            path {
                stroke: var(--active-color);
                transform: scale(1.25);
            }
        }

        .disabled {
            path {
                stroke: $disabled-color;
            }
        }

        .edit-icon, .remove, .deselect {
            cursor: pointer;
        }

        .edit-icon {
            margin-right: 6px;
            opacity: 0.2;
            position: relative;
            top: 1px;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .label-value-tile {
        border-top: 0;
        padding: 0;
    }

    &.is-live {
        .question-container .question-text .response-type-tooltip {
            display: none;
        }
    }
}


@keyframes highlight {
    0% {
        background-color: inherit;
    }
    10% {
        background-color: rgba($primary-color, 0.5);
    }
    100% {
        background-color: rgba($highlight-color, 0.2);
    }
}
