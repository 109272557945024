$checked-color: #59c427;

.custom-radio-button {
    display: inline-block;
    width: 18px;
    height: 18px;
    position: relative;
    cursor: default;

    > input[type=radio] {
        -webkit-appearance: none;
        background-color: #eee;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 1px solid #fff;
        outline:none;

        top: 1px;
        left: 2px;
        opacity: 1;

        &:checked {
            background-color: $checked-color;
            border: 0;
            &:after {
                color: white;
                content: url("../../../images/IconTickThin.svg");
                position: absolute;
                margin: -4px 0 0 3px;
                // transform: scale(1);
            }
        }
    }

    &.show-value {
        width: 100%;

        input {
            position: relative;
            -webkit-appearance: auto;
            margin-right: 0.5rem;
            top: 0;
        }
    }
}
