@import "../../styles/breakpoints";

$primary-color: #00A1DF;

.custom-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;

    .overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 30;
    }

    .overlay-form {
        --form-padding: 1rem;
        --screen-padding: 1rem;
        display: none;
        z-index: 2;
        border: 2px solid grey;
        border-radius: 0.5rem;
        background: white;
        width: calc(100% - (2 * var(--form-padding)) - var(--screen-padding));
        box-sizing: content-box;

        @include from($screen-desktop) {
            width: calc(600px + (2 * var(--form-padding)));
            max-height: 90vh;
        }

        .container-form {
            grid-area: modal-content;
            width: 100%;
            box-sizing: border-box;
            max-height: 90vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0 var(--form-padding) var(--form-padding);
        }
    }

    &.search-dialog, &.media-preview {
        > .overlay-form {
            > .container-form {
                padding: 0;
            }
        }
    }

    &.open {
        display: grid;
        opacity: 1;
        visibility: visible;
        grid-template-rows: 100%;
        grid-template-columns: 100%;

        .overlay-form {
            display: grid;
            justify-self: center;
            align-self: center;
            position: absolute;
            z-index: 40;
            min-height: 4rem;
            max-height: 90vh;
            grid-template-areas: "modal-content";
            // grid-gap: 1rem;

            &.is-loading {
                .loader {
                    top: 14px;
                }
            }

            &.has-title {
                grid-template-rows: auto 1fr;
                grid-template-areas: "modal-title" "modal-content";
            }

            .modal-title {
                grid-area: modal-title;
            }
        }

    }

    .modal-title {
        font-size: 18px;
        font-weight: 400;
        // text-transform: capitalize;
        padding: var(--form-padding);
    }

    .close-icon {
        cursor: pointer;
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 20;

        svg {
            path {
                fill: black;
            }
        }

        &:hover {
            svg {
                path {
                    fill: $primary-color;
                }
            }
        }
    }

    .close-button {
        height: calc(1rem - 2px);
        line-height: 1rem;
        position: relative;
        top: -0.5rem;
    }
}