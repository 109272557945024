.notice-banner {
  background-color: #202d63;
  color: white;
  font-size: 14px;
  line-height: 40px;
  text-align: center;

  strong {
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
    background-color: #2e408a;
    transition: all .1s linear;
  }
}

.notice-dialog {
  .notice-dialog-header {
    height: 32px;
    margin: 1em 0;
    background-image: url('../../images/logo-criteria-color.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .notice-dialog-content {
    padding: .5em .25em;
    .notice-dialog-content-header {
      font-size: 1.25em;
      color: #00A1DF;
    }

    strong {
      font-weight: 600;
    }

    .cta {
      display: flex;
      justify-content: flex-end;
    }
  }
}
