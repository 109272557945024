@import "../../styles/breakpoints";
@import "../../styles/common";

.maintenance-page {
  min-height: 100vh;
  width: 100vw;
  display: grid;
  align-items: center;
  justify-items: center;

  @include upTo($screen-desktop) {
    min-height: -webkit-fill-available; /* mobile viewport bug fix */
  }

  background: #004acf;
  background: linear-gradient(45deg, #004acf 0%, #00a3d1 100%);

  color: white;
}