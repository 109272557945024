@import "../../../styles/breakpoints";
@import "../../../styles/common";

.candidates-panel {
  background-color: $default-foreground-color;
  border-right: 1px solid rgba(0,0,0,.05);
  display: grid;
  grid-template-rows: repeat(3, auto);

  @include from($screen-desktop) {
    grid-template-rows: auto 1fr;
    height: calc(100vh - #{$header-height});
    @include sticky();
  }

  .job-details-panel {
    font-size: 1rem;

    @include upTo($screen-desktop) {
      padding: 0.25rem 0.5rem;
    }

    @include from($screen-desktop) {
      padding: 0.5rem 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .job-title-container {
      font-weight: 600;

      .job-title {
        word-break: break-word;
      }

      @include upTo($screen-desktop) {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 0.5rem;
        align-items: center;

        grid-template-areas: "job-title back-link";

        .job-title {
          grid-area: job-title;
          height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 17px;
          font-weight: 800;
        }

        .back-link {
          grid-area: back-link;
          font-size: 0;
          &:before {
            font-size: 1.25rem;
            content: "x";
            color: initial;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }

      .back-link {
        display: block;
        font-weight: 100;
        color: $primary-color;
        text-decoration: none;
        &:hover {
          cursor: pointer;
        }
        span {
          text-decoration: underline;
        }
      }
    }

    .job-location {
      word-break: break-word;
      @include upTo($screen-desktop) {
        display: none;
      }
    }
  }

  .candidate-list {
    @include upTo($screen-desktop) {
      position: relative;
      height: 70px;
      padding: 0 0.5rem;

      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;

      @include no-scroll-bar();
    }
    @include from($screen-desktop) {
      height: 100%;
      overflow: auto;
    }

    .candidate-item {
      user-select: none;
      display: grid;

      @include upTo($screen-desktop) {
        display: inline-block;
        padding: 0.25rem;
        margin-top: 0.75rem;

        &:hover {
          cursor: pointer;
          background-color: var(--hover-color);
        }
      }

      @include from($screen-desktop) {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: repeat(2, 20px);
        grid-template-areas:
                            "avatar firstName"
                            "avatar lastName";
        grid-gap: 0 0.5rem;
        padding: 0.5rem 1rem;

        font-size: var(--candidate-evaluation-font-size);
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: var(--hover-color);
        }

        .candidate-avatar {
          grid-area: avatar;
        }
      }

      .firstName {
        @include upTo($screen-desktop) {
          display: none;
        }

        @include from($screen-desktop) {
          grid-area: firstName;
          color: $primary-color;
          width: calc(var(--candidate-panel-width) - 36px - 2.5rem);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .lastName {
        @include upTo($screen-desktop) {
          display: none;
        }

        @include from($screen-desktop) {
          grid-area: lastName;
          color: $primary-color;
          width: calc(var(--candidate-panel-width) - 36px - 2.5rem);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &.selected {
        @include upTo ($screen-desktop) {

        }

        @include from ($screen-desktop) {
          background-color: $highlight-color;

          &:hover {
            cursor: default;
          }

          .firstName, .lastName {
            color: white;
          }
        }
      }
    }

    .pending, .watch-again, .evaluated {
      font-weight: 400;

      @include upTo($screen-desktop) {
        position: absolute;
        height: 3.5rem;
        border-left: 1px solid $disabled-color;
        margin-left: 0.25rem;

        .watch-again-text,
        .evaluated-text {
          position: absolute;
          top: -0.25rem;
          left: 0.5rem;
          font-size: 0.75rem;
          color: $grey-color;
          text-transform: uppercase;
          user-select: none;
        }

        + .candidate-item {
          margin-left: calc(0.5rem + 1px);
        }
      }

      @include from($screen-desktop) {
        line-height: 2rem;
        padding-left: 1rem;
      }
    }

    .pending-recommendation {
      line-height: 2rem;
      padding-left: 1rem;
      font-weight: 400;

      ~ .pending-recommendation {
        display: none;
      }
    }
  }
}