@import "../../styles/common";

.loader {
  &.triple-box {
    z-index: 2;

    // inspired from https://loading.io/css/

    --box-width: 4px;
    --box-height: calc(4 * var(--box-width));
    --box-distance: var(--box-width);

    display: inline-block;
    position: relative;
    top: calc(-1 * 2 * var(--box-width));
    left: calc(50% - (var(--box-width) * 1.5) - var(--box-distance));
    width: calc((var(--box-width) * 3) + (var(--box-distance) * 2));
    height: var(--box-height);

    &.page {
      position: absolute;
      top: 50%;
      left: 50%;

      --box-width: 6px;

      &.with-header {
        top: calc(50% - 60px);
      }

      div {
        background: $primary-color;
      }
    }

    div {
      display: inline-block;
      position: absolute;
      left: 0;
      width: var(--box-width);
      background: #ddd;
      animation: triple-box-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

      &:nth-child(1) {
        left: 0;
        animation-delay: -0.24s;
      }

      &:nth-child(2) {
        left: calc(var(--box-width) + var(--box-distance));
        animation-delay: -0.12s;
      }

      &:nth-child(3) {
        left:  calc(2 * (var(--box-width) + var(--box-distance)));
        animation-delay: 0;
      }
    }

    @keyframes triple-box-animation {
      0% {
        top: 0px;
        height: calc(2 * var(--box-height));
      }
      50%, 100% {
        top: calc(0.5 * var(--box-height));
        height: var(--box-height);
      }
    }

  }

  &.linear {
    // https://codepen.io/snak3/pen/QOOqGW
    position: relative;
    display: flex;
    align-self: center;

    .loader-progress {
      height: 4px;
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      left: 0;
      background: #eee;
      width: 0;
      animation: linear-animation 2s linear infinite;
    }

    @keyframes linear-animation {
      0% {
        left: 0;
        right: 100%;
        width: 0;
      }
      10% {
        left: 0;
        right: 75%;
        width: 25%;
      }
      90% {
        right: 0;
        left: 75%;
        width: 25%;
      }
      100% {
        left: 100%;
        right: 0;
        width: 0;
      }
    }
  }

}