.final-comment {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 10em;

    .final-comment-title {
        font-weight: 600;
        padding-bottom: 0.5rem;
    }

    .final-comment-content {
        textarea {
            position: relative;
            height: 100% !important;
            overflow-y: scroll;
            resize: none;
        }
    }
}