@import "../../styles/common";

.customDatePicker {
    --default-border-color: #7d8b92;
    --active-border-color: #00A1DF;
    --error-border-color: #f44336;

    min-height: 44px;
    max-height: 66px;
    position: relative;
    justify-self: start;
    user-select: none;
    width: 100%;

    .customDateValue {
        margin: 0;
        position: relative;
        display: block;

        > label {
            z-index: 1;
            padding-left: 0;
            margin-left: 3px;
            line-height: 10px;
            // text-transform: uppercase;
            font-size: 12.8px;
            top: 5px;

            padding-top: 0;
            transform: none !important;
            // transform: translate(0, 3px) scale(0.75) !important;

            &:hover {
                cursor: pointer;
            }
        }

        &.disabled {
            > label {
                color: $disabled-color;

                + div {
                    input {
                        opacity: 0.5;
                    }
                }
            }
        }

        &.error {
            > label {
                color: var(--error-border-color);
            }

            > div {
                input[type=text] {
                    border-bottom-color: var(--error-border-color);
                }
            }
        }

        > div {
            border-bottom-width: 0;
            width: 100%;
            background: white;
            margin-top: 0;

            &:hover {
                cursor: pointer;
            }

            input[type=text] {
                font-family: var(--font-face);
                background-image: url("../../images/datePicker.svg");
                background-repeat: no-repeat;
                background-position: calc(100% - 6px) 22px;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0;
                padding-left: 3px;
                padding-top: 16px;

                border-bottom-color: var(--default-border-color);
                border-bottom-width: 1px;

                &:hover {
                    border-bottom-width: 2px;
                    cursor: pointer;
                    // border-bottom-color: var(--default-border-color);
                }

                &:focus {
                    border-bottom-color: var(--active-border-color);
                    border-bottom-width: 2px;
                }

                &[aria-invalid="true"] {
                    border-bottom-color: var(--error-border-color);
                }
            }
        }

        &.edit-mode {
            label {
                display: none;
            }

            > div {
                border-bottom: 0;
                background: transparent;
                margin-top: 0;
                height: 100%;

                > input[type=text] {
                    font-size: 14px;
                    font-weight: 300;

                    border-bottom-width: 2px;
                    position: relative;
                    height: 100%;
                    transition: background-position 0s linear;

                    padding-top: 12px;
                    padding-bottom: 12px;

                    background-image: none;
                    background-position: calc(100% - 6px) 50%;

                    &[aria-invalid=false] {
                        border-bottom-color: transparent;

                        &:hover {
                            border-bottom-color: var(--default-border-color);
                        }

                        &:focus {
                            border-bottom-color: var(--active-border-color);
                        }
                    }

                    &:hover, &:focus {
                        background-image: url("../../images/datePicker.svg");
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }

    .edit-mode {
        > div {
            line-height: unset;

            input {
                min-height: 47px;
            }
        }
    }
}

.customDurationPicker {
    > label {
        // text-transform: uppercase;
        transition: none !important;
        transform: none !important;
        font-size: 12.8px;
    }
}
