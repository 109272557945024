@import "../../styles/breakpoints";
@import "../../styles/common";

$primary-color: #00A1DF;
$success-color: #00A1DF; // #1ECD97;
$fail-color: #F44336;

.evaluator-preferences {
    > .overlay-form {
        max-width: 700px;

        > .close-icon {
            z-index: 3;
        }

        > .container-form {
            max-height: calc(100vh - 4rem);
            overflow: auto;
        }
    }

    .title {
        font-weight: 600;
        padding-bottom: 20px;

        position: sticky;
        top: -1px;
        background: white;
        z-index: 2;
    }

    .header {
        font-weight: 400;
    }

    .evaluators {
        .label {
            font-size: 12px;
            color: grey;
            margin-left: 4px;
        }

        .value {
            background: $primary-color;
            border-radius: 20px;
            padding: 2px 8px;
            color: white;
            display: inline-block;
            margin-right: 3px;
            margin-bottom: 1px;
            &:last-child {
                margin-right: 0;
            }
        }

        .value-disabled {
            margin-left: 5px;
        }
    }

    .preferences {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        grid-gap: 16px;
        margin-top: 16px;

        @include from($screen-desktop) {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: auto;
        }

        .questions-container {
            .questions {
                max-height: 340px;
                @include scrolling-shadows();
                overflow: auto;

                .question {
                    display: grid;
                    grid-template-columns: 20px 50px 1fr;
                    grid-column-gap: 0.5rem;
                    font-size: 14px;
                    margin-bottom: 1px;
                    padding: 0.5rem;
                    // background-color: white;
                    box-shadow: 0 1px 0 0 rgba(69,90,100,0.05), 0 1px 1px 0 rgba(69,90,100,0.2), 0 1px 5px 0 rgba(69,90,100,0.1);
                }
            }
        }

        .options {
            label {
                width: 100%;

                .superscript {
                    vertical-align: super;
                    font-size: 8px;
                    line-height: 1em;
                }
            }
        }
    }

    .actions {
        text-align: right;
        display: grid;
        grid-template-columns: 1fr auto;
        margin-top: 16px;

        .status-text {
            text-align: left;
            margin-right: 10px;
            line-height: 42px;
            color: $fail-color;
        }

        &.success {
            .status {
                color: $success-color;
            }
        }

        &.fail {
            .status {
                color: $fail-color;
            }
        }
    }

}