@import "../../styles/common";

.icon-attachment {
  g {
    g {
      stroke: var(--icon-color);
    }
  }
}

.icon-chain {
  path {
    scale: 0.5;
    fill: $grey-color;
  }
}

.icon-circle-check-animated {
  width: 75px;

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      animation: dash .9s ease-in-out;
    }
    &.check {
      stroke-dashoffset: -100;
      animation: dash-check 0.9s .35s ease-in-out forwards;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
}

.icon-exclamation-mark,
.icon-information,
.icon-question-mark {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  font-weight: 400;
  border-radius: 50%;
  font-size: 12px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
}

.icon-cross-animated,
.icon-information-animated,
.icon-question-mark-animated {
  font-size: 1.3rem;
  font-weight: 400;

  border: 1px solid;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.5rem;

  transform: scale(3);
}

.icon-cross-animated {
  font-size: 1rem;
  color: $warning-color;
  display: grid;
  justify-items: center;

  .icon-cross-animated-character {
    width: 80%;
    height: 100%;
  }

  animation: bounce-cross 0.9s ease-in-out;

  @keyframes bounce-cross {
    0% {
      transform: scale(2.6);
    }
    40% {
      transform: scale(3.4);
    }
    70% {
      transform: scale(2.8);
    }
    90% {
      transform: scale(3.1);
    }
    100% {
      transform: scale(3);
    }
  }
}

.icon-information-animated {
  color: $primary-color;

  animation: bounce-information 0.9s ease-in-out;

  @keyframes bounce-information {
    0% {
      transform: scale(2.6);
    }
    40% {
      transform: scale(3.4);
    }
    70% {
      transform: scale(2.8);
    }
    90% {
      transform: scale(3.1);
    }
    100% {
      transform: scale(3);
    }
  }
}

.icon-mail {
  width: 16px;
  height: 14px;
  position: relative;
  top: -1px;
  left: -1px;

  > g {
    transform: scale(0.35);
  }
}

.icon-mail-delivered {
  width: 19px;
  height: 19px;

  #icon-mail-delivered-envelope {
    transform: scale(0.35);
  }

  #icon-mail-delivered-tick {
    transform: scale(0.8);
    path {
      fill: $active-color;
    }
  }
}

.icon-mail-open {
  width: 19px;
  height: 19px;
  position: relative;
  top: 1px;

  > g {
    transform: scale(0.35);
  }

  #icon-mail-delivered-tick {
    transform: scale(0.8);
    path {
      fill: $active-color;
    }
  }
}

.icon-question-mark-animated {
  color: $pending-color;

  animation: bounce-question 0.9s ease-in-out;

  @keyframes bounce-question {
    0% {
      transform: scale(2.6);
    }
    40% {
      transform: scale(3.4);
    }
    70% {
      transform: scale(2.8);
    }
    90% {
      transform: scale(3.1);
    }
    100% {
      transform: scale(3);
    }
  }
}

.icon-refresh {
  width: 10px;
  margin-left: 8px;

  path {
    fill: #00A1DF;
  }
}

.icon-text-bubble {
  transform: scale(.65);

  g {
    stroke: black;
  }
}

.icon-trash {
  width: 16px;
  opacity: 0.3;

  &:hover {
    opacity: 0.8;
  }
}

.icon-yes-no {
  color: $default-foreground-color;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  padding: 1px 4px;
  border-radius: 4px;
}

.icon-yes {
  background-color: $active-color-dark;
}

.icon-no {
  background-color: $pending-color;
}
