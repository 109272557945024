@import "../../styles/breakpoints";
@import "../../styles/common";

.job-details {
    background-color: #f2f2f2;
    scroll-behavior: smooth;
    @include contentOverflow();

    &.integration {
        @include contentOverflow(100vh);
    }

    &.has-intercom {
        padding-bottom: 6rem;
    }

    > .header {
        padding: 1rem 0.5rem 0.5rem;
        display: grid;
        grid-template-rows: auto;
        grid-template-areas: "breadcrumbs title-id menu";
        grid-template-columns: auto 1fr auto;
        grid-gap: 1rem;

        @include from($screen-desktop) {
            padding: 1.5rem 1.5rem 1rem;
            grid-template-columns: 200px calc(100% - 400px - 2rem) 200px;
        }

        .breadcrumbs {
            grid-area: breadcrumbs;

            .back-link {
                color: $primary-color;
                cursor: pointer;

                span {
                    text-decoration: underline;
                }
            }
        }
        .title-id {
            grid-area: title-id;
            text-align: center;

            .title {
                text-transform: capitalize;
                font-size: 1.8em;
                line-height: 1.2em;
                font-weight: 700;
                display: inline;

                &.test {
                    font-style: italic;
                }
            }
            .id {
                color: #7d8b92;
                font-weight: 400;
                padding-left: 8px;
                vertical-align: super;
            }
        }
        .menu {
            grid-area: menu;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            grid-template-areas: "actions";
            grid-gap: 8px;
            justify-items: right;

            @include from($screen-desktop) {
                grid-template-columns: 1fr auto;
                grid-template-areas: "short-link actions";
            }

            .short-link {
                grid-area: short-link;
                display: none;

                @include from($screen-desktop) {
                    display: block;
                }
            }
            .send-to-pageup-link {
                grid-area: short-link;
                display: none;

                @include from($screen-desktop) {
                    display: block;
                }
            }
            .actions {
                grid-area: actions;
                display: inline-block;

                .selections {
                    width: 150px;

                    .selection {
                        .firstLine {
                            text-align: right;
                        }
                    }
                }
            }
            .evaluate {
                grid-area: evaluate;
                display: none;

                .react-button {
                    font-size: 14px;
                    padding: 0.25rem 0.5rem;
                    width: 94px;
                    display: inline-block;
                    height: 1.75rem;
                    line-height: 20px;
                    text-align: center;
                }

                @include from($screen-desktop) {
                    display: inline-block;
                }
            }
        }
    }

    .summary {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;
        grid-template-areas: "location created-at job-status";
        justify-content: center;
        justify-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);

        margin: 0 12px;
        padding-bottom: 0.75rem;
        grid-gap: 0 12px;

        @include from($screen-desktop) {
            margin: 0 1.5rem;
            padding-bottom: 1rem;
        }

        @include from($screen-desktop-l-min) {
            grid-gap: 24px;
        }

        .label-value-tile {
            border: 0;

            .value {
                font-weight: 600;
                white-space: unset;
            }
        }

        .location, .job-status {
            padding-bottom: 0;
        }

        .location {
            grid-area: location;
        }

        .created-at {
            grid-area: created-at;
        }

        .job-status {
            grid-area: job-status;
        }
    }

    .job-stage-header {
        .job-stage-menu {
            position: absolute;
            top: 0;
            right: 0;

            display: grid;
            grid-template-columns: repeat(3, auto);

            .react-button {
                margin-right: 0;
                padding: 0 0.75rem;
                height: 28px;
                line-height: 26px;

                ~ .short-link {
                    margin-left: 0.75rem;
                }
                ~ .job-stage-actions {
                    margin-left: 0.75rem;
                }
            }

            .short-link {
                ~ .job-stage-actions {
                    margin-left: 0.75rem;
                }
            }

            .job-stage-actions {
                .selections {
                    width: 170px;
                }
            }
        }
    }

    .candidates-invited {
        @include upTo($screen-desktop) {
            margin-top: 0.5rem;
        }
    }

    .job-more-candidates {
        margin: 1rem 0.5rem 0.5rem;

        @include from($screen-desktop) {
            margin: 24px;
        }

        .header {
            text-align: center;
            font-weight: bold;
            font-size: 17px;
            user-select: none;

            @include upTo($screen-desktop) {
                margin-top: 1rem;
                margin-bottom: .5rem;
            }
        }

        .closed {
            text-align: center;
            margin-top: 1rem;

            .link {
                color: $primary-color;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .react-candidates {
            .candidates-list {
                max-height: none;
            }
        }

        .candidates-add {
            display: grid;
            grid-gap: 1rem;
            padding: 1rem 0;
            text-align: center;

            .candidates-add-button {
                justify-self: center;
            }
        }

        .candidates-list {
            .new-candidate {
                background-color: transparent;
            }
        }
    }

    &.evaluator {
        .summary {
            grid-template-columns: repeat(2, auto);
            grid-template-areas:
                "candidates candidates"
                "location deadline";

            @include from($screen-desktop-s-min) {
                min-width: $screen-desktop-s-min;
                grid-gap: 24px;
                grid-template-rows: auto;
                grid-template-columns: 1fr repeat(3, auto) 1fr;
                grid-template-areas: "blank-left candidates location deadline blank-right";
            }

            .candidates {
                .tiles {
                    grid-template-columns: repeat(2, 1fr);

                    @include from($screen-desktop-s-min) {
                        grid-template-columns: repeat(2, minmax(60px, 70px));
                        justify-self: right;
                    }
                }
            }
        }
    }
}

.custom-modal {
    &.candidates-invite-modal {
        .overlay-form {
            width: auto;
        }
    }
}
