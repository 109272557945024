$primary-color: #00A1DF;
$success-color: #00A1DF; // #1ECD97;
$fail-color: #F44336;

.candidate-action {
    &.multiple {
        .overlay-form {
            width: 400px;
        }
    }

    &.no-modal {
        padding: 1rem;
    }

    .candidate-labels, .closing-date, .prepTime, .answerTime {
        .label {
            font-size: 12px;
            color: grey;
            margin-left: 4px;
        }

        .value {
            background: $primary-color;
            border-radius: 20px;
            padding: 2px 8px;
            color: white;
            display: inline-block;
            margin-right: 3px;
            margin-bottom: 1px;
            &:last-child {
                margin-right: 0;
            }
        }

        .value-disabled {
            margin-left: 5px;
            font-weight: 500;
        }
    }

    .closing-date {
        margin: 10px 3px 0 3px;
        width: fit-content;

        &.disabled-true {
            margin-left: 0;
        }
    }

    .deadline-timezone {
        margin: 10px 3px 0 3px;
    }

    .questions {
        margin-top: 16px;
    }

    .no-invitation-email-message {
        margin-bottom: -.5rem;
    }

    .actions {
        text-align: right;
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 16px;
        grid-gap: 1rem;
        align-items: center;

        .status-message {
            background: transparent;
            color: $fail-color !important;
            line-height: 2rem;
        }
    }

}