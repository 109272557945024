@import "../../styles/common";
@import "../../styles/breakpoints";

$primary-color: #00A1DF;
$border-color: #eee;
$background-color: #f9fafa;

.job-edit {
    background: $background-color;

    @include contentOverflow();

    &.integration {
        @include contentOverflow($height: 100vh);
    }

    &.has-intercom {
        .job-steps {
            .step {
                &:last-child {
                    .step-content {
                        margin-bottom: 4rem;
                    }
                }
            }
        }
    }

    .job-header {
        display: grid;
        grid-template-columns: 66px 1fr 66px;
        grid-template-rows: auto;
        grid-gap: 8px;
        padding: 1rem;
        background: $background-color;
        border-bottom: 2px solid rgba(0,0,0,0.05);
        align-items: start;

        position: sticky;
        top: 0;
        z-index: 6;
        transition: all 0.2s linear;

        @include upTo($screen-desktop) {
            grid-template-columns: 92px calc(100% - (2*(92px + 8px))) 92px;
        }

        @include from ($screen-desktop) {
            grid-template-columns: 128px 1fr 128px;
            padding: 1rem;
        }

        .back-container {
            .back-link {
                color: $primary-color;
                cursor: pointer;

                span {
                    text-decoration: underline;
                }
            }
        }

        .job-name {
            width: 100%;
            overflow: hidden;

            .job-title {
                font-size: 1.5rem;
                font-weight: bold;
                text-align: center;

                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include from ($screen-desktop) {
                    font-size: 2rem;
                }

                .job-id {
                    line-height: 10px;
                    font-size: 12px;
                    top: -1rem;
                    font-weight: normal;
                    color: $grey-color;
                    padding-left: 0.5rem;
                }
            }
        }

        .job-actions {
            .save-button {
                width: 100%;
            }
        }
    }

    .job-steps {
        padding: 0;

        .step {
            display: grid;
            grid-template-rows: 1fr auto;
            grid-template-columns: auto;
            background: transparent;
            margin: 0;
            height: auto;
            overflow: unset;

            @include from($screen-desktop) {
                grid-template-rows: auto;
                grid-template-columns: 160px 1fr;
                grid-gap: 0;
                @include boxShadow($color-1: rgba(0,0,0,0.05), $color-2: transparent, $color-3: transparent);
                margin: 0 0 1px 0;
            }

            .step-header-text {
                font-weight: bold;
                border-bottom: 1px solid $border-color;
                padding: 1rem;
                background: #f2f3f4;

                @include upTo($screen-desktop) {
                    position: sticky;
                    top: 77px;
                    z-index: 5;
                }

                @include from($screen-desktop) {
                    padding: 1.5rem;
                    font-size: 17px;
                }

                .header-title {
                    @include from($screen-desktop) {
                        position: sticky;
                        top: 7rem;
                    }
                }
            }

            .step-content {
                padding: 1rem;

                @include from($screen-desktop) {
                    padding: 1.5rem;
                    background: $background-color;
                }
            }

            &.job-details {
                .step-content {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(5, auto) 1fr;
                    grid-template-areas:
                        "job-title"
                        "location"
                        "interview-terminology"
                        "deadline"
                        "branding-container"
                        "position_description";
                    grid-gap: 1rem;

                    @include from($screen-desktop) {
                        grid-template-columns: 1fr 2fr;
                        grid-template-rows: repeat(4, auto) 1fr;
                        grid-template-areas:
                            "job-title position_description"
                            "location position_description"
                            "interview-terminology position_description"
                            "deadline position_description"
                            "branding-container position_description";
                        grid-gap: 1rem 2rem;
                    }

                    .label-value-tile {
                        .label {
                            font-size: 12px;
                        }
                        .value {
                            font-weight: 600;
                        }
                    }

                    .location {
                        grid-area: location;
                    }

                    .job-deadline {
                        grid-area: deadline;
                    }

                    &.read-only {
                        .job-title, .location, .terminology, .job-deadline, .job-branding {
                            border-top: 0;
                            padding: 0;

                            .label {
                                font-size: 12.8px;
                                text-transform: none;
                            }
                        }
                    }

                    // the radio button
                    .interview-terminology {
                        grid-area: interview-terminology;
                        display: grid;
                        grid-template-rows: repeat(2, 1rem);
                        grid-template-columns: repeat(2, 50%);
                        grid-template-areas:
                            "label label"
                            "item-interview item-assessment";

                        .label {
                            grid-area: label;
                        }

                        .item-interview {
                            grid-area: item-interview;
                        }

                        .item-assessment {
                            grid-area: item-assessment;
                        }
                    }

                    .branding-container {
                        .branding-header {
                            text-transform: none;
                            font-weight: 700;
                            padding-bottom: 0.5rem;
                        }

                        .branding-dropdown {
                            > .selector {
                                background-color: white;

                                > .placeholder {
                                    top: 5px;
                                    left: 3px;
                                }
                            }

                            .firstLine {
                                left: 4px;
                            }
                        }

                        .branding-preview-link {
                            display: inline-block;
                            cursor: pointer;
                            padding-top: 0.25rem;
                            font-size: 14px;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .position_description {
                        grid-area: position_description;

                        .quill {
                            .ql-container {
                                overflow: auto;
                                height: 180px;

                                @include from($screen-desktop) {
                                    height: 395px;
                                }

                                .ql-editor {
                                    max-height: unset;
                                }
                            }
                        }
                    }
                }
            }
            &.questions {
                .section-header {
                    font-weight: 600;
                    padding: 0 0 0.5rem;
                    font-size: 15px;

                    &.disabled {
                        opacity: 0.2;
                    }
                }

                .step-content {
                    .step-assessment {
                        .section-header {
                            padding: 0;
                        }
                    }
                }
            }

            &.evaluators {
                .step-content {
                    .job-owner {
                        .staff-selection {
                            width: 300px;
                            padding-bottom: 1rem;
                        }
                    }

                    .candidate-contact {
                        padding-bottom: 1rem;
                        .candidate-contact-header {
                            font-weight: 700;
                        }

                        .candidate-contact-note {
                            font-size: 14px;
                            padding-bottom: 0.5rem;
                        }

                        .candidate-contact-name {
                            width: 300px;
                            padding-bottom: 0.5rem;
                        }

                        .candidate-contact-email {
                            width: 300px;
                            padding-bottom: 0.5rem;
                        }
                    }

                    .job-evaluators {
                        .job-evaluators-header {
                            text-transform: none;
                            font-weight: 700;
                            padding-bottom: 0.5rem;
                        }
                    }

                    .evaluator-selection {
                        @include upTo($screen-desktop) {
                            grid-template-rows: auto;
                            grid-template-columns: 1fr auto;
                            margin-bottom: 1.5rem;
                        }

                        .staff-dropdown {
                            background: white;
                        }
                    }
                }
            }
        }
    }

    .branding-preview-container {
        .overlay-form {
            width: 90vw;

            .container-form {
                padding: 0;
            }
        }
    }
}

.status-message {
    position: sticky;
    top: 0;
    z-index: 1;

    &.message-show {
        + .job-edit {
            .job-header {
                top: 32px;
            }
        }
    }
}
