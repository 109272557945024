@import "../../styles/breakpoints";
@import "../../styles/common";

.library-page {
    background: #f2f2f2;
    min-height: calc(100vh - 104px);

    &.has-intercom {
        padding-bottom: 6rem;
    }

    @include from($screen-desktop) {
        @include contentOverflow($height: $content-height-after-header-and-subheader);

        &.integration {
            @include contentOverflow($height: $content-height-after-subheader);

            .library {
                .library-filter-container {
                    @include contentOverflow($height: calc(100vh - 104px));
                }
            }
        }
    }

    .custom-modal {
        &.item-details {
            .overlay-form {
                .container-form {
                    max-height: 90vh;
                    overflow-x: hidden;
                    overflow-y: auto;
                }
            }
        }
    }
}