@import "../../styles/breakpoints";
@import "../../styles/common";

.email-template-selection {
  margin-bottom: 1rem;

  .header {
    font-weight: 700;
  }

  .subheader {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }

  .email-template-row {
    padding: .5rem;
    display: grid;
    grid-gap: .25rem;
    align-items: center;

    @include boxShadow();

    @include upTo($screen-desktop) {
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto auto;

      grid-template-areas:
        "checkbox type preview"
        "checkbox dropdown preview";

      .email-checkbox {
        grid-area: checkbox;
        align-self: start;
      }

      .type {
        grid-area: type;
      }

      .email-dropdown {
        grid-area: dropdown;
      }

      .email-preview {
        grid-area: preview;
      }
    }

    @include from($screen-desktop) {
      grid-template-columns: auto auto 1fr auto;
      grid-template-rows: auto;
    }

    &.disabled {
      color: $grey-color;
    }

    .email-checkbox {
      margin-right: 0.25rem;
    }

    .email-type {
      display: inline-block;
      font-weight: 400;
    }

    .email-dropdown {
      min-width: 100%;
      justify-self: left;
    }

    .email-preview {
      color: $primary-color;
      cursor: pointer;
      justify-self: end;
      margin-left: 1rem;

      @include upTo($screen-desktop) {
        align-self: start;
      }

      &:hover {
        text-decoration: underline;
      }

      &.disabled {
        color: $disabled-color;
        cursor: default;
        text-decoration: none;
      }
    }
  }
}
