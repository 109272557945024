@import "../../styles/common";

.no-invitation-email-message {
  font-size: 14px;
  color: $primary-color;
  margin-top: 1rem;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: .5rem;
  align-items: start;

  .icon-exclamation-mark {
    margin-top: .25rem;
  }
}
