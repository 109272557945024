@import "../../../styles/common";

.candidate-feedback {
    display: grid;
    background: white;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
    @include boxShadow();

    .candidate-feedback-title {
        font-weight: 600;
        padding-bottom: 0.5rem;
    }

    .no-candidate-feedback {
        color: rgba(0,0,0,0.25);
    }
}