@import "../../../../styles/common";

.candidate-response-question {
    .display-text {
        font-weight: 600;
    }

    .extend-link {
        padding-left: 6px;
        color: $primary-color;
        cursor: pointer;
        text-decoration: underline;
    }
}