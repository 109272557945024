@import "../../styles/breakpoints";
@import "../../styles/common";

.sms-template-selection {
  display: grid;
  grid-gap: 0.5rem;

  .header {
    font-weight: 700;
  }

  .invitation_sms_dropdown
  {
    background-color: $default-foreground-color;
    @include from($screen-desktop) {
      width: 50%;
    }
  }

  .reminder-sms-list {
    margin-top: 1rem;

    .subheader {
      font-size: 14px;
      margin-bottom: 0.5rem;

      .no-reminder-templates {
        color: $warning-color;
      }
    }

    .reminder-sms {
      display: grid;
      grid-template-columns: 25px auto 300px auto 140px 1fr auto;
      grid-gap: 0.5rem;

      align-items: center;
      padding: 0.5rem;

      @include boxShadow();

      &.is_duplicate {
        background-color: $warning-background-color;
      }

      .index {
        justify-self: center;

        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-color: $primary-color;
          color: white;
          text-align: center;
          border-radius: 50%;
          font-size: 14px;
        }
      }

      .actions {
        .icon-trash {
          cursor: pointer;
        }
      }

      + .add {
        margin-top: 0.5rem;
      }
    }

    .add {
      display: inline-block;
      transition: none;
    }

    .duplicate {
      margin: 0.5rem 0;
      font-size: 14px;
      color: $warning-color;
    }
  }
}