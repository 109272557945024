$primary-color: #00A1DF;
$primary-color-hover: #7d8b92;

$enabled-checkbox-color: #666;
$enabled-checkbox-color-checked: $primary-color;
$disabled-border-color: #ddd;
$enabled-check-color: white;

.customCheckbox {
    &.native {
        input[type="checkbox"] {
            // to revert materialize
            opacity: 1;
            position: relative;
            left: 0;
            width: 14px;
            height: 14px;
        }

        .label {
            display: inline-block;
            margin-left: 0.4rem;
            font-size: 14px;
            text-transform: capitalize;
            position: relative;
            top: 4px;
            white-space: nowrap;
            width: calc(100% - 14px - 0.4rem);
            overflow: hidden;
            text-overflow: ellipsis;

            &.disabled {
                opacity: 0.2;
            }
        }
    }

    &.custom {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 0.4rem;
        position: relative;

        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
        }

        .checkbox {
            margin-top: 4px;
            cursor: pointer;
            border: 1px solid $enabled-checkbox-color;
            background: white;

            display: inline-block;
            border-radius: 2px;

            svg {
                position: absolute;
                opacity: 0;
            }

            &.checked {
                border: 1px solid $enabled-checkbox-color-checked;
                background-color: $enabled-checkbox-color-checked;

                svg {
                    opacity: 1;
                }
            }

            &.disabled {
                border-color: $disabled-border-color;
                background-color: $disabled-border-color;
                cursor: default;
            }
        }

        &.native {
            input[type="checkbox"] {
                position: relative;
                left: unset;
                opacity: 1;
            }

            .checkbox {
                display: none;
            }
        }

        .label {
            display: inline-block;
            text-transform: capitalize;
            margin-left: 0.25rem;
            user-select: none;

            &.disabled {
                color: rgba(0, 0, 0, 0.25);
            }
        }

        &.small {
            .checkbox {
                width: 0.75rem;
                height: 0.75rem;

                svg {
                    top: 6px;
                    left: 2px;
                }
            }

            .label {
                font-size: 14px;
                margin-left: 0;
            }
        }

        &.medium {
            .checkbox {
                width: 1rem;
                height: 1rem;

                svg {
                    transform: scale(1.2);
                    top: 8px;
                    left: 4px;
                }
            }
        }
    }
}