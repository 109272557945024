@import "../../styles/common";
@import "../../styles/breakpoints";

.marketplace {
    @include from($screen-desktop) {
        @include contentOverflow($height: $content-height-after-header-and-subheader);
        display: grid;
        justify-content: center;
    }

    .marketplace-items {
        padding: 1rem;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, 280px);
        grid-gap: 1rem;
        align-content: center;

        @include from($screen-desktop) {
            width: 100vw;
            max-width: 1200px;
        }
        // margin: 0 auto;

        .item {
            background: white;
            border: 1px solid #eee;
            border-radius: 3px;
            padding: 1.5rem;
            display: grid;
            grid-template-rows: 6rem 4rem 1fr 42px;
            @include boxShadow;

            width: 280px;
            user-select: none;

            .logo {
                height: 6rem;
                font-size: 2rem;
                display: grid;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }

            .label-value-tile {
                border-top: 0;

                .value {
                    font-weight: 600;
                }
            }

            .description {
                padding: 0 0 2rem;
                font-weight: 400;
            }

            .react-button {
                width: 150px;
                text-align: center;
            }

            .hidden {
                display: none;
            }
        }
    }
}
