@import "../../styles/common";

.branding-preview {
    --padding-size: 1rem;

    background: #f6f7f7; // default color from common.scss - html
    display: grid;
    grid-template-columns: minmax(250px,20%) minmax(300px,25%) auto;
    grid-gap: 0;
    grid-template-areas: "section-1 section-2 section-3";

    .welcome-details {
        display: grid;
        background-color: var(--welcome-details-background-color);
        grid-template-rows: 7rem auto 1fr auto;

        padding: calc(var(--padding-size) * 3) calc(var(--padding-size) * 2) calc(var(--padding-size) * 2);

        .welcome-details-logo {

            .logo {
                width: 100%;
                max-width: 100%;

                background-image: var(--welcome-logo);
                background-repeat: no-repeat;
                background-size: contain;

                height: var(--welcome-logo-height);
                margin-top: calc(var(--welcome-logo-margin-correction-factor) * -1 * 32px * 1.5);
            }
        }

        .welcome-details-content {
            .welcome-details-info {
                position: relative;
                display: block;
                margin-bottom: 1rem;

                .welcome-details-info-label {
                    height: 20px;
                    .label {
                        text-transform: uppercase;
                        font-size: 0.75rem;
                        letter-spacing: 1px;
                        font-weight: 300;
                        display: inline-block;
                        line-height: 12px;

                        color: var(--welcome-details-label-color);
                    }
                }

                .welcome-details-info-value {
                    margin-bottom: 1rem;
                    .value {
                        font-size: 1rem;
                        line-height: 16px;
                        display: inline-block;
                        font-weight: 800;
                        text-transform: uppercase;
                        color: var(--welcome-details-value-color);
                    }
                }
            }

            .welcome-details-position-description {
                position: relative;
                padding-top: 1.5rem;

                .position-description-border-color-picker {
                    position: absolute;
                    right: 3.5rem;
                    bottom: 1rem;
                    z-index: 1;
                }

                .position-description-background-color-picker {
                    position: absolute;
                    right: 2rem;
                    bottom: 1.5rem;
                    z-index: 1;
                }

                .position-description-color-picker {
                    position: absolute;
                    right: 0.5rem;
                    bottom: 2rem;
                    z-index: 1;
                }

                .position-description-icon-color-picker {
                    position: absolute;
                    left: -0.75rem;
                    bottom: 2rem;
                    z-index: 1;
                }

                .welcome-details-description {
                    position: relative;
                    display: inline-block;
                    margin-bottom: 2rem;
                    padding: 0.25rem 0.5rem;
                    font-size: 1rem;
                    font-weight: 400;
                    user-select: none;
                    line-height: 1.15;

                    background-color: var(--welcome-position-description-background-color);
                    border: 1px solid var(--welcome-position-description-border-color);
                    color: var(--welcome-position-description-color);

                    .icon-paragraph {
                        margin-right: 0.5rem;
                        .icon-highlight {
                            fill: var(--welcome-position-description-icon-color);
                        }
                    }
                }
            }
        }

        .welcome-details-background {
            height: 1rem;
            margin-bottom: 1rem;
        }

        .welcome-details-powered {
            position: relative;
            display: inline-block;

            margin: 1rem 0 0;
            line-height: 1rem;
            font-size: 13px;
            align-self: end;

            user-select: none;

            color: var(--welcome-details-color, #656877);
        }
    }
    .welcome-form {
        background-color: #fff; // default color from common.scss - .registration-form
        padding: calc(var(--padding-size) * 3) calc(var(--padding-size) * 2) calc(var(--padding-size) * 2);
        display: grid;
        grid-template-rows: auto 1fr auto auto;

        .greeting {
            margin-top: 9rem;
            margin-bottom: .5rem;
        }

        .links {
            text-decoration: underline;
            user-select: none;
            color: var(--link-color);

            .second-link {
                display: block;
                margin-top: .5rem;
            }
        }

        .terms {
            .terms-checkbox {
                width: 213px;
                display: inline-block;

                .checkbox {
                    svg {
                        path {
                            stroke: var(--checkbox-checked-color);
                        }
                    }
                }

                .checked {
                    border-color: var(--checkbox-background-color);
                    background-color: var(--checkbox-background-color);
                }

                .label {
                    a {
                        color: var(--link-color);
                    }
                }
            }

            .terms-label {
                text-transform: none;
                font-size: 1rem;
                a {
                    text-transform: capitalize;
                    border-bottom: 1px dotted;
                    color: var(--link-color);
                }
            }
        }

        .start-button-container {
            position: relative;
            margin-top: 1.5rem;

            .button-background-color {
                position: absolute;
                right: -20px;
                top: 9px;
            }

            .start-button {
                width: 100%;
                text-align: center;
                background-color: var(--button-background-color);
                color: var(--button-color);
                border: 0;
                border-radius: 2px;
                outline: none;
                height: 40px;
                line-height: 2.4rem;
                font-weight: 400;
            }
        }
    }
    .page-banner {
        background-color: #EEE;
        background-image: var(--welcome-background-image);
        background-position: var(--welcome-background-position);
        background-repeat: no-repeat;
        background-size: cover;

        position: relative;
        width: 100%;
        overflow: hidden;

        .page-banner-title {
            position: absolute;
            left: 56px;
            bottom: 30px;

            font-size: var(--welcome-message-font-size);
            font-weight: var(--welcome-message-font-weight);
            font-stretch: normal;
            font-style: normal;
            line-height: var(--welcome-message-font-size);
            letter-spacing: 0.02em;
            text-transform: uppercase;

            .welcome-message-line-1, .welcome-message-line-2 {
                display: block;
            }

            .first-line, .second-line {
                display: inline-block;
                white-space: nowrap;

                background-color: var(--welcome-message-background-color);
                color: var(--welcome-message-color);
            }

            .welcome-message-first-line-override-color {
                position: absolute;
            }

            .first-line {
                padding: .375em .5em 0;
                color: var(--welcome-message-first-line-override-color, var(--welcome-message-color));
            }

            .welcome-message-background-color {
                display: inline;
                position: absolute;
                top: .5em;
                margin-left: -0.375em;
            }

            .second-line {
                padding: .375em .5em .25em;
                color: var(--welcome-message-color);
            }

            .welcome-message-color {
                margin-left: -.75em;
            }
        }
    }
    .color-picker-button {
        @include boxShadow($color-3: rgba(69, 90, 100, 1));
    }
}
