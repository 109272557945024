@import "../../../styles/breakpoints";

$quill-toolbar-wrap-breakpoint: 443px;
$header-height: 60px;

.step-job-details {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto) 1fr;
    grid-template-areas:
        "title"
        "location"
        "deadline"
        "branding"
        "position_description";
    grid-gap: 1rem;
    height: 100%;

    @include from($screen-desktop) {
        grid-gap: 1rem 2rem;
    }

    @include from($screen-desktop-l-min) {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: repeat(3, auto) 1fr;
        grid-template-areas:
            "title position_description"
            "location position_description"
            "deadline position_description"
            "branding position_description";
    }

    .title {
        grid-area: title;
    }

    .location {
        grid-area: location;
    }

    .job-deadline {
        grid-area: deadline;
        margin-top: 0.5rem;
    }

    .branding-container {
        grid-area: branding;
        margin-top: 0.5rem;

        .branding-header {
            text-transform: none;
            font-weight: 700;
            padding-bottom: 0.5rem;
        }

        .branding-dropdown {
            > .selector {
                background-color: white;

                > .placeholder {
                    top: 5px;
                    left: 3px;
                }
            }

            .firstLine {
                left: 4px;
            }
        }

        .branding-preview-link {
            padding-top: 0.25rem;
            font-size: 14px;
        }
    }

    .deadline {
        grid-area: deadline;

        align-self: start;
        display: grid;
        height: auto;

        input {
            background-color: white !important;
        }
    }

    .position_description {
        grid-area: position_description;

        @include from($screen-desktop-l-min) {
            margin-bottom: 2rem;

            .quill {
                height: calc(100% - 43px);

                .ql-container {
                    .ql-editor {
                        max-height: 100%;
                    }
                }
            }
        }
    }

    &.no-header {
    }

    .branding-preview-container {
        .overlay-form {
            width: 90vw;

            .container-form {
                padding: 0;
            }
        }
    }

    .job-template-selection {
        .overlay-form {
            width: 40rem;
            min-height: 15rem;

            .container-form {
                padding: 0;
            }
        }
    }
}
