@import "../../../styles/common";

$border-color-standard: #7d8b92;
$error-color: #f44336;

.customTextArea {
    position: relative;
    .label {
        font-size: 12.8px;
        line-height: 12.8px;
        color: #7d8b92;
        display: block;
        margin-bottom: 4px;

        &.active {
            color: var(--primary-color);
        }

        &.error {
            color: $error-color;
        }
    }

    textarea {
        border-width: 0 0 1px 0;
        border-color: $grey-color;
        min-height: auto;
        height: auto;
        transition: all 0.05s linear;
        font-size: 14px;

        &.non-resizable {
            resize: none;
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            color: $disabled-color;
            border-color: $disabled-color;
        }
    }

    &.editable {
        textarea {
            &:hover {
                border-bottom-width: 2px;
                // border-color: $primary-color;
            }

            &:focus {
                border-bottom-width: 2px;
                border-color: var(--primary-color);
            }
        }

        .label.active + textarea:focus {
            border-color: var(--primary-color);
        }

        .label.error + textarea:focus {
            border-color: $error-color;
        }
    }

    &.readOnly {
        .toolbar {
            display: none;
        }

        textarea {
            cursor: default;
        }

        .quill {
            border-color: transparent !important;

            .ql-container {
                border-color: transparent !important;
                &:hover {
                    border-color: transparent !important;
                }
            }
        }
    }

    .toolbar {
        &.hidden {
            display: none;
        }

        &.small {
            .ql-toolbar {
                padding: 0;
                @include boxShadow();

                .ql-formats {
                    margin-right: 0.2rem;
                    padding-right: 0.2rem;
                    border-right: 1px solid $disabled-color;

                    &:last-child {
                        margin-right: 0;
                        padding-right: 0;
                        border: 0;
                    }
                }
            }
        }
    }

    .toolbar-toggle {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        padding: 0 4px;
        color: white;
        font-weight: 400;
        border-radius: 3px;
        user-select: none;
        background-color: $disabled-color;

        &.no-label {
            top: -1.25rem;
        }

        &:hover {
            cursor: pointer;
            background-color: var(--active-color-semi);
        }

        &.active {
            background-color: var(--active-color);
        }
    }

    .quill {
        transition: all 0.3s;
        border-bottom: 1px solid $border-color-standard !important;

        &:focus-within, &:focus {
            border-bottom: 1px solid var(--primary-color) !important;
        }

        .ql-container {
            transition: all 0.3s;
            border-bottom: 1px solid transparent !important;
            font-family: var(--font-face);
            font-size: 14px;

            &:hover {
                border-bottom: 1px solid $border-color-standard !important;
            }

            &:focus-within, &:focus {
                border-bottom: 1px solid var(--primary-color) !important;
            }

            .ql-editor {
                min-height: 80px;
                max-height: 160px;
                padding: 0;

                &.ql-blank {
                    &:before {
                        left: 0;
                        color: rgba(0, 0, 0, 0.2);
                        font-style: normal;
                    }
                }
            }

            .ql-tooltip {
                left: 0 !important;
            }

        }
    }

    .label {
        &.error {
            + .quill {
                border-bottom-color: $error-color !important;

                .ql-container {
                    border-bottom-color: $error-color !important;
                }
            }
        }
    }

    &.padding {
        .quill {
            .ql-container {
                .ql-editor {
                    position: relative;
                    padding: 0.75rem 0;
                    margin: 0 0.75rem;
                }
            }
        }
    }

    .char-count {
        font-size: 14px;
        color: $grey-color;
    }

    .helper-text {
        font-size: 14px;
        color: $grey-color;
        transition: all 0.2s linear;
        opacity: 1;

        &.empty {
            height: 0;
            opacity: 0;
        }
    }
}


.customTextArea.email {
    // width: 600px !important;

    .quill .ql-container .ql-editor {
        /* Match email template styles */
        padding: 18px 40px 40px 40px;

        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;
        line-height: 1.6em;

        p {
            margin: 1.6em 0 0 0;
        }

        .ql-placeholder-content {
            background-color: rgba(0, 163, 209, 0.2);
            padding: 0.3em 0.5em;
            border-radius: 0.5em;
        }
    }
}


// Copy/paste from quill-placeholder-module/dist/toolbar.css.
.ql-picker.ql-placeholder {
    width: 118px;
}

.ql-picker.ql-placeholder > span.ql-picker-label::before {
    content: 'Placeholders';
}

.ql-picker.ql-placeholder > span.ql-picker-options > span.ql-picker-item::before {
    content: attr(data-label);
}
