@import "../../../styles/common";

.customColorPicker {
    display: inline-block;
    position: relative;
    color: initial;
    user-select: none;

    @include firefox-only {
        top: -7px;
    }

    &.has-manual-input {
        .color-picker-button {
            position: absolute;
            right: 2px;
            bottom: 6px;
        }
    }

    .customColorPickerManualInput {
        .input-field {
            input {
                width: 100%;
                overflow: hidden;
                padding-right: 24px;
                text-overflow: ellipsis;
                background-color: transparent;
            }
        }
    }

    .color-picker-button {
        user-select: none;
        border-width: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        background-color: transparent;

        &:hover {
            cursor: pointer;
        }

        &.square {
        }

        &.circle {
            border-radius: 50%;
            margin-left: 0.25rem;
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
        }
    }

    --preset-colors-padding: 0.5rem;
    --color-picker-height: 1.4rem;
    --color-picker-width: 2.1rem;

    .color-picker {
        position: absolute;
        z-index: 2;

        &.top {
            top: -2px;
        }

        &.bottom {
            top: 100%;
        }

        &.left {
            left: -10px;
        }

        &.right {
            right: -10px;
        }

        &.preset-grid {
            &.top {
                top: calc(-1 * (
                    (2 * var(--preset-colors-padding))
                    + (6 * var(--color-picker-height))
                    + (5 * 1px)
                ));

                .color-picker-arrow-border {
                    position: absolute;
                    bottom: -6px;
                    border-top: 6px solid #bbb;
                    border-bottom: none;

                    .color-picker-arrow {
                        border-bottom: none;
                        border-top: 5px solid white;
                        top: -7px;
                    }
                }
            }

            &.right {
                .color-picker-arrow-border {
                    left: unset;
                    right: 12px;
                }
            }
        }
    }

    .color-picker-arrow-border {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #bbb;

        position: relative;
        left: 16px;

        .color-picker-arrow {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid white;
            position: absolute;
            width: 0;
            height: 0;
            top: 2px;
            left: -5px;
        }
    }

    .preset-colors {
        padding: var(--preset-colors-padding);
        background: white;
        border: 1px solid #BBB;
        @include boxShadow($color-3: rgba(69, 90, 100, 0.5));

        display: grid;
        grid-gap: 9px;

        --preset-colors-length: 6;

        &.width-1 {
            --preset-colors-length: 1;
        }
        &.width-2 {
            --preset-colors-length: 2;
        }
        &.width-3 {
            --preset-colors-length: 3;
        }
        &.width-4 {
            --preset-colors-length: 4;
        }
        &.width-5 {
            --preset-colors-length: 5;
        }

        grid-template-columns: repeat(var(--preset-colors-length), auto);

        .preset-color-container {
            height: var(--color-picker-height);
            cursor: pointer;
        }

        &.circle {
            border-radius: 1rem;

            .preset-color-container {
                width: 1rem;
                border-radius: 50%;
                @include boxShadow($color-3: rgba(69, 90, 100, 0.5));
            }
        }

        &.grid {
            .preset-color-group {
                display: grid;
                grid-template-rows: repeat(6, auto);
                width: var(--color-picker-width);
                grid-gap: 1px;
            }

            .preset-color-container {
                /*--check-color: #F3F3F3;
                background-image:
                    linear-gradient(45deg, var(--check-color) 25%, transparent 25%),
                    linear-gradient(-45deg, var(--check-color) 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, var(--check-color) 75%),
                    linear-gradient(-45deg, transparent 75%, var(--check-color) 75%);
                background-size: 10px 10px;
                background-position: 0 0, 0 5px, 5px -5px, -5px 0px;*/

                .preset-color {
                    border: 1px solid rgba(0,0,0,0.1);
                    display: block;
                    width: var(--color-picker-width);
                    height: var(--color-picker-height);

                    &:hover, &.selected {
                        // border-width: 2px;
                        width: calc(var(--color-picker-width) + 2px);
                        height: calc(var(--color-picker-height) + 2px);
                        position: relative;
                        top: -1px;
                        left: -1px;

                        svg {
                            position: absolute;
                            top: 4px;
                            left: 10px;
                            path {
                                stroke: rgba(0,0,0,0.5);
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    label {
        text-transform: uppercase;
        user-select: none;
    }
}