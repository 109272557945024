@import "../../../styles/breakpoints";
@import "../../../styles/common";

.email-templates {
  margin: 0;
  padding: 1rem;
  background: #f2f2f2;
  min-height: calc(100vh - 104px);

  @include from($screen-desktop) {
    padding: 2rem;
    @include contentOverflow($height: $content-height-after-header-and-subheader);
  }

  .email-templates-header {
    background-color: white;
    border-bottom: 1px solid $header-border-color;

    @include sticky($top: -2px, $z-index: 5);
  }

  .header-row, .email-template-row {
    display: grid;

    @include from($screen-desktop) {
      grid-template-columns: 30px 1fr 1.5fr 70px calc(40px + 1rem);
    }
  }

  .header-row {
    @include upTo($screen-desktop) {
      display: none;
    }
  }

  .header-column {
    padding: 0.5rem;

    font-size: 11px;
    color: #7d8b92;
    text-transform: uppercase;
    user-select: none;
  }

  .template_type_header {
    display: grid;
    grid-template-columns: 1fr calc(60px + 1rem);
    align-items: center;
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    font-weight: 400;
    background-color: $highlight-color;
    border-bottom: 1px solid $header-border-color;
    color: white;

    @include from($screen-desktop) {
      @include sticky($top: -32px, $z-index: 6);
    }

    .action {
      justify-self: end;
      font-size: 12px;
      text-transform: uppercase;

      .new-button {
        height: 20px;
        padding: 2px 4px;
        background-color: $default-foreground-color;
        border-radius: 10px;

        svg {
          transform: scale(0.8);
          position: relative;
          top: 1px;

          path {
            fill: $primary-color;
          }
        }

        &:hover {
          cursor: pointer;
          padding-left: 8px;
          transition: all 0.1s linear;

          &:before {
            content: "Add";
            color: $primary-color;
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.email-template-row {
  display: grid;
  grid-template-columns: auto;
  position: relative;
  outline: none;
  background-color: $default-foreground-color;
  margin-bottom: 1px;
  border-radius: 3px;
  align-items: start;

  font-size: 14px;

  @include upTo($screen-desktop) {
    grid-template-columns: 30px 1fr auto;
    grid-template-areas:
        "id subject actions"
        "content content content"
        "jobs-associated jobs-associated jobs-associated";
    align-items: center;
    grid-gap: 0.5rem 0;

    padding: 0.25rem 0;
  }

  @include from($screen-desktop) {
    padding: 0.25rem 0;
  }

  .template-id,
  .template-type,
  .template-subject,
  .template-content,
  .jobs-associated,
  .email-template-actions {
    padding: 0 0.5rem;
    line-height: 1.8rem;
  }

  .icon-star {
    transform: scale(0.6);
    path {
      fill: $primary-color;
    }

    @include upTo($screen-desktop) {
      position: absolute;
      right: 2.75rem;
      top: 0.3rem;
    }

    @include from($screen-desktop) {
      position: absolute;
      top: 2rem;
      left: 0.25rem;
    }
  }

  .template-id {
    font-weight: 400;

    @include upTo($screen-desktop) {
      grid-area: id;
    }
  }

  .template-type {
    font-weight: 400;

    @include upTo($screen-desktop) {
      grid-area: type;
    }
  }

  .template-subject {
    @include upTo($screen-desktop) {
      grid-area: subject;
      font-weight: 400;
    }
  }

  .template-content {
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: $default-background-color;

    @include upTo($screen-desktop) {
      grid-area: content;
    }

    textarea {
      border-color: transparent;
    }

    &.html {
      .ql-container {
        background-color: transparent;

        .ql-editor {
          padding: 0;
          overflow: hidden;
          max-height: 100%;
        }
      }
    }
  }

  .jobs-associated {
    @include upTo($screen-desktop) {
      grid-area: jobs-associated;
    }

    @include from($screen-desktop) {
      text-align: center;
    }

    .jobs-associated-label {
      @include from($screen-desktop) {
        display: none;
      }
    }

    .jobs-associated-value {
      &.has-value {
        color: $primary-color;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
          font-weight: 700;
        }
      }
    }
  }

  .email-template-actions {
    justify-self: end;
    @include upTo($screen-desktop) {
      grid-area: actions
    }

    .react-dropdown {
      .selections {
        width: 82px;
      }
    }
  }

  .jobs-associated-list {
    .job {
      display: block;
      padding: 0.2rem 0;
      border-bottom: 1px solid $header-border-color;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: var(--default-background-highlight);

        .job-id, .job-title {
          cursor: pointer;
          font-weight: 500;
        }
      }

      .job-id {
        display: inline-block;
        font-size: 1rem;
        width: 60px;
      }
    }
  }
}
