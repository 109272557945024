@import "../../../styles/common";

.recommendation {
    --disabled-color: rgba(55, 160, 160, 0.2);

    .recommendation-title {
        font-weight: 600;
        padding-bottom: 0.5rem;
    }

    .react-button {
        font-weight: 400;
        text-transform: capitalize;
        display: inline-block;

        &.outline {
            &.active, &.pending, &.warning {
                border-color: var(--disabled-color);
                background: $default-foreground-color;

                &:hover {
                    border-color: initial;
                }
            }

            &.readOnly {
                color: var(--disabled-color);
            }
        }

        ~ .pending {
            margin-left: 0.5rem;
        }
    }
}