@import "../../styles/common";

.live-interview {
    grid-template-rows: auto 1fr auto;

    height: 100%;
    overflow: hidden;

    .steps {
        padding: 0 1rem;

        display: grid;
        grid-template-columns: repeat(7, auto);
        justify-content: center;
        position: relative;
        top: 1px;

        .arrow {
            padding: 0.5rem;
        }

        .step-title {
            padding: 0.5rem 0;
            font-weight: 400;
            user-select: none;
            &.active {
                font-weight: 600;
                border-bottom: 2px solid $primary-color;
            }
            &.disabled {
                color: $grey-color;
            }
            &.completed {
                cursor: pointer;
                color: $primary-color;
                text-decoration: underline;
            }
            &.error {
                color: $warning-color;
            }
        }
    }

    .step {
        margin: 0;

        height: calc(100% - 42px - 60px);
        overflow-x: hidden;
        overflow-y: auto;
    }

    --live-interview-table-grid-gap: 0.5rem;

    .step-1 {
        display: grid;
        grid-template-columns: repeat(2, 50%);

        border-width: 1px 0;
        border-style: solid;
        border-color: #e6e6e6;

        .step-1-info {
            .candidate-name, .interview-link-candidate {
                margin-left: 3px;

                border-top: 0;
                padding: 1rem 1rem 0;
            }

            .interview-link-candidate {
                .short-link {
                    border-radius: 0;
                    border: 1px solid rgba(0,0,0,0.25);
                    margin-top: 0.25rem;
                    max-width: 100%;
                    display: block;
                    text-transform: none;

                    .short-link-content {
                        max-width: 100%;
                    }
                }
            }

            .join-interview-button {
                margin: 1rem 1rem 1rem 1.2rem;
            }
        }

        .step-1-details {
            display: grid;
            grid-gap: var(--live-interview-table-grid-gap);
            padding: 1rem 1rem 2rem;
            background-color: #f7f7f7;
            position: relative;
            grid-template-rows: auto auto 1fr;

            .step-1-details-header {
                font-weight: 600;

            }

            .interview-date-time {
                display: grid;
                grid-template-columns: 2fr auto 1fr;
                grid-gap: var(--live-interview-table-grid-gap);

                .interview-date {
                    #interview_date-helper-text {
                        display: none;
                    }
                }

                .at {
                    align-self: center;
                    user-select: none;
                }

                .interview-time {
                    height: 2.6rem;
                }
            }

            .date-time-error {
                font-size: 13px;
                color: $warning-color;
                /*position: absolute;

                bottom: 0.75rem;
                padding-left: 1rem;*/
                padding-left: 0.15rem;
            }
        }
    }

    .step-2 {
        display: grid;
        grid-template-columns: repeat(2, 50%);

        border-width: 1px 0;
        border-style: solid;
        border-color: #e6e6e6;

        .evaluator-selection {
            grid-template-columns: auto;
            grid-template-rows: auto 1fr;
            padding: 1rem 1rem 0;
            align-items: start;

            .staff-dropdown {
                z-index: 10;
            }

            .add-button {
                align-self: start;
                margin: 0 0 5rem;
            }
        }

        .selected-interviewers {
            background-color: #f7f7f7;
            padding: 1rem;

            .staff-selection {
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .step-3 {
        display: grid;
        grid-gap: var(--live-interview-table-grid-gap);
        padding: 1rem;
        background-color: #f7f7f7;

        .email-preview {
            display: grid;
            grid-gap: var(--live-interview-table-grid-gap);
            padding: 1rem;
            margin-bottom: 1rem;
            @include boxShadow();

            .job-position, .interview-time, .interviewer {
                .label {
                    font-weight: 600;
                }
                .value {

                }
            }

            .customTextArea {
                textarea {
                    background-color: #fafafa;
                    resize: none;

                    min-height: 60px;
                    max-height: 100px;
                    overflow-x: hidden;
                    overflow-y: auto;
                }

                &.editable {
                    textarea {
                        background-color: white;
                    }
                }
            }

            .learn-more {
                color: $primary-color;
                text-decoration: underline;
            }

            .email-button {
                cursor: default;
                justify-self: start;
                border-radius: 0;
            }
        }
    }

    .step-4 {
        display: grid;
        grid-gap: var(--live-interview-table-grid-gap);
        padding: 1rem;
        background-color: #f7f7f7;

        .customTextArea {
            textarea {
                background-color: white;
                resize: none;

                min-height: 60px;
                max-height: 100px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
    }

    .step-buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 1rem 1rem 0;

        .button-left {
            display: grid;
            align-items: center;
            justify-self: start;

            .previous-button {
                font-weight: 400;
                color: $primary-color;
                // border-color: $pr
                cursor: pointer;
                user-select: none;
            }
        }


        .button-right {
            justify-self: end;
        }
    }
}