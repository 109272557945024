@import "../../styles/breakpoints";
@import "../../styles/common";

$primary-color: #00A1DF;

.candidate-profile-admin {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 0.5rem;

    .candidate-labels {
        .label {
            font-size: 12px;
            color: grey;
            margin-left: 4px;
        }

        .value {
            background: $primary-color;
            border-radius: 20px;
            padding: 2px 8px;
            color: white;
            display: inline-block;
            margin-right: 3px;
            margin-bottom: 1px;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .interview-url {
        .short-link {
            max-width: unset;
            border-color: $primary-color;
            background-color: $default-background-color;
            padding: 2px 4px;
            width: calc(100%);
            height: 2rem;
            position: relative;

            .short-link-content {
                max-width: unset;
                width: calc(100% - 8px);
                padding: 0;
                white-space: nowrap;
                overflow: auto;
                background-color: transparent;
                position: absolute;
                border-radius: 0;
                font-size: 14px;
            }

            .icon-copy {
                padding: 7px 3px 6px 4px;
            }

            .outcome {
                line-height: 30px;
            }
        }
    }

    .form-container {
        margin-left: 4px;
        display: grid;
        grid-gap: 0.5rem;

        .questions {
            .headers {
                display: grid;
                grid-template-columns: 1fr 50px 2.5rem;
                grid-template-rows: auto;
                grid-gap: 1rem;

                .header {
                    font-size: 12px;
                    color: grey;
                    user-select: none;
                }
            }

            .question-with-response-and-selection {
                display: grid;
                grid-template-columns: 1fr 50px 2.5rem;
                grid-template-rows: auto;
                grid-gap: 1rem;
                border: 1px solid rgba(0, 0, 0, 0.1);
                margin-bottom: 0px;
                border-radius: 3px;
                @include boxShadow();

                &:last-of-type {
                    border-bottom: 0;
                }

                .question-item {
                    border: 0;
                    box-shadow: none;

                    .question-thumbnail {
                        z-index: 5;
                    }
                }

                .response {
                    margin: 0.5rem 0;
                }

                .retake-checkbox {
                    position: inherit;
                    opacity: 1;
                    left: 8px;
                    text-align: center;
                    justify-self: end;
                    margin: 1rem 1rem 0 0;
                }
            }
        }

        .actions {
            text-align: right;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 1rem;

            .status-text {
                color: $primary-color;
                align-self: center;

                &.warning {
                    color: $warning-color;
                }
            }
        }
    }
}