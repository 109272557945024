@import "../../../../styles/breakpoints";
@import "../../../../styles/common";

.item-selection-header {
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: "title type add";
  padding: 0 3rem 0 1rem;
  border-bottom: 1px solid #d8d8d8;
  background-color: white;
  height: 49px;
  border-radius: 6px 6px 0 0;

  .desktop-only {
    display: none;
    @include from($screen-desktop) {
      display: inline-block;
    }
  }

  .item-selection-title {
    grid-area: title;
    font-weight: 400;
    font-size: 18px;
    line-height: 1rem;
    align-self: center;
  }

  .item-selection-type {
    grid-area: type;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    align-content: start;
    font-size: 14px;
    user-select: none;
    grid-gap: 1.5rem;

    position: relative;
    align-self: end;

    &.has-stock-library {
      grid-template-columns: repeat(3, auto);
    }

    .type-link {
      padding: 0 0 0.5rem;
      display: inline-block;
      cursor: pointer;
      text-align: center;
      width: 100%;

      &.active {
        color: var(--primary-color);
        font-weight: bold;
        border-bottom: 2px solid var(--active-underline-color);
      }
    }
  }

  .question-add {
    grid-area: add;
    align-self: center;
    justify-self: end;

    button {
      height: 2rem;
      line-height: 1rem;
    }
  }
}

.item-selection-library {
  grid-area: library;
  padding: 16px;
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .library-filter-container {
    @include from($screen-desktop) {
      // max modal height minus header height minus self top & bottom margin
      height: calc(90vh - 49px - 2rem);
      top: 0;
    }
  }

  .library-list {
    @include upTo($screen-desktop) {
      overflow: auto;
    }
    @include from($screen-desktop) {
      padding-bottom: 1rem;
    }
  }
}

.criteria {
  .item-selection-header .item-selection-type .type-link.active {
    color: black;
  }
}
