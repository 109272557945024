@import "../../../styles/breakpoints";
@import "../../../styles/common";

.candidate {
    --full-width: 100%;
    --button-width: 61px;
    --gap-width: 0.5rem;
    --width-rest: calc(var(--full-width) - var(--button-width) - var(--gap-width));
    --column-width: calc((var(--width-rest) - (4 * var(--gap-width)) )/ 17);

    grid-template-rows: auto;
    grid-template-columns:
            calc(var(--column-width) * 3)
            calc(var(--column-width) * 3)
            calc(var(--column-width) * 4)
            calc(var(--column-width) * 4)
            calc(var(--column-width) * 3)
            var(--button-width);

    display: grid;
    grid-template-areas: "first last email phone deadline remove";
    grid-gap: 0.5rem;
    position: relative;

    border-bottom: 1px solid #eee;

    &.has-candidate-deadline-timezone-true {
        --column-width: calc((var(--width-rest) - (5 * var(--gap-width)) )/ 17);

        grid-template-rows: auto;
        grid-template-columns:
            calc(var(--column-width) * 2)
            calc(var(--column-width) * 2)
            calc(var(--column-width) * 4)
            calc(var(--column-width) * 2)
            calc(var(--column-width) * 2)
            calc(var(--column-width) * 5)
            var(--button-width);

        grid-template-areas: "first last email phone deadline deadline-timezone remove";
    }


    &:last-child {
        border-bottom: 0;
    }

    &.new-candidate {
        background-color: var(--default-background-highlight);
    }

    .first-name, .last-name, .email, .phone, .interview-closing-time {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        .read-only {
            display: grid;
            height: 100%;
            align-items: center;
        }
    }

    .first-name {
        grid-area: first;
    }

    .last-name {
        grid-area: last;
    }

    .email {
        grid-area: email;
    }

    .phone {
        grid-area: phone;
        overflow: inherit;
    }

    .interview-closing-time {
        grid-area: deadline;
    }

    .deadline-timezone {
        .selection-label {
            height: 0;
        }

        .selection-value {
            height: 47px;

            &:hover {
                background-color: transparent;
            }
        }
    }

    .remove {
        grid-area: remove;
        cursor: pointer;
        align-self: center;
        justify-self: right;
        padding-right: 0.5rem;
    }
}