@import "../../../../styles/breakpoints";
@import "../../../../styles/common";

.interview-template-selection {
  > .overlay-form {
    width: 80%;
  }

  .interview-templates {
    min-height: auto;
    height: auto;
    padding: 0;

    .header-row, .interview-template-row {
      @include from($screen-desktop) {
        grid-template-columns: 2fr repeat(3, 1.5fr) repeat(2, 1fr) calc(70px + 1rem);
      }

      .interview-template-actions {
        .react-button {
          padding: 0.5rem 0.75rem;
          height: 2.2rem;
          line-height: 1rem;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
  }
}