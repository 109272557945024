@import "../../styles/common";

.item-notice {
    border: 1px solid grey;
    padding: 0.5rem;
    margin-top: 0;
    font-size: 0.8rem;
    background-color: var(--default-background-highlight);

    strong {
        color: red;
    }
}

.library-item {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        "item-details item-file"
        "item-actions item-actions";
    grid-gap: 1rem;

    &.item-text {
        grid-template-columns: auto;
        grid-template-rows: 1fr auto;
        grid-template-areas:
        "item-details"
        "item-actions";

        .item-files {
            display: none;
        }
    }

    &.response-video {
        .response-template {
            display: none;
        }
    }

    &.message {
        .item-details {
            .html-content {
                margin-top: 1rem;
                .quill {
                    min-height: 140px;
                    .ql-container {
                        .ql-editor {
                            min-height: 140px;
                        }
                    }
                }
            }
        }
    }

    input[type="file"] {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        border-radius: 51%;
        cursor: pointer;
    }

    input::-webkit-file-upload-button {
        cursor: pointer;
    }

    .item-details {
        grid-area: item-details;
        max-width: 389px;
        z-index: 1;

        .display-text {
            grid-area: display-text;
            textarea {
                min-height: 4rem;
                max-height: 4rem;
                resize: none;
            }
        }

        .page-title, .display-text {
            .input-field {
                input {
                    font-size: 14px;
                    padding-left: 0;
                }

                label {
                    left: 0 !important;
                }
            }
        }

        .additional-information-toggle,
        .attachment-toggle,
        .answer-selection-toggle,
        .exclude-from-scoring-toggle,
        .evaluation-guide-toggle {
            label {
                margin: 0;
                font-weight: 400;
            }
        }

        .additional-information-toggle {
            grid-area: additional-information;
            + .html-content {
                margin-top: 0;
            }
        }

        .html-content {
            margin-top: 0.5rem;
            position: relative;
            z-index: 100;
        }

        .attachments {
            margin-left: 1.6rem;

            .attachment-list {
                margin-bottom: 0.25rem;

                .attachment-item {
                    width: 100%;
                    display: grid;
                    grid-template-columns: auto 1fr auto;
                    grid-gap: 0.5rem;
                    align-items: center;
                    padding: 0.25rem;
                    background-color: var(--default-background-highlight);
                    margin-bottom: 0.2rem;

                    .short-link {
                        .short-link-content {
                            background-color: $default-background-color;
                        }
                    }

                    .attachment-name {
                        font-size: 0.9rem;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .attachment-remove {
                        &:hover {
                            cursor: pointer;
                        }
                        svg {
                            transform: scale(0.8);
                        }
                    }
                }
            }

            .attachment-description {
                font-size: 0.8rem;
                margin-bottom: 0.25rem;
            }

            .attachment-upload {
                position: relative;
                width: 66px;

                .upload-button {
                    height: 2rem;
                    line-height: 1rem;
                    padding: 0.5rem;
                    font-size: 0.9rem;
                }
            }
        }

        .exclude-from-scoring-toggle-description,
        .evaluation-guide-toggle-description {
            font-size: .8rem;
            position: relative;
            top: -.25rem;
            left: 1.7rem;

            &.off {
                color: $disabled-color;
            }
        }

        .evaluation-guide-container {
            &.off {
                display: none;
            }
        }

        .evaluation_guide {
            textarea {
                resize: none;
            }
        }

        .item-details-more {
            width: 100%;

            display: grid;
            grid-template-columns: 3fr 1.1fr;
            grid-template-rows: repeat(3, auto);
            grid-template-areas:
                "category prep-time"
                "response-type answer-time"
                "response-details response-details";
            grid-gap: 0.5rem 1rem;

            .react-dropdown {
                --floating-padding: 10px;
                margin-top: 0.5rem;
            }

            .preparation-time, .answer-time {
                .react-tooltip {
                    position: relative;
                    top: -1px;
                    background-color: grey;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    z-index: 100 !important;

                    .label {
                        position: relative;
                        top: 1px;
                        color: white;
                        display: block;
                        text-align: center;
                        font-weight: 400;
                    }

                    .description {
                        top: -3rem;
                        left: 1.25rem;
                        height: 5.5rem;
                        font-size: 12px;
                        line-height: 1rem;
                    }
                }
            }

            .response-section {
                .response-type {
                    --floating-padding: 10px;
                    margin-top: 0.5rem;

                    .firstLine {
                        text-transform: capitalize;
                    }
                }

                .response-template {
                    textarea {
                        outline: none;

                        min-height: 4rem;
                        max-height: 4rem;
                        resize: none;
                    }
                }
            }

            .answer-time {
                align-self: start;
            }

            .response-details {
                grid-area: response-details;
                margin-top: -0.5rem;

                .answer-selection-title {
                    padding-top: 0.5rem;
                    font-size: 0.8rem;
                    color: #7d8b92;
                }

                .answer-selection {
                    margin: 0.5rem 0;
                }
            }

            .question-tags {
                display: none;

                .tag-list {
                    margin-top: 0.25rem;
                }
            }
        }
    }

    .item-files {
        grid-area: item-file;

        border: 2px dotted var(--primary-color);
        background-color: var(--default-background-highlight);
        padding: 0.5rem;
        border-radius: 3px;

        display: grid;
        grid-template-rows: auto 1fr;
        align-items: center;
        justify-items: center;

        .item-files-title {
            text-transform: capitalize;
            color: var(--primary-color);
        }

        .item-file-selection {
            position: relative;
            display: grid;
            grid-gap: 0.5rem;
            align-items: center;

            grid-template-rows: auto;
            grid-template-columns: auto;
            justify-items: center;

            .item-file-upload, .item-file-record {
                position: relative;
                .upload-button, .record-button {
                    height: var(--button-height);
                    line-height: var(--button-line-height);
                    background-color: white;
                }

                .record-button {
                    padding: 0.5rem;

                    .record-icon {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $warning-color;

                        display: inline-block;
                        position: relative;
                        top: 5px;
                        margin-right: 0.5rem;
                    }

                    &.disabled {
                        .record-icon {
                            background-color: $disabled-color;
                        }
                    }
                }
            }

            .or {
                &.disabled {
                    color: $disabled-color;
                }
            }
        }

        .error-message {
            color: $warning-color;
        }

        .item-list {
            margin-top: 0.5rem;

            .item-file {
                .question-thumbnail {
                    display: inline-block;
                }
            }
        }
    }

    .item-actions {
        grid-area: item-actions;
        display: grid;
        grid-template-columns: auto 1fr;

        .submit {
            margin-right: 0;
            height: 2.4rem;
            line-height: 1rem;
            width: 6rem;
        }

        .cancel {
            align-self: center;
            justify-self: start;
        }
    }

    .record-video-modal {
        .overlay-form {
            border: 0;

            .container-form {
                padding: 0;
                margin-bottom: -7px;
            }
        }
    }

    &.isLive {
        grid-template-columns: auto;
        grid-template-areas:
            "item-details"
            "item-actions";

        .item-details {
            max-width: unset;

            .item-details-more {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                grid-template-areas: "category";

                .preparation-time, .answer-time, .response-section, .response-details {
                    display: none;
                }
            }
        }
    }
}
