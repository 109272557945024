@import "../../../styles/common";

.custom-list {
  .custom-list-add {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0.5rem;

    .custom-list-item {
      .input-field {
        input {
          padding: 0;
          height: 2rem;
        }
      }
    }

    .custom-list-item-add-button {
      padding: 0.2rem 0.5rem;
      height: 2rem;
    }
  }

  .custom-list-items {
    .custom-list-item-line {
      position: relative;

      .row-index {
        position: absolute;
        left: 0;
        top: 1rem;
        z-index: 10;
      }

      .custom-list-item {
        .input-field {
          input {
            padding-left: 1rem;
          }
        }
      }

      .custom-list-item-delete {
        position: absolute;
        right: 0;
        top: 1rem;
      }
    }
  }
}