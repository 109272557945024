.reset-password {
    --grey-color: #7d8b92;
    --weak-color: #F25F5C;
    --fair-color: #000000;
    --good-color: #247BA0;
    --strong-color: #009900;

    width: 100%;

    .reset-password-error-message {
        color: rgba(255,0,0,0.75);
        padding: 20px 0;
        text-align: center;

        a {
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }

    .reset-password-message {
        margin-top: 0;
        text-align: center;
    }

    .customTextField {
        &.new-password, &.confirm-password {
            margin-bottom: 2rem;
            --default-border-color: var(--grey-color);
            --active-border-color: #eee;

            input {
                &.matching {
                    border-bottom: 1px solid var(--strong-color) !important;
                    box-shadow: 0 1px 0 0 var(--strong-color) !important;
                }
                &:not(:focus) {
                    &.non-matching {
                        border-bottom: 1px solid var(--weak-color) !important;
                        box-shadow: 0 1px 0 0 var(--weak-color) !important;
                    }
                }
            }
        }
    }

    .confirm-password-validation {
        font-size: 12px;
        text-align: left;
        margin-top: -32px;
        margin-bottom: 14px;

        .matching {
            color: var(--strong-color);
        }
        .non-matching {
            color: var(--weak-color);
        }
    }

    .reset-password-confirm {
        padding: 2rem 0;
        display: grid;
        justify-items: center;
        grid-gap: 0.5rem;
    }

    .password-strength-meter {
        margin-top: -34px;
        display: block;
        // padding-bottom: 30px;
        z-index: 10;
        position: relative;
        height: 36px;

        .password-strength-meter-progress {
            vertical-align: top;
        }

        .password-strength-meter-label {
            margin-top: -16px;
        }
    }

    .reset-password-button {
        width: 100%;
    }

    .reset-password-link {
        margin: 20px 0;
        display: block;
        font-size: 14px;
        text-align: center;

        a {
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }

    .status-message {
        padding-top: 20px;
        text-align: center;
    }
}