.attributes-scoring {
    .attributes-scoring-title {
        font-weight: 600;
        padding-bottom: 0.5rem;
    }
    .attributes-scoring-section {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-template-areas: "table";

        .attributes-table {
            grid-area: table;

            .attributes-row {
                display: grid;
                grid-template-rows: repeat(2, auto);
                grid-template-columns: auto;
                grid-gap: 0.5rem;
                margin-bottom: 0.5rem;

                .header {
                    font-size: 12px;
                    color: #7d8b92;
                    text-transform: uppercase;
                    line-height: 22px;
                }
            }
        }
    }
}