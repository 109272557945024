@import "../../styles/breakpoints";
@import "../../styles/common";


.interview-template-edit-button {
  .react-button {
    min-width: 10rem;

    .loader {
      left: 0;
      top: -6px;

      div {
        background-color: white;
      }
    }
  }
}

.interview-template {
  display: grid;
  grid-gap: 1rem;

  position: relative;

  &.is-in-job-context {
    padding: .5rem;
    background-color: $secondary-background-color;
    transition: background-color 0.2s linear;
    border-radius: 0.25rem;

    @include from($screen-desktop) {
      padding: 1rem;
    }
  }

  &.using-interview-template {
    background: var(--selected-color);
  }

  .job-context {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: .5em;

    .terminology {
      display: grid;
      grid-template-rows: repeat(2, auto);

      label {
        font-size: 15px;
        font-weight: 600;
        color: initial;
      }

      .value {
        font-size: 14px;
        text-transform: capitalize;
      }
    }

    .interview-template-name {
      color: $grey-color;
      text-align: right;
      font-size: 14px;

      strong {
        font-weight: 600;
      }
    }
  }

  .interview-template-section-header {
    font-weight: 600;
    padding: 0 0 0.5rem;
    font-size: 15px;
  }

  .pre-interview-section {
    .pre-interview-questions {
      background: white;
    }
  }

  .add-item-container {
    display: grid;
    align-content: center;
    justify-content: center;

    .add-item-message {
      width: 300px;
      height: 300px;
      max-width: calc(100vw - 32px);
      max-height: calc(100vw - 32px);
      background: white;
      border-radius: 100%;
      vertical-align: middle;
      display: grid;
      align-content: center;
      justify-content: center;
      font-size: 1.4rem;
      padding: 4rem;
      text-align: center;
      line-height: 2rem;
      user-select: none;
      cursor: pointer;

      &.disabled {
        cursor: default;
      }
    }
  }

  .interview-section {
    position: relative;

    .add-item-container {
      grid-area: add-item-container;
    }

    .interview-questions {
      background: white;

      display: grid;
      grid-template-rows: 1fr;
      grid-gap: 1rem;
    }
  }
}

.question-selection {
  > .overlay-form {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    max-width: 920px;
    padding: 0;
    background-color: $secondary-background-color;

    .close-icon {
      z-index: 5;
    }

    > .container-form {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto 1fr;
      grid-template-areas: "header" "library";
      overflow: unset;
      padding: 0;

      .item-selection-header {
        .question-add {
          position: relative;
          right: 2rem;
        }
      }
    }
  }
}