@import "../../styles/common";

@mixin linkBox {
    background: rgba(0, 0, 0, 0.25);
    color: white;
    user-select: none;
    padding: 0.25rem 0.5rem;

    &:hover, &:focus {
        background-color: rgba(0,0,0,0.5);
        cursor: pointer;
    }
}

.record-video {
    .teleprompter {
        padding: 0.5rem;
        display: grid;
        align-items: center;

        max-height: 7.25rem;
        @include contentOverflow(auto);

        .display_text {
            max-width: 600px;
            font-weight: 400;
            overflow-wrap: break-word;
        }

        .html-content {
            max-width: 600px;
            padding-bottom: 0.5rem;
            .ql-container {
                border-width: 0 !important;
            }
            .ql-editor {
                min-height: 0;
                max-height: 100%;
            }
        }
    }

    .video-recording-container {
        position: relative;

        .camera-preview {
            width: 100%;
            transform: scaleX(-1);
        }

        .cancel {
            position: absolute;
            top: 1rem;
            left: 1rem;

            @include linkBox();
        }

        .record-button {
            --button-size: 70px;

            width: var(--button-size);
            height: var(--button-size);
            border: calc(var(--button-size) / 10) solid #fff;
            border-radius: 50%;
            cursor: pointer;

            position: absolute;
            top: calc(100% - var(--button-size) - 20px);
            left: calc(50% - calc(var(--button-size) / 2));

            .record-button-inner {
                background-color: #9e1919;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                transition: all 0.2s ease;
                transform: scale(0.94);
            }

            &.disabled {
                border-color: $disabled-color;

                .record-button-inner {
                    background-color: $disabled-color;
                }
            }

            &.recording {
                .record-button-inner {
                    transform: scale(0.5);
                    border-radius: 12%;
                }
            }
        }

        .timer {
            position: absolute;
            top: calc(100% - 4rem);
            right: 2rem;

            color: white;
            display: block;
            padding: 8px 16px;
            background: rgba(0,0,0,0.25);
            user-select: none;
            width: 80px;
            text-align: center;
        }
    }

    .recorded-video-preview-container {
        font-size: initial;
        position: relative;

        .recorded-preview {
            width: 100%;
        }

        .retake-button {
            position: absolute;
            top: 1rem;
            left: 1rem;

            @include linkBox();
        }

        .cancel-button {
            position: absolute;
            top: 1rem;
            left: 6.25rem;

            user-select: none;

            color: white;

            .cancel-link {
                @include linkBox();
                display: inline-block;
            }
        }

        .upload-button {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
    }
}