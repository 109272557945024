@import "../../../styles/breakpoints";
@import "../../../styles/common";

.step-evaluators {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 1.5rem;

    .staff-selection {
        @include from($screen-mobile-l-min) {
            width: 300px;
        }
    }

    .candidate-contact {
        .candidate-contact-header {
            font-weight: 700;
        }

        .candidate-contact-note {
            font-size: 14px;
            padding-bottom: 0.5rem;
        }

        .candidate-contact-name {
            width: 300px;
            padding-bottom: 0.5rem;
        }

        .candidate-contact-email {
            width: 300px;
            padding-bottom: 0.5rem;
        }
    }

    .job-evaluators {
        .evaluator-dropdown {
            background: white;

            .selections {
                max-height: 250px;
            }
        }
    }

    .evaluator-list-container {
        background: white;
    }
}