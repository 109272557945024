$primary-color: #00A1DF;
$active-color: #59c427;;
$active-color-dark: green;
$pending-color: orange;

$warning-color: #F44336;
$grey-color: #7d8b92;
$grey-color-light: #AAA;
$disabled-color: #dfdfdf; // rgba(80, 204, 75, 0.2);
$highlight-color: #4CBFDF;

$default-background-color: #f9fafa;
$default-foreground-color: #ffffff;

$warning-background-color: rgba(244, 67, 54, 0.2);

$secondary-background-color: #f2f2f2;

$header-height: 60px;
$sub-header-height: 44px;
$content-height-after-header: calc(100vh - #{$header-height});
$content-height-after-subheader: calc(100vh - #{$sub-header-height});
$content-height-after-header-and-subheader: calc(100vh - #{$header-height} - #{$sub-header-height});

$header-border-color: rgba(0, 0, 0, 0.07);

html {
    --font-face: "jaf-bernino-sans", sans-serif;
    --primary-color: #00A1DF;
    --scrollbar-color: #00A1DF;
    --scrollbar-highlight-color: #4CBFDF;
    --selected-color: rgba(0, 163, 209, 0.16);
    --hover-color: rgba(0, 163, 209, 0.08);
    --disabled-color: #dfdfdf;
    --active-color: #59c427;
    --active-color-bright: #5dcc29;
    --active-disabled-color: rgba(89, 196, 39, 0.45);
    --active-color-semi: rgba(89, 196, 39, 0.5);
    --active-border-color: #00a3d1;
    --active-underline-color: #00A1DF;
    --label-color: #00a3d1;
    --icon-color: #00A3D1;
    --field-label-size: 0.8rem;
    --field-label-color: #7d8b92;
    --field-label-weight: 300px;
    --field-label-line-height: 1.3125rem;
    --input-font-color: #000;

    --default-background-highlight: rgba(0, 163, 209, 0.08);
    --default-background-selected: rgba(0, 163, 209, 0.1);
    --default-background-highlighted: rgba(0, 163, 209, 0.2);

    --button-height: 2.4rem;
    --button-line-height: 1.4rem;
}

.criteria {
    --font-face: "Lato", sans-serif;
    --primary-color: #425CC7;
    --scrollbar-color: #DDDDDE;
    --scrollbar-highlight-color: #999990;
    --selected-color: rgba(66, 92, 199, 0.16);
    --hover-color: rgba(66, 92, 199, 0.08);
    --disabled-color: #C6CEEE;
    --active-color: #425CC7; // sapphire-100
    --active-color-bright: #546CCC; // sapphire-90
    --active-disabled-color: rgba(66, 92, 199, 0.35);
    --active-color-semi: rgba(66, 92, 199, 0.4);
    --active-border-color: #425CC7;
    --active-underline-color: #2DCCD3;
    --label-color: #425CC7;
    --icon-color: #53565A;
    --field-label-size: 0.875rem;
    --field-label-color: #53565A;
    --field-label-weight: 700;
    --field-label-line-height: 1.25rem;
    --input-font-color: #86888B;

    --default-background-highlight: rgba(66, 92, 199, 0.08);
    --default-background-selected: rgba(66, 92, 199, 0.1);
    --default-background-highlighted: rgba(66, 92, 199, 0.2);

    --button-height: auto;
    --button-line-height: auto;
}

@mixin boxShadow(
    $color-1: rgba(69, 90, 100, 0.05),
    $color-2: rgba(69, 90, 100, 0.2),
    $color-3: rgba(69, 90, 100, 0.1),
    $width-1: 1px,
    $width-2: 5px
) {
    box-shadow:
        0 $width-1 0 0 $color-1,
        0 $width-1 $width-1 0 $color-2,
        0 $width-1 $width-2 0 $color-3;
}

@mixin default-scroll-bar() {
    // http://webkit-scroll-gen.sourceforge.net/
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: var(--scrollbar-color);
        border: 0 none #ffffff;
        border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-highlight-color);
    }
    ::-webkit-scrollbar-thumb:active {
        background: var(--scrollbar-highlight-color);
    }
    ::-webkit-scrollbar-track {
        background: #EEEEEE;
        border: 0 none #ffffff;
        border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
        background: #EEEEEE;
    }
    ::-webkit-scrollbar-track:active {
        background: #EEEEEE;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
}

@mixin no-scroll-bar() {
    &::-webkit-scrollbar {
        height: 0;
        width: 0;
    }

    &::-webkit-scrollbar-thumb {
        height: 0;
        width: 0;
    }

    &::-webkit-scrollbar-track {
        height: 0;
        width: 0;
    }
}

@mixin show-scroll-bar() {
    &::-webkit-scrollbar {
        height: initial;
        width: initial;
    }

    &::-webkit-scrollbar-thumb {
        height: initial;
        width: initial;
    }

    &::-webkit-scrollbar-track {
        height: initial;
        width: initial;
    }
}

@mixin contentOverflow($height: $content-height-after-header) {
    height: $height;
    overflow-x: hidden;
    overflow-y: auto;
}

@mixin sticky($top: 0px, $z-index: 10) {
    position: sticky;
    top: $top;
    z-index: $z-index;
}

// https://codepen.io/laustdeleuran/pen/DBaAu
@mixin scrolling-shadows($background-color: rgb(255, 255, 255), $shadow-intensity: 0.4, $shadow-color: rgb(0,0,0), $cover-size: 40px, $shadow-size: 14px) {

    background:
            // Shadow covers
            linear-gradient($background-color 30%, rgba($background-color,0)),
            linear-gradient(rgba($background-color,0), $background-color 70%) 0 100%,

            // Shadows
            radial-gradient(farthest-side at 50% 0, rgba($shadow-color,$shadow-intensity), rgba($shadow-color,0)),
            radial-gradient(farthest-side at 50% 100%, rgba($shadow-color,$shadow-intensity), rgba($shadow-color,0)) 0 100%;

    background-repeat: no-repeat;
    background-color: $background-color;
    background-size: 100% $cover-size, 100% $cover-size, 100% $shadow-size, 100% $shadow-size;

    // Opera doesn't support this in the shorthand
    background-attachment: local, local, scroll, scroll;
}

@mixin chequered-background($color-1: #EEE, $color-2: transparent) {
    background-image:
        linear-gradient(45deg, $color-1 25%, $color-2 25%),
        linear-gradient(-45deg, $color-1 25%, $color-2 25%),
        linear-gradient(45deg, $color-2 75%, $color-1 75%),
        linear-gradient(-45deg, $color-2 75%, $color-1 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

@mixin default-placeholder($color: grey, $font-weight: 200) {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color;
        font-weight: $font-weight;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $color;
        font-weight: $font-weight;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $color;
        font-weight: $font-weight;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: $color;
        font-weight: $font-weight;
    }
    ::placeholder {
        color: $color;
        font-weight: $font-weight;
    }
}

@mixin placeholder-styling($color: grey, $font-size: 1rem, $font-weight: 200, $font-style: italic) {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color;
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: $color;
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: $color;
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: $color;
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
    }
    &::placeholder {
        color: $color;
        font-size: $font-size;
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

@mixin firefox-only {
    @at-root {
        @-moz-document url-prefix() {
            & {
                @content;
            }
        }
    }
}

@keyframes highlight {
    0% { background-color: transparent; }
    5% { background-color: var(--default-background-highlighted); }
    75% { background-color: var(--default-background-highlighted); }
    100% { background-color: transparent; }
}
