$weak-color: #F25F5C;
// $fair-color: #000000; // #FFE066;
// $good-color: #247BA0;
$strong-color: #009900;

.password-strength-meter {
    text-align: left;

    &:after {
        content: ".";
        color: rgba(0,0,0,0);
    }
}

.password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    border: 0;
}

.password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
}

.password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-moz-progress-bar {
    background-color: $weak-color;
}
/*.strength-Fair::-moz-progress-bar {
    background-color: $fair-color;
}*/
.strength-Good::-moz-progress-bar {
    background-color: $strong-color;
}
.strength-Strong::-moz-progress-bar {
    background-color: $strong-color;
}

.strength-Weak::-webkit-progress-value {
    background-color: $weak-color;
}
/*.strength-Fair::-webkit-progress-value {
    background-color: $fair-color;
}*/
.strength-Good::-webkit-progress-value {
    background-color: $strong-color;
}
.strength-Strong::-webkit-progress-value {
    background-color: $strong-color;
}

.password-strength-meter-label {
    display: block;
    font-size: 12px;

    &.Weak {
        .value {
            color: $weak-color;
        }
    }
    /*&.Fair {
        .value {
            color: $fair-color;
        }
    }*/
    &.Good {
        .value {
            color: $strong-color;
        }
    }
    &.Strong {
        .value {
            color: $strong-color;
        }
    }

    .feedback {
        /*top: 0;
        padding-top: 27px;
        background: linear-gradient(180deg, transparent 0%, transparent 24px, grey 25px, grey 100%);
        font-size: 12px;*/
        display: inline;
        margin-left: 4px;

        .item {
            display: inline;

            .link {
                width: 16px;
                background: rgba(0,0,0,0.5);
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                color: white;
                font-weight: 400;
                height: 16px;
                line-height: 16px;
                user-select: none;
            }
        }

        .warning, .suggestion {
            display: block;
        }
    }
}