@import "../../../styles/common";

.customTextField {
    --default-border-color: #BABBBD;

    .input-field {
        margin: 0;
        position: relative;

        input {
            font-family: var(--font-face);
            font-size: 1rem;
            color: var(--input-font-color);
            margin: 0;
            background: white;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: var(--default-border-color);
            transition: border-bottom-width 0s linear;
            z-index: 10;
            box-sizing: border-box;

            &:disabled {
                opacity: 0.5;
            }

            &:not(:disabled) {
                &:hover {
                    padding-top: 17px;
                    border-bottom-width: 2px;
                }
                &:focus {
                    padding-top: 17px;
                    border-bottom-width: 2px;
                    border-bottom-color: var(--active-border-color);
                    box-shadow: none;
                }
            }
        }

        label {
            font-family: var(--font-face);
            font-size: 12px;
            font-weight: var(--field-label-weight);
            line-height: 1.25rem;
            top: 0;

            &.active {
                transform: unset;
            }
        }

        .helper-text {
            display: block;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            font-size: 12px;
            margin-top: -1px;
            margin-left: 3px;
        }
    }

    &.warning {
        > div {
            > input {
                color: $pending-color !important;
                border-bottom-color: $pending-color !important;
            }
            > label {
                color: $pending-color !important;
            }
            > .helper-text {
                color: $pending-color !important;
            }
        }
    }

    &.error {
        > div {
            > input {
                color: $warning-color !important;
                border-bottom-color: $warning-color !important;
            }
            > label {
                color: $warning-color !important;
            }
            > .helper-text {
                color: $warning-color !important;
            }
        }
    }

    &.edit-mode {
        .input-field {
            input {
                background: transparent;
                height: auto;
                padding: 0.75rem 0.25rem;
                font-size: 14px;
                border-bottom-width: 2px;
                border-bottom-style: solid;
                border-bottom-color: transparent;
                font-weight: 300;

                &:hover {
                    padding: 0.75rem 0.25rem;
                    border-bottom-color: var(--default-border-color);
                }

                &:focus {
                    padding: 0.75rem 0.25rem;
                    border-bottom-color: var(--active-border-color);
                }
            }
        }
    }
}

.criteria {
    .customTextField {
        .input-field {
            input {
                padding: 1rem 0 0 0.2rem;
                height: 3.5rem;
            }
        }
    }
}
