@import "../../styles/common";

.template-details {
  > .overlay-form {
    width: 800px;
    background: $default-background-color;

    > .container-form {
      display: grid;
      grid-gap: 1rem;
      padding-bottom: 0;
    }
  }

  .templates_type_dropdown {
    background-color: $default-foreground-color;
  }

  .customTextArea {
    .ql-container {
      .ql-editor {
        padding: 0.5rem;
      }
    }
    textarea {
      background-color: $default-foreground-color;
    }
  }

  .invalid-placeholders {
    padding-top: 0.25rem;
    color: $warning-color;
    .placeholder {
      display: inline-block;
      margin: 0 0.25rem 0.25rem 0;
      padding: 1px 4px;
      border-radius: 6px;
      background-color: $primary-color;
      color: white;
    }
  }

  .actions {
    position: relative;
    text-align: right;
    padding-bottom: 1rem;
  }
}