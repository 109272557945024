@import "../../../styles/breakpoints";
@import "../../../styles/common";

.candidate-details {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "avatar fullName actionItem";
    grid-gap: 0.5rem 1rem;
    padding-bottom: 1rem;
    align-items: center;

    @include from($screen-desktop) {
        grid-template-columns: auto repeat(3, 1fr);
        grid-template-areas: "avatar firstName lastName actionItem closeItem";
    }

    &.show-email {
        grid-template-columns: 88px 1fr 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
            "avatar fullName actionItem"
            "avatar email actionItem"
            "avatar phone actionItem";

        @include from($screen-desktop) {
            grid-template-columns: 88px repeat(3, 1fr);
            grid-template-areas:
                "avatar firstName email actionItem"
                "avatar lastName phone actionItem"
                "avatar cv cv actionItem";
        }
    }

    .avatar {
        grid-area: avatar;
        align-self: start;
        justify-self: center;
        margin: 0;
    }

    .label-value-tile {
        border-top: 0;
        padding: 0;

        .value {
            text-transform: none;
        }
    }

    .fullName {
        @include from($screen-desktop) {
            display: none;
        }
    }

    .firstName {
        grid-area: firstName;

        @include upTo($screen-desktop) {
            display: none;
        }
    }

    .lastName {
        grid-area: lastName;

        @include upTo($screen-desktop) {
            display: none;
        }
    }

    .email {
        grid-area: email;
    }

    .phone {
        grid-area: phone;
    }

    .cv {
        grid-area: cv;

        .cv-link {

        }
    }

    .actionItem {
        grid-area: actionItem;
        align-self: start;
        justify-self: end;

        &.btn {
            height: 2rem;
            line-height: 2rem;
            padding: 0 0.5rem;
        }

        .icon-share {
            position: relative;
            top: 1px;

            .icon-bg {
                fill: transparent;
            }

            .icon-highlight {
                fill: white;
            }
        }
    }

    .closeItem {
        grid-area: closeItem;
        align-self: start;
        justify-self: end;
    }
}
