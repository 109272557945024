@import "../../../styles/common";

.decision {
    --disabled-color: rgba(55, 160, 160, 0.2);

    .actions {
        height: 2.7rem;

        .react-button {
            font-weight: 400;
            text-transform: capitalize;
            position: relative;
            top: 0.5rem;

            &.outline {
                &.active, &.pending, &.warning {
                    border-color: var(--disabled-color);
                    background: $default-foreground-color;

                    &:hover {
                        border-color: initial;
                    }
                }

                &.readOnly {
                    color: var(--disabled-color);
                }
            }

            ~ .pending {
                margin-left: 0.5rem;
            }
        }
    }
}