@import "../../styles/breakpoints";
@import "../../styles/common";

.tenant {
  display: flex;
  overflow: hidden;
  height: calc(90vh - 59px - 1rem);

  .action-buttons {
    margin-bottom: 1rem;
    display: grid;

    .load-button {
      align-self: start;
      justify-self: start;
    }
  }

  .list {
    width: 25vw;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    .list-header {
      font-weight: 700;
      font-size: 1.2rem;
    }

    .tenant-new {
      width: 100%;
      padding: 0.5rem;
      color: $primary-color;

      &.selected-true {
        background-color: var(--default-background-highlight);
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: var(--default-background-highlight);
        text-decoration: underline;
      }
    }

    .list-items {
      overflow-x: hidden;
      overflow-y: auto;

      .tenant-item {
        display: grid;
        align-items: center;
        padding: 0.5rem;

        &.selected-true {
          background-color: var(--default-background-highlight);
          margin-right: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--default-background-highlight);
        }

        .display_name {
          line-height: 1rem;
          height: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
        }

        .tenant_id {
          font-size: 0.8rem;
          line-height: 0.8rem;
        }
      }
    }
  }

  .form-container {
    background-color: var(--default-background-highlight);
    border-radius: .25rem;
    overflow: scroll;

    .form {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, calc(50% - 1rem));
      grid-template-rows: auto 1fr auto;
      grid-template-areas:
      "section-one section-two"
      "section-one section-three"
      "blank submit-button";

      padding: 0.5rem;
      align-self: start;
    }

    .section {
      display: grid;
      grid-gap: .5rem;
      align-self: start;

      &.section-one {
        grid-area: section-one;

        .tenantId {
          border: 0;
          padding: 0 0 0.5rem;
          margin-left: 0.2rem;

          .label {
            text-transform: none;
            font-size: 0.8rem;
          }

          .value {
            text-transform: none;
          }
        }
      }

      &.section-two {
        grid-area: section-two;

        .state-postcode {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: auto;
          grid-gap: 1rem;
        }
      }

      &.section-three {
        grid-area: section-three;
      }

      .customTextField .input-field input,
      .typed-dropdown .selection-value.focused,
      .typed-dropdown .selection-value:hover,
      .typed-dropdown .selection-value .selection .clear:hover{
        background: transparent;
      }

      .logo-label {
        font-size: 0.8rem;
        color: $grey-color;
        padding-left: 0.2rem;
      }

      .logo-upload {
        display: grid;
        width: 60px;
        position: relative;

        .logo-upload-link {
          color: $primary-color;
          cursor: pointer;
          text-decoration: underline;
          padding-left: 0.2rem;
          display: inline;
        }

        input[type="file"] {
          position: absolute;
          width: 60px;
          opacity: 0;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .logo-remove {
        color: $warning-color;
        cursor: pointer;
        display: inline;

        &:hover {
          text-decoration: underline;
        }
      }

      .logo-preview {
        display: block;
        max-height: 100px;
      }

      .integrationType {
        font-size: 0.8rem;
        color: $grey-color;

        .value {
          display: grid;
          grid-gap: 0.5rem;
          font-size: 0.9rem;
          margin-top: 0.2rem;

          grid-template-areas:
            "selection-none selection-ats selection-criteria"
            "criteriaCompanyAccountId criteriaCompanyAccountId criteriaCompanyAccountId";

          div.criteriaCompanyAccountId {
            grid-area: criteriaCompanyAccountId;
            margin-top: -.75rem;
          }

          .selection-none {
            grid-area: selection-none;
          }

          .selection-ats {
            grid-area: selection-ats;
          }

          .selection-criteria {
            grid-area: selection-criteria;
            + div.criteriaCompanyAccountId {
              margin-top: 0;
            }
          }
        }
      }
    }


    .create-tenant-button {
      grid-area: submit-button;
      justify-self: start;
    }
  }

  .title {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .sub-title {
    font-weight: 700;
    color: $primary-color;
  }

  &+ .notice {
    grid-area: notice;
    font-size: 0.9rem;
    border: 1px solid $grey-color;
    padding: 0 1rem;
    margin-bottom: 1rem;

    .overlay-form {
      width: 80vw;
      max-height: 80vh;
      overflow: scroll;
    }

    .header {
      color: $warning-color;
      font-weight: bold;
    }
  }
}
