@import "../../../styles/breakpoints";
@import "../../../styles/common";

$primary-color: #00A1DF;
$error-color: #f44336;
$header-height: 60px;

.step-candidates {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-gap: 1rem;
    position: relative;

    .skip-cv-upload {
        position: absolute;
        top: 4.5rem;
        left: 1.5rem;
    }

    .candidates-list-container {
        background: white;
    }

    .candidates-list {
        @include boxShadow();
    }

    &.no-header {
        .candidates-list {
            @include from($screen-desktop) {
                max-height: calc(100vh - 349px + #{$header-height});

                @include from($screen-desktop-l-min) {
                    max-height: calc(100vh - 425px + #{$header-height});
                }
            }
        }
    }
}