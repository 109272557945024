@import "../../../styles/common";

.scoring-table {
    .scoring-table-section {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;
        grid-template-areas: "table";
        grid-gap: 1rem;

        &.has-overall-score {
            grid-template-columns: auto 1fr;
            grid-template-areas: "score table";
        }

        .overall-score {
            grid-area: score;
            padding-right: 1rem;
            border-right: 2px solid $grey-color;

            .score {
                font-weight: 600;
                font-size: 20px;
            }
        }

        .attributes-table {
            grid-area: table;
            display: grid;
            grid-gap: 0.15rem;

            .attributes-row {
                display: grid;
                grid-template-columns: 100px repeat(auto-fit, minmax(30px, 40px));
                margin-bottom: 0.5rem;

                &.header {
                    line-height: 1rem;
                }

                &.row-0 {
                    margin-bottom: 0;
                    .header {
                        line-height: 22px;
                    }
                }

                .attributes-item {
                    text-align: center;
                    font-weight: 400;

                    &.individual-score {
                        width: 2rem;
                    }

                    .attributes-score {
                        display: inline-block;
                        text-transform: uppercase;
                        font-size: 11px;
                        color: white;
                        font-weight: 600;
                        padding: 2px;
                        border-radius: 3px;
                        line-height: 1em;

                        &.yes {
                            background: $active-color;
                        }

                        &.maybe {
                            background: $pending-color;
                        }

                        &.no {
                            background: $warning-color;
                        }
                    }

                    .score-pending {
                        color: rgba(0,0,0,0.25);
                    }
                }
            }
        }
    }
}