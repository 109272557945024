@import "../../../styles/breakpoints";
@import "../../../styles/common";

$border-color: #eee;
$link-color: #00a3d1;

.step-review {
    display: grid;
    grid-gap: 1rem 0;

    .step {
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: auto 1fr;
        grid-template-areas:
            "step-header-text step-edit"
            "step-content step-content";
        grid-gap: 1rem 0;
        margin: 0;

        @include boxShadow();
        padding: 1rem;
        background: white;

        .step-header-text {
            grid-area: step-header-text;
            font-weight: bold;
            border-bottom: 1px solid $border-color;
            padding-bottom: 0.5rem;
        }

        .step-edit {
            grid-area: step-edit;
            text-align: right;
            font-size: 14px;
            border-bottom: 1px solid $border-color;
            padding-bottom: 0.5rem;

            .step-edit-text {
                color: $link-color;
                cursor: pointer;

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }

        .step-content {
            grid-area: step-content;
            font-size: 14px;

            // Step Job Details
            .job-title, .location, .deadline, .interview-terminology, .branding-name,
            .invitation-sms-template, .reminder-sms-template {
                border-top: 0;
                padding: 0 0 0.5rem 0;
            }

            .branding-name {
                padding: 0;
            }

            .ask-for-feedback, .star-rating-description {
                margin-top: 1rem;
                .emphasis {
                    font-weight: bold;
                }
            }

            .attributes {
                margin-top: 1rem;
                margin-left: 0;

                .attribute-list {
                    margin-block-start: 0;
                    margin-block-end: 0;
                    padding-inline-start: 20px;
                }
            }

            // Candidates
            .candidates-description {
                .open-registration {
                    display: block;
                }
            }

            // Email & SMS Templates
            .email-list, .sms-list {
                .header {
                    margin-top: 1rem;
                    font-weight: 700;

                    .subheader {
                        font-weight: 300;
                    }
                }
                .value {
                    padding-bottom: 0.5rem;

                    .template-type {
                        text-transform: capitalize;
                    }

                    strong {
                        font-weight: 500;
                        font-style: italic;
                    }
                }
            }

            // Evaluators
            .evaluators {
                .staff-selection {
                    padding-bottom: 1rem;
                }
                .candidate-contact {
                    padding-bottom: 1rem;
                    .candidate-contact-header {
                        font-weight: 700;
                        padding-bottom: 0.5rem;
                    }
                    .candidate-contact-name {
                        font-weight: 400;
                        line-height: 18px;
                    }
                    .candidate-contact-email {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.55);
                    }
                }
                .job-evaluators-header {
                    text-transform: none;
                    font-weight: 700;
                    padding-bottom: 0.5rem;
                }
            }
        }
    }
}