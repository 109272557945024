@import "../../styles/breakpoints";
@import "../../styles/common";

$primary-color: #00A1DF;
$error-color: #f44336;
$header-height: 60px;

.candidates-container {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-gap: 1rem;

    .upload-candidates {
        position: relative;
        text-align: right;

        .upload {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 118px;
            opacity: 0;
            cursor: pointer;
        }
    }

    .import-and-upload {
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-template-areas: "blank candidate-import upload-candidates";
        grid-gap: 1rem;

        .candidate-import {
            grid-area: candidate-import;
        }

        .upload-candidates {
            grid-area: upload-candidates;
            position: relative;
            text-align: right;

            .upload {
                position: absolute;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 118px;
                opacity: 0;
                cursor: pointer;

                &.disabled {
                    cursor: default;
                }
            }
        }
    }

    .candidates-list-container {
        background: white;
    }

    .candidates-list {
        @include boxShadow();
    }
}