@import "../../styles/breakpoints";
@import "../../styles/common";

.react-button {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    outline: 0;
    height: 2.7rem;
    line-height: 24px;
    transition: all .2s ease-out;
    white-space: nowrap;
    text-transform: none;
    letter-spacing: .5px;
    box-shadow: none;
    margin-right: 0.5em;
    user-select: none;
    padding: 0.5em 1em;

    &:hover, &:focus {
        filter: brightness(110%);
    }

    &.selected {
        &:hover, &:focus {
            filter: none;
            cursor: default;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    &.button-link {
        background-color: transparent;
        color: var(--primary-color);

        border-radius: 0;
        border-width: 0 0 1px;
        border-color: transparent;
        border-style: solid;
        margin: 0 1rem;
        padding: 0;
        line-height: 1rem;
        height: 1.5rem;
        width: auto;
        transition: all 0s;

        &.underline, &:hover {
            border-color: var(--primary-color);
        }
    }

    &.text-link {
        background-color: transparent;
        color: var(--primary-color);

        border: 0;
        padding: 0;
        height: auto;
        letter-spacing: unset;

        &:hover {
            text-decoration: underline;
        }
    }

    &.outline {
        background-color: transparent;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
    }

    &.info {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);

        &.outline {
            background-color: transparent;
            color: var(--primary-color);
        }
    }

    &.active {
        background-color: var(--active-color);
        border: 1px solid var(--active-color);

        &.outline {
            background-color: transparent;
            color: var(--active-color);
        }
    }

    &.pending {
        background-color: $pending-color;
        border: 1px solid $pending-color;

        &.outline {
            background-color: transparent;
            color: $pending-color;
        }
    }

    &.warning {
        background-color: $warning-color;
        border: 1px solid $warning-color;

        &.outline {
            background-color: transparent;
            color: $warning-color;
        }
    }

    &.disabled {
        background-color: var(--disabled-color);
        border: 1px solid var(--disabled-color);
        color: white !important; // materialize override
        overflow: hidden;
        cursor: default;

        &.outline {
            background-color: transparent;
            color: $disabled-color !important;
        }

        &:hover, &:focus {
            filter: none;
        }

        svg {
            path {
                stroke: $disabled-color;
            }
        }
    }

    &.readOnly {
        cursor: default !important;
        &:hover, &:focus {
            filter: none;
        }

        &.outline {
            &:hover, &:focus {
                border-color: rgba(55, 160, 160, 0.2) !important;
            }
        }
    }

    &.ripple {
        position: relative;
        overflow: hidden;
        transform: translate3d(0, 0, 0);

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
            background-repeat: no-repeat;
            background-position: 50%;
            transform: scale(10, 10);
            opacity: 0;
            transition: transform .5s, opacity 1s;
        }

        &:active {
            &:after {
                transform: scale(0, 0);
                opacity: .3;
                transition: 0s;
            }
        }
    }

    &.new {
        height: 28px;
        line-height: 14px;
        font-size: 14px;
    }
}

// integration
.criteria {
    .react-button {
        height: 2rem;
        padding: .5rem .75rem;
        border-radius: .25rem;
        font-size: 14px;
        line-height: 1em !important;
        font-family: var(--font-face);
        min-width: auto;

        &.button-link {
            border: 0;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
