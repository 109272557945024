@import "../../styles/common";

.evaluators-invite-modal {
  .overlay-form {
    .container-form {
      overflow-y: hidden;
    }
  }

  .evaluators-invite {
    .message {
      margin-bottom: 0.5rem;
    }
    .bulk-select {
      //margin-bottom: 0.5rem;
      font-size: 14px;
    }
    .evaluators-list {
      max-height: calc(90vh - 200px);
      overflow-x: hidden;
      overflow-y: scroll;
      margin-bottom: 1rem;

      .evaluator-selection {
        display: grid;
        grid-template-columns: auto auto 1fr;
        grid-gap: 0.5rem;

        padding: 0.5rem;
        margin: 0;
        user-select: none;

        &:hover {
          background-color: var(--default-background-highlight);
        }

        &.selected {
          background-color: var(--default-background-highlight);
        }
      }

      .evaluator-details {
        display: grid;
        grid-template-rows: repeat(2, auto);
        .name {
          height: 1rem;
          line-height: 1rem;
          font-weight: 400;
        }
        .position {
          height: 1rem;
          line-height: 1rem;
          font-size: 14px;
          color: $grey-color;
        }
      }
    }

    .actions {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-gap: 1rem;

      .number-selected {
        align-self: center;
        font-size: 14px;

        span {
          display: inline-block;
          width: 1rem;
          text-align: center;
        }
      }

      .cancel {
        align-self: center;
        justify-self: end;
      }
    }
  }
}