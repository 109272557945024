@import "../../styles/common";

.candidate-activity {
  .candidate-session {
    border: 1px solid $primary-color;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .item-label {
    font-weight: 500;
  }

  .session-header {
    padding: .5rem;
    background-color: var(--default-background-highlight);
    position: relative;

    .session-id {
      position: absolute;
      top: .25rem;
      right: .25rem;
      opacity: 0.25;
      font-weight: 600;
      color: $primary-color;
    }

    .timestamp {
      font-weight: 600;
    }

    .copy-user-agent {
      margin-left: 2px;
      .icon-share {
        cursor: pointer;
      }
    }
  }

  .session-item {
    border-top: 1px solid $primary-color;
    padding: .5rem;

    .question-id {
      font-weight: 600;
      color: $primary-color;
    }

    .activity-type {
      text-transform: capitalize;
      font-weight: 600;
    }
  }
}
