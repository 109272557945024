@import "../../../styles/breakpoints";
@import "../../../styles/common";

$inactive-color: rgba(0,0,0,0.25);
$header-height: 60px;

.step-questions {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
        "template-selection interview-template-edit-button"
        "interview-template interview-template"
        "assessment assessment";
    grid-gap: 1rem;

    @include from($screen-desktop) {
        grid-template-columns: 1fr auto 150px;
        grid-template-rows: auto;
        grid-template-areas:
            "template-selection interview-template-edit-button assessment"
            "interview-template interview-template assessment";
    }

    &.read-only {
        grid-gap: 0 1rem;
    }

    .section-header {
        font-weight: 600;
        padding: 0 0 0.5rem;
        font-size: 15px;
    }

    .template-selection {
        display: grid;
        grid-area: template-selection;

        .interview-template-selection-button {
            justify-self: end;
        }
    }

    .interview-template-edit-button {
        @include from($screen-desktop) {
          grid-area: interview-template-edit-button;
          justify-self: end;

          @include from($screen-desktop-l-min) {
            position: relative;
            top: 0;
            right: 0;
          }
        }
    }

    .interview-template {
        grid-area: interview-template;
    }

    .assessment {
        grid-area: assessment;

        .section-header {
            padding: 0;
        }

        .star-rating, .attribute-evaluation {
            label {
                font-size: 14px;
            }
        }
    }

    &.no-header {
        .questions-container {
            @include from($screen-desktop) {
                max-height: calc(100vh - 272px + #{$header-height});
                // max-height: calc((var(--vh, 1vh) * 100) - 272px + #{$header-height});

                @include from($screen-desktop-l-min) {
                    max-height: calc(100vh - 180px + #{$header-height});
                    // max-height: calc((var(--vh, 1vh) * 100) - 180px + #{$header-height});
                }
            }
        }
    }
}
