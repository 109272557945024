@import "../../../../styles/breakpoints";
@import "../../../../styles/common";

.email-template-details {
  > .overlay-form {
    height: 90vh;
    width: 90% !important;

    .container-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        "edit edit"
        "preview preview"
        "preview-settings-link actions";
      grid-gap: 0.75rem;

      @include from($screen-desktop) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr auto;
        grid-template-areas:
        "edit edit preview preview"
        "actions actions preview preview";
      }
    }

    .edit {
      grid-area: edit;
      display: grid;
      grid-gap: 0.75rem;
      grid-template-rows: repeat(5, auto) 1fr;

      .templates_type_dropdown {
        &+ .default-checkbox {
          margin-top: -1rem;
        }
      }

      .customTextArea {
        .ql-editor {
          min-height: 170px;
          max-height: none;
        }
      }
    }

    .default-checkbox {
      .label {
        top: 6px;
        text-transform: none;
      }
    }

    .preview {
      grid-area: preview;
      margin-bottom: 1rem;
    }

    .actions {
      grid-area: actions;
      justify-self: end;
    }
  }

  &.preview-only {
    > .overlay-form {
      @include from($screen-desktop) {
        width: 47.5% !important;
      }

      .container-form {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
        "preview preview";
        grid-gap: 0.75rem;
      }
    }
  }

  .preview-settings {
    .container-form {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: auto;
      grid-template-rows: repeat(2, auto);
      grid-template-areas: "job-settings" "interview-settings";

      @include from($screen-desktop) {
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "job-settings interview-settings";
      }

      .header {
        font-weight: 400;
      }

      .job-settings {
        grid-area: job-settings;
        display: grid;
        grid-gap: 0.5rem;
        align-self: start;
      }

      .interview-settings {
        grid-area: interview-settings;
        display: grid;
        grid-gap: 0.5rem;
        align-self: start;
      }

    }
  }
}
