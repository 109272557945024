@import "../../styles/breakpoints";
@import "../../styles/common";

.candidate-evaluation-container {
    display: grid;

    --candidate-evaluation-font-size: 16px;
    background-color: $default-background-color;

    --candidate-panel-width: 166px;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "evaluation";

    @include from($screen-desktop) {
        --candidate-panel-width: 166px;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-template-areas: "evaluation";
        background-color: $default-background-color;

        @include contentOverflow();
        @include sticky($z-index: 5);
    }

    &.has-status-panel {
        grid-template-rows: auto 1fr;
        grid-template-areas: "status-panel" "evaluation";
    }

    &.has-list {
        grid-template-rows: auto 1fr;
        grid-template-areas: "panel" "evaluation";

        @include from($screen-desktop) {
            grid-template-columns: var(--candidate-panel-width) 1fr;
            grid-template-areas: "panel evaluation";
        }

        &.has-status-panel {
            grid-template-rows: auto auto 1fr;
            grid-template-areas: "status-panel" "panel" "evaluation";

            @include from($screen-desktop) {
                grid-template-columns: var(--candidate-panel-width) 1fr;
                grid-template-areas:
                    "panel status-panel"
                    "panel evaluation";
            }
        }
    }

    &.in-progress {
        @include upTo($screen-desktop) {
            grid-template-columns: auto;
            grid-template-rows: auto auto 1fr;

            grid-template-areas: "in-progress" "panel" "evaluation";
        }

        @include from($screen-desktop) {
            grid-template-rows: auto 1fr;

            grid-template-areas:
                "panel in-progress"
                "panel evaluation";
        }
    }

    .candidates-panel {
        grid-area: panel;
    }

    @include from ($screen-desktop) {
        &.integration {
            height: 100vh;

            .candidates-panel {
                height: 100vh;
            }
        }
    }

    .status-panel {
        grid-area: status-panel;
    }

    .interview-in-progress {
        grid-area: in-progress;
        background: $pending-color;
        color: white;
        line-height: 2rem;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;

        @include from($screen-desktop) {
            @include sticky();
        }
    }

    .candidate-evaluation {
        grid-area: evaluation;
        position: relative;

        @include upTo($screen-desktop) {
            background-color: white;
            padding: 1rem;
            border-top: 1px solid $header-border-color;
        }

        @include from($screen-desktop) {
            padding: 1rem 1rem 2rem 1rem;
        }

        &.has-status-panel {
            padding-bottom: 3rem;
        }

        .submitted {
            margin-bottom: 1rem;
            padding: 0.5rem;
            background: $primary-color;
            color: white;
            user-select: none;
            border-radius: 6px;
            @include boxShadow();

            .unsubmit {
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .action-dropdown {
            position: absolute;
            right: 1rem;
            top: 1rem;

            .selections {
                width: 155px !important;
            }
        }

        .assessment-and-comments {
            margin-top: 1rem;

            .final-assessment {
                .final-assessment-section {
                    display: grid;
                    grid-template-rows: auto;
                    grid-template-columns: auto;
                    grid-template-areas: "table";
                    .attributes-table {
                        .attributes-row {
                            grid-template-columns: 100px 1fr;
                            grid-gap: 1rem;
                        }
                    }
                }
            }
        }

        .status-panel {
            margin: -1rem -1rem 0.75rem;
            padding: 0.25rem;
            color: white;
            font-weight: 400;
            text-align: center;

            &.completed {
                background-color: $highlight-color;
            }
        }

        .assessment-and-comments {
            grid-gap: 0.5rem;
            padding: 1rem;
            display: grid;
            background: white;
            border-radius: 6px;
            @include boxShadow();

            @include from($screen-desktop) {
                --grid-gap: 1rem;
                grid-gap: var(--grid-gap);
                --total-width: calc(100% - var(--grid-gap));
                grid-template-columns: var(--total-width);
                grid-template-rows: auto;
                grid-template-areas: "comments";

                &.has-assessment {
                    grid-template-columns: calc(var(--total-width)*2/3) calc(var(--total-width)/3);
                    grid-template-areas: "assessment comments";
                }

                &.has-decisions {
                    // grid-template-columns: calc(var(--total-width)*2/3) calc(var(--total-width)/3);
                    // grid-template-areas: "decision comments";
                    grid-template-columns: auto;
                    grid-template-rows: auto auto;
                    grid-template-areas: "decision" "comments";
                }

                &.has-assessment.has-decisions {
                    grid-template-rows: repeat(2, auto);
                    grid-template-areas: "assessment comments" "decision decision";
                }
            }
        }

        .evaluation-actions {
            position: relative;
            margin-top: 1rem;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: auto;
            grid-gap: 0.25rem;

            .submit-message {
                font-size: 14px;
                color: $primary-color;
            }

            .evaluation-buttons {
                display: flex;
                flex-direction: row;

                .evaluation-submit-button {
                    @include from($screen-desktop) {
                        width: 230px;
                    }
                }
            }
        }
    }
}
