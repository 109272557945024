@import "../../../styles/common";

.interview-guide {
    display: grid;
    background: white;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
    @include boxShadow();

    .interview-guide-summary {
        font-weight: 400;
    }

    .interview-guide-content {

    }
}