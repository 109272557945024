@import "../../styles/breakpoints";

$background-color: #fff;
$border-color: #eee;
$warning-color: #F44336;

.react-evaluators {
    box-shadow: 0 1px 0 0 rgba(69,90,100,0.05), 0 1px 1px 0 rgba(69,90,100,0.1), 0 1px 5px 0 rgba(69,90,100,0.05);

    .evaluator {
        padding: 0.5rem;
        background-color: $background-color;
        border: 1px solid $border-color;
        border-bottom: 0;
        display: grid;
        grid-template-rows: auto;
        align-items: center;
        line-height: 16px;
        grid-template-columns: auto repeat(2, 1fr) 20px 16px;
        grid-template-areas:
            "avatar names questions playback options"
            "avatar names questions playback options";
        grid-gap: 0 1rem;

        &.has-position {
            grid-template-areas:
                "avatar names questions playback options"
                "avatar position questions playback options";
        }

        @include from($screen-desktop) {
            padding: 1rem;
        }

        &.removable {
            grid-template-columns: auto repeat(2, 1fr) 20px 16px 16px;
            grid-template-areas:
                "avatar names questions playback options remove"
                "avatar names questions playback options remove";

            &.has-position {
                grid-template-areas:
                    "avatar names questions playback options remove"
                    "avatar position questions playback options remove";
            }
        }

        .newAvatar {
            grid-area: avatar;
        }

        .names {
            grid-area: names;
            font-weight: 400;
        }

        .position {
            grid-area: position;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.55);
        }

        .questions {
            grid-area: questions;

            .warning {
                color: $warning-color;
            }
        }

        .playback {
            grid-area: playback;
        }

        .options {
            grid-area: options;
            position: relative;

            .edit {
                cursor: default;

                &.editable {
                    cursor: pointer;

                    .lines-0 {
                        svg {
                            opacity: 0.3;
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }

                .badge {
                    position: absolute;
                    background-color: #00a3d1;
                    border-radius: 50%;
                    width: 12px;
                    color: white;
                    height: 12px;
                    text-align: center;
                    font-size: 9px;
                    top: -4px;
                    right: -7px;
                    line-height: 12px;
                    user-select: none;
                }

                .tooltip {
                    .description {
                        span {
                            display: block;
                        }
                    }
                }
            }
        }

        .remove {
            text-align: right;
            grid-area: remove;
            cursor: pointer;
            user-select: none;
            transition: all 0.1s;

            .icon-trash {
                width: 18px;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
