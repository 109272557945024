@import "../../styles/breakpoints";
@import "../../styles/common";

.candidates-invite {
  margin: 1rem 0.5rem 0.5rem;

  @include from($screen-desktop) {
    margin: 24px;
  }

  .header {
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    user-select: none;
  }

  .closed {
    text-align: center;
    margin-top: 1rem;

    .link {
      color: $primary-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .no-invitation-email-message {
    margin-bottom: 1rem;
  }

  .react-candidates {
    .candidates-list {
      max-height: none;
    }
  }

  .candidates-add {
    display: grid;
    grid-gap: 1rem;
    padding: 1rem 0;
    text-align: center;

    .candidates-add-button {
      justify-self: center;
    }
  }

  .candidates-list {
    .new-candidate {
      background-color: transparent;
    }
  }
}