$primary-color: #00a3d1;
$shade-color: #7d8b92;

.job-tile {
    display: grid;
    background: #fff;
    padding: 1rem;
    box-shadow: 0 1px 0 0 rgba(69, 90, 100, 0.05), 0 1px 1px 0 rgba(69, 90, 100, 0.2), 0 1px 5px 0 rgba(69, 90, 100, 0.1);
    border-top: 1px solid rgba(69, 90, 100, 0.07);
    border-radius: 3px;
    // min-height: 100%;
    opacity: 1;
    user-select: none;

    &.expired {
        // opacity: 0.6;
    }

    &.evaluator {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
            "title title"
            "received evaluated"
            "location deadline";

        &.expired {
            opacity: 1;
        }
    }

    &.manager {
        grid-template-columns: repeat(3, 1fr) repeat(2, 0.5fr);
        grid-template-rows: 52px repeat(3, 60px);
        grid-template-areas:
            "title title title title id"
            "invited received evaluated accepted accepted"
            "location location deadline deadline deadline"
            "evaluators evaluators evaluators evaluators evaluators";
    }

    .id {
        grid-area: id;
        font-size: 0.8em;
        color: $shade-color;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-align: right;
    }
    .title {
        grid-area: title;
        font-size: 1.15em;
        line-height: 1.2em;
        color: $primary-color;
        height: 2.8rem; // 2.4rem for new jobtile?
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

    .invited, .received, .evaluated, .accepted, .location, .deadline {
        color: initial;
    }

    .invited {
        grid-area: invited;
    }
    .received {
        grid-area: received;
    }
    .evaluated {
        grid-area: evaluated;
    }
    .accepted {
        grid-area: accepted;
    }
    .location {
        grid-area: location;
        width: 100%;
    }
    .deadline {
        grid-area: deadline;
    }
    .evaluators-thumbnails {
        grid-area: evaluators;
    }

    &.manager {
        .evaluators {
            padding-bottom: 0;
        }
    }

    &.evaluator {
        .location, .deadline {
            padding-bottom: 0;
        }
    }
}

// job tile with link
.job-tile {
    &[href*="/"] {
        cursor: pointer;
        transition: box-shadow .25s;
        transition: opacity 0.25s;

        &:hover, &:focus {
            box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
            transition: box-shadow .25s;
            outline: none;
        }
    }
}