@import "../../../styles/breakpoints";
@import "../../../styles/common";

.sms-templates {
  margin: 0;
  padding: 1rem 1rem 0;
  background: #f2f2f2;
  min-height: calc(100vh - 104px);

  @include from($screen-desktop) {
    padding: 2rem 2rem;
    @include contentOverflow($height: $content-height-after-header-and-subheader);
  }

  .sms-templates-header {
    background-color: white;
    border-bottom: 1px solid $header-border-color;
  }

  .header-row, .sms-template-row {
    display: grid;

    @include from($screen-desktop) {
      grid-template-columns: 1fr 2fr 3fr calc(60px + 1rem);
    }
  }

  .header-row {
    @include upTo($screen-desktop) {
      display: none;
    }

    .action {
      justify-self: end;
      padding-top: 7px;
      padding-bottom: 0;

      .new-button {
        height: 20px;
        padding: 2px 4px;
        background-color: $active-color;
        border-radius: 10px;

        svg {
          transform: scale(0.8);
          position: relative;
          top: 2px;
        }

        &:hover {
          cursor: pointer;
          padding-left: 8px;
          transition: all 0.1s linear;

          &:before {
            content: "Add";
            color: white;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .header-column {
    padding: 0.5rem;

    font-size: 11px;
    color: #7d8b92;
    text-transform: uppercase;
    user-select: none;
  }
}

.sms-template-row {
  display: grid;
  grid-template-columns: auto;
  position: relative;
  padding: 0.5rem 0;
  outline: none;
  background-color: $default-foreground-color;
  margin-bottom: 1px;
  border-radius: 3px;
  align-items: start;

  font-size: 14px;

  @include upTo($screen-desktop) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
        "name actions"
        "type actions"
        "content content";
  }

  .template-type,
  .template-name,
  .template-content,
  .sms-template-actions {
    padding: 0 0.5rem;
    line-height: 1.8rem;
  }

  .template-name {
    font-weight: 400;

    @include upTo($screen-desktop) {
      grid-area: name;
    }
  }

  .template-type {
    @include upTo($screen-desktop) {
      grid-area: type;
      font-size: 12px;
      line-height: 1rem;
      margin-top: -0.3rem;
    }
  }

  .template-content {
    @include upTo($screen-desktop) {
      grid-area: content;
    }
  }

  .sms-template-actions {
    justify-self: end;
    @include upTo($screen-desktop) {
      grid-area: actions
    }

    .react-dropdown {
      .selections {
        width: 80px;
      }
    }
  }
}