@import "../../../../styles/common";

.interview-template-details {
  > .overlay-form {
    width: 800px;
    background: $default-background-color;

    > .container-form {
      overflow: hidden;

      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: auto;
      grid-template-areas: "interview-template-content" "actions";

      .actions {
        margin: 0 -1rem -1rem;
        padding: 1rem;
      }
    }
  }

  &.no-modal {
    height: 100vh;
    overflow: hidden;

    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: auto;
    grid-template-areas: "interview-template-content" "actions";
  }

  &.create {
    .interview-template-content {
      grid-template-columns: 2fr 1fr;
      grid-template-areas: "interview-template-name blank" "terminology-dropdown blank" "blank-2 actions";
    }
  }

  .interview-template-content {
    grid-area: interview-template-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto) 1fr;
    grid-template-areas:
        "interview-template-name interview-template-edit-button"
        "terminology-dropdown interview-template-edit-button"
        "interview-template interview-template";
    grid-gap: 0.5rem;

    margin: 0 -1rem;
    padding: 0 1rem 1rem;

    overflow-x: hidden;
    overflow-y: scroll;

    .interview_template_name {
      grid-area: interview-template-name;
    }

    .type_dropdown {
      display: none;
      grid-area: type-dropdown;
    }

    .terminology_dropdown {
      grid-area: terminology-dropdown;
    }

    .interview-template-edit-button {
      grid-area: interview-template-edit-button;
      text-align: right;
      margin-bottom: 0.5rem;
    }

    .interview-template {
      grid-area: interview-template;
    }
  }

  .actions {
    grid-area: actions;
    padding-top: 1rem;
    text-align: right;
    position: sticky;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);

    .no-interview-questions {
      position: absolute;
      left: 1rem;
      top: 1.5rem;

      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 0.25rem;
      align-items: center;

      color: var(--primary-color);
      font-weight: 400;
    }
  }

  &.criteria {
    .interview-template-content {
      grid-template-rows: repeat(3, auto) 1fr;
      grid-template-areas:
        "interview-template-name interview-template-edit-button"
        "type-dropdown interview-template-edit-button"
        "terminology-dropdown interview-template-edit-button"
        "interview-template interview-template";

      .type_dropdown {
        display: block;
      }

      &.is-live {
        grid-template-rows: repeat(2, auto) 1fr;
        grid-template-areas:
        "interview-template-name interview-template-edit-button"
        "type-dropdown interview-template-edit-button"
        "interview-template interview-template";

        .terminology_dropdown {
          display: none;
        }
      }
    }
  }
}
