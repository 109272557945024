$primary-color: #00A1DF;
$primary-color-hover: #7d8b92;

.customRadioField {
    user-select: none;

    .label {
        // text-transform: uppercase;
        height: 1rem;
        font-size: 12.8px;
        color: #7d8b92;
    }
    .item {
        label {
            text-transform: capitalize;
            color: initial;
            padding: 2px 0;
            line-height: 26px;
            font-size: 1rem;
            font-weight: 400;

            display: grid;
            grid-gap: 8px;
            grid-template-columns: auto 1fr;
        }
    }

    input[type="radio"] {
        &:checked, &:not(:checked) {
            position: relative;
            left: initial;
            opacity: 1;
            height: 100%;
            // margin-right: 8px;
        }
    }
}