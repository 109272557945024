@import "../../styles/breakpoints";

.confirm-dialog {
    z-index: 50;

    .overlay-form {
        width: 350px;
        max-width: calc(100vw - 1rem);
    }
    .header {
        padding-bottom: 8px;
        font-weight: 600;
    }
    .message {
        padding-bottom: 16px;
    }
    .confirm-dialog-children {
        padding-bottom: 1rem;
    }
    .actions {
        text-align: right;
    }
}