@import "../../styles/common";

.action-dropdown {
    background: white;
    border-radius: 3px;
    width: 32px;
    height: 28px;

    &.has-border {
        border: 1px solid rgba(0,0,0,0.1);
    }

    &:hover {
        background: var(--hover-color);
    }

    .selector {
        font-size: 22px;
        font-weight: 400;
        height: 100%;

        &:hover, &:focus {
            .dropdown-overlay {
                background: transparent;
            }
        }

        .placeholder {
            color: black;
            line-height: 55%;
        }
    }

    .selections {
        max-height: 238px !important; // override react-dropdown .selections.open - originally 170px

        .selection {
            .mark {
                display: inline-block;
                height: 10px;
                width: 10px;
                background-color: rgba(0,0,0,0.1);
                border: 1px solid rgba(0,0,0,0.1);
                border-radius: 51%;

                &.filled {
                    background-color: rgba(0,255,0,1);
                }
            }
        }
    }
}