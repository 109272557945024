@import "../../styles/breakpoints";
@import "../../styles/common";
$primary-color: #00a3d1;

.react-navigation {
    background: linear-gradient(45deg, #004acf 0%, #00a3d1 100%);

    display: grid;
    grid-template-columns: 66px auto 66px;
    grid-template-rows: auto;
    grid-template-areas: "logo nav user";

    height: 60px;

    @include from($screen-desktop-l-min) {
        grid-template-columns: 66px auto 175px;
    }

    .logo {
        grid-area: logo;
        height: 40px;
        align-self: center;
        margin: 0 16px;
        background-image: url('../../images/logo-icon-criteria-color.png');
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .nav {
        grid-area: nav;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr auto;
        overflow-x: scroll;
        overflow-y: hidden;
        @include no-scroll-bar();

        @include from($screen-desktop) {
            justify-self: center;
            overflow-x: hidden;
        }

        .nav-item {
            background-color: transparent;
            transition: background-color .3s;
            color: #fff;
            cursor: pointer;
            text-align: center;
            line-height: 60px;
            user-select: none;
            padding: 0 10px;
            font-size: 18px;
            border: 0;

            @include from($screen-desktop) {
                padding: 0 20px;
                min-width: 124px;
            }

            &.active {
                font-weight: 700;
                position: relative;

                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    bottom: -1px;
                    left: calc(50% - 7px);
                    border-bottom: 10px solid white;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    background: transparent;
                }
            }

            &:hover, &:focus {
                background-color: rgba(0,0,0,0.1);
            }
        }

        .react-button {
            align-self: center;
            height: 36px;
            line-height: 18px;

            width: 108px;

            margin: 0 10px;
            font-size: 18px;

            @include from($screen-desktop-s-min) {
                margin: 0 20px;
            }
        }
    }

    .user {
        grid-area: user;
        justify-self: right;
        margin: 0 16px;

        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas: "avatar";

        cursor: pointer;
        position: relative;

        @include from($screen-desktop-l-min) {
            grid-template-columns: auto auto;
            grid-template-rows: auto auto;
            grid-template-areas: "avatar username" "avatar logout";
            grid-gap: 0 8px;
            cursor: default;
        }

        .react-avatar {
            grid-area: avatar;
            align-self: center;
        }
        .username {
            grid-area: username;
            align-self: end;
            color: white;
            white-space: nowrap;
            display: none;
            text-overflow: ellipsis;

            @include from($screen-desktop-l-min) {
                display: block;
            }

        }
        .logout {
            grid-area: logout;
            align-self: start;
            color: white;
            white-space: nowrap;
            // display: inline-block;
            line-height: 1;
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 0.7px;
            background: rgba(255, 255, 255, 0.2);
            padding: 3px 3px 2px 4px;
            border-radius: 2px;
            width: 54px;
            user-select: none;
            text-align: center;
            border: none;
            display: none;

            @include from($screen-desktop-l-min) {
                display: block;
            }
        }

        .userMenu {
            display: none;

            &.open {
                position: fixed;
                background: white;
                top: 60px;
                @include boxShadow;
                z-index: 1;
                transition: all 0.1s linear;
                min-width: 150px;
                right: 0;
                padding: 10px;
                display: grid;
                grid-template-rows: 1fr auto;
                grid-template-columns: auto;
            }

            &.blank {
                @include from($screen-desktop-l-min) {
                    padding: 0;
                }
            }

            .username {
                display: block;
                color: black;
                grid-area: unset;
                justify-self: right;
                align-self: start;
                width: 100%;

                @include from($screen-desktop-l-min) {
                    display: none;
                }
            }

            .settings {
                .settings-header {
                    font-size: 14px;
                    font-weight: 400;
                }
                .settings-content {
                    text-transform: capitalize;
                }
                .settings-item {
                    font-size: 0.9rem;
                    padding-bottom: 0.25rem;
                    color: $primary-color;

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }

            .logout {
                display: block;
                justify-self: right;
                background: $primary-color;
                grid-area: unset;

                @include from($screen-desktop-l-min) {
                    display: none;
                }
            }
        }
    }

    .tenant-dialog {
        > .overlay-form {
            width: 90%;
        }
    }
}
