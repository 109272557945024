@import "../../styles/breakpoints";
@import "../../styles/common";

:root {
    --star-size: 16px;
    --star-background: rgba(0,0,0,0.2); // rgba(95, 113, 122, 0.2);
    --maximum-score: 5;
}

.ratings {
    float: left;
    display: flex;
    align-items: center;

    &:not(:checked) {
        > input {
            display: none;
        }

        > label {
            // revert materialize
            transition: all 0s linear;
            margin-bottom: 0;

            &:before {
                border: 0;
                content: '';
            }

            &:after {
                display: none;
            }
        }
    }

    /* STAR STYLE */
    &[class*="star"] {
        transform: scaleX(-1);
        width: 90px;

        &.has-clear {
            width: 105px;
        }

        &:not(:checked) {
            > label {
                overflow: hidden;
                white-space: nowrap;
                cursor: pointer;
                margin-top: -8px;
                padding-left: 2px;

                &:before {
                    display: none;
                }

                .icon-star {
                    width: var(--star-size);
                    path {
                        fill: var(--star-background);
                    }
                }
            }
        }

        > input {
            &:checked {
                ~ label {
                    .icon-star {
                        path {
                            fill: var(--active-color-bright);
                        }
                    }
                }
            }
        }

        > label {
            &:hover + .tooltip {
                display: block;
                position: absolute;
                transform: scaleX(-1);
                font-size: 12px;
                font-family: var(--font-face);
                width: 200px;
                right: 0;

                background: white;
                border: 1px solid rgba(0,0,0,0.2);
                padding: 0.5rem;
                line-height: 0.8rem;
            }
        }

        > .tooltip {
            display: none;
        }

        &.active {
            &:not(:checked) {
                > label {
                    &:hover, &:hover ~ label {
                        .icon-star {
                            path {
                                fill: var(--active-color-semi);
                            }
                        }
                    }
                }
            }

            > input {
                &:checked {
                    + label:hover,
                    + label:hover ~ label,
                    ~ label:hover,
                    ~ label:hover ~ label {
                        .icon-star {
                            path {
                                fill: $active-color-dark;
                            }
                        }
                    }
                }
            }

            > label {
                &:hover ~ input:checked ~ label {
                    .icon-star {
                        path {
                            fill: var(--active-color-bright);
                        }
                    }
                }
            }
        }

        .clear {
            margin-right: 3px;

            svg {
                path {
                    transform: scale(0.7);
                }
            }
        }
    }

    /* NUMERIC STYLE */
    &[class*="numeric"] {
        > label {
            border: 1px solid rgba(0, 0, 0, 0.1);
            width: 22px;
            height: 22px;
            font-size: 14px;
            text-align: center;
            padding-left: 0;
            color: rgba(0, 0, 0, 0.5);
            background-color: $default-foreground-color;

            // making it round and big
            border-radius: 50%;
            line-height: 22px;
            margin-right: 0.25rem;

            @include from($screen-desktop) {
                margin-right: 0.5rem;
                width: 30px;
                height: 30px;
                line-height: 28px;
                font-size: 16px;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                // border-color: rgba(0, 0, 0, 1);
                // color: rgba(0, 0, 0, 1);
                z-index: 2;

                // making it round
                // color: rgba(0,0,0,0.5);
                background-color: rgba($primary-color, 0.25);
            }

            &:before {
                width: 0;
            }
        }

        > input {
            &:checked {
                + label {
                    color: white;
                    background-color: $primary-color;
                }
            }
        }

        &.inactive {
            > label {
                &:hover {
                    background-color: initial;
                }
            }

            > input {
                &:checked {
                    + label {
                        background-color: $primary-color;
                    }
                }
            }
        }

        .clear {
            margin-right: 10px;
        }
    }

    &.inactive {
        > label {
            cursor: default !important;
            border: 0;
        }
    }

    .clear {
        position: relative;
        top: 1px;

        svg {
            path {
                fill: $grey-color;
            }
        }

        &:hover {
            cursor: pointer;
            svg {
                path {
                    fill: $warning-color;
                }
            }
        }
    }
}
