ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none; }
  ul:not(.browser-default) li {
    list-style-type: none; }

i {
  line-height: inherit; }
  i.left {
    float: left;
    margin-right: 15px; }
  i.right {
    float: right;
    margin-left: 15px; }
  i.tiny {
    font-size: 1rem; }
  i.small {
    font-size: 2rem; }
  i.medium {
    font-size: 4rem; }
  i.large {
    font-size: 6rem; }


/*******************
  Utility Classes
*******************/
.hide {
  display: none !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

.circle {
  border-radius: 50%; }



h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.1; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit; }

h1 {
  font-size: 5.5rem;
  line-height: 110%;
  margin: 2.75rem 0 2.2rem 0; }

h2 {
  font-size: 3rem;
  line-height: 110%;
  margin: 1.5rem 0 1.2rem 0; }

h3 {
  font-size: 1.92rem;
  line-height: 110%;
  margin: 0.96rem 0 0.768rem 0; }

h4 {
  font-size: 1.28rem;
  line-height: 110%;
  margin: 0.64rem 0 0.512rem 0; }

h5 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0; }

h6 {
  font-size: 1rem;
  line-height: 110%;
  margin: 0.5rem 0 0.4rem 0; }




.btn {
  border: none;
  border-radius: 3px;
  display: inline-block;
  height: 2.7rem;
  line-height: 2.7rem;
  padding: 0 1rem;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent; }

.btn[disabled] {
  pointer-events: none;
  background-color: #DFDFDF !important;
  color: #9F9F9F !important;
  cursor: default; }

  .btn[disabled]:hover {
    background-color: #DFDFDF !important;
    color: #9F9F9F !important; }

.btn {
  outline: 0; }

.btn:focus {
  background-color: #007b9e; }

.btn {
  text-decoration: none;
  color: #fff;
  background-color: #00a3d1;
  text-align: center;
  letter-spacing: .5px;
  transition: .2s ease-out;
  cursor: pointer; }
  .btn:hover {
    background-color: #00b7eb; }




select:focus {
  outline: 1px solid #c2f2ff; }

button:focus {
  outline: none;
  background-color: #00b3e5; }

label {
  font-size: 0.8rem;
  color: #7d8b92; }

/* Text Inputs + Textarea
   ========================================================================== */
/* Style Placeholders */
::-webkit-input-placeholder {
  color: #b4bdc1; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #b4bdc1; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #b4bdc1; }

:-ms-input-placeholder {
  color: #b4bdc1; }

/* Text inputs */
input:not([type]),
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search],
textarea.materialize-textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #7d8b92;
  border-radius: 0;
  outline: none;
  height: 1.9rem;
  width: 100%;
  font-size: 1.2rem;
  margin: 0 0 15px 0;
  padding: 1rem 0 0 0.2rem;
  box-shadow: none;
  box-sizing: content-box;
  transition: all 0.3s; }
  input:not([type]):disabled, input:not([type])[readonly="readonly"],
  input[type=text]:disabled,
  input[type=text][readonly="readonly"],
  input[type=password]:disabled,
  input[type=password][readonly="readonly"],
  input[type=email]:disabled,
  input[type=email][readonly="readonly"],
  input[type=url]:disabled,
  input[type=url][readonly="readonly"],
  input[type=time]:disabled,
  input[type=time][readonly="readonly"],
  input[type=date]:disabled,
  input[type=date][readonly="readonly"],
  input[type=datetime]:disabled,
  input[type=datetime][readonly="readonly"],
  input[type=datetime-local]:disabled,
  input[type=datetime-local][readonly="readonly"],
  input[type=tel]:disabled,
  input[type=tel][readonly="readonly"],
  input[type=number]:disabled,
  input[type=number][readonly="readonly"],
  input[type=search]:disabled,
  input[type=search][readonly="readonly"],
  textarea.materialize-textarea:disabled,
  textarea.materialize-textarea[readonly="readonly"] {
    color: rgba(0, 0, 0, 0.26);
    border-bottom: 1px dotted rgba(0, 0, 0, 0.26); }
  input:not([type]):disabled + label, input:not([type])[readonly="readonly"] + label,
  input[type=text]:disabled + label,
  input[type=text][readonly="readonly"] + label,
  input[type=password]:disabled + label,
  input[type=password][readonly="readonly"] + label,
  input[type=email]:disabled + label,
  input[type=email][readonly="readonly"] + label,
  input[type=url]:disabled + label,
  input[type=url][readonly="readonly"] + label,
  input[type=time]:disabled + label,
  input[type=time][readonly="readonly"] + label,
  input[type=date]:disabled + label,
  input[type=date][readonly="readonly"] + label,
  input[type=datetime]:disabled + label,
  input[type=datetime][readonly="readonly"] + label,
  input[type=datetime-local]:disabled + label,
  input[type=datetime-local][readonly="readonly"] + label,
  input[type=tel]:disabled + label,
  input[type=tel][readonly="readonly"] + label,
  input[type=number]:disabled + label,
  input[type=number][readonly="readonly"] + label,
  input[type=search]:disabled + label,
  input[type=search][readonly="readonly"] + label,
  textarea.materialize-textarea:disabled + label,
  textarea.materialize-textarea[readonly="readonly"] + label {
    color: rgba(0, 0, 0, 0.26); }
  input:not([type]):focus:not([readonly]),
  input[type=text]:focus:not([readonly]),
  input[type=password]:focus:not([readonly]),
  input[type=email]:focus:not([readonly]),
  input[type=url]:focus:not([readonly]),
  input[type=time]:focus:not([readonly]),
  input[type=date]:focus:not([readonly]),
  input[type=datetime]:focus:not([readonly]),
  input[type=datetime-local]:focus:not([readonly]),
  input[type=tel]:focus:not([readonly]),
  input[type=number]:focus:not([readonly]),
  input[type=search]:focus:not([readonly]),
  textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #00a3d1;
    box-shadow: 0 1px 0 0 #00a3d1; }
  input:not([type]):focus:not([readonly]) + label,
  input[type=text]:focus:not([readonly]) + label,
  input[type=password]:focus:not([readonly]) + label,
  input[type=email]:focus:not([readonly]) + label,
  input[type=url]:focus:not([readonly]) + label,
  input[type=time]:focus:not([readonly]) + label,
  input[type=date]:focus:not([readonly]) + label,
  input[type=datetime]:focus:not([readonly]) + label,
  input[type=datetime-local]:focus:not([readonly]) + label,
  input[type=tel]:focus:not([readonly]) + label,
  input[type=number]:focus:not([readonly]) + label,
  input[type=search]:focus:not([readonly]) + label,
  textarea.materialize-textarea:focus:not([readonly]) + label {
    color: var(--label-color); }
  input:not([type]).valid, input:not([type]):focus.valid,
  input[type=text].valid,
  input[type=text]:focus.valid,
  input[type=password].valid,
  input[type=password]:focus.valid,
  input[type=email].valid,
  input[type=email]:focus.valid,
  input[type=url].valid,
  input[type=url]:focus.valid,
  input[type=time].valid,
  input[type=time]:focus.valid,
  input[type=date].valid,
  input[type=date]:focus.valid,
  input[type=datetime].valid,
  input[type=datetime]:focus.valid,
  input[type=datetime-local].valid,
  input[type=datetime-local]:focus.valid,
  input[type=tel].valid,
  input[type=tel]:focus.valid,
  input[type=number].valid,
  input[type=number]:focus.valid,
  input[type=search].valid,
  input[type=search]:focus.valid,
  textarea.materialize-textarea.valid,
  textarea.materialize-textarea:focus.valid {
    border-bottom: 1px solid #59c427;
    box-shadow: 0 1px 0 0 #59c427; }
  input:not([type]).valid + label:after, input:not([type]):focus.valid + label:after,
  input[type=text].valid + label:after,
  input[type=text]:focus.valid + label:after,
  input[type=password].valid + label:after,
  input[type=password]:focus.valid + label:after,
  input[type=email].valid + label:after,
  input[type=email]:focus.valid + label:after,
  input[type=url].valid + label:after,
  input[type=url]:focus.valid + label:after,
  input[type=time].valid + label:after,
  input[type=time]:focus.valid + label:after,
  input[type=date].valid + label:after,
  input[type=date]:focus.valid + label:after,
  input[type=datetime].valid + label:after,
  input[type=datetime]:focus.valid + label:after,
  input[type=datetime-local].valid + label:after,
  input[type=datetime-local]:focus.valid + label:after,
  input[type=tel].valid + label:after,
  input[type=tel]:focus.valid + label:after,
  input[type=number].valid + label:after,
  input[type=number]:focus.valid + label:after,
  input[type=search].valid + label:after,
  input[type=search]:focus.valid + label:after,
  textarea.materialize-textarea.valid + label:after,
  textarea.materialize-textarea:focus.valid + label:after {
    content: attr(data-success);
    color: #59c427;
    opacity: 1; }
  input:not([type]).invalid, input:not([type]):focus.invalid,
  input[type=text].invalid,
  input[type=text]:focus.invalid,
  input[type=password].invalid,
  input[type=password]:focus.invalid,
  input[type=email].invalid,
  input[type=email]:focus.invalid,
  input[type=url].invalid,
  input[type=url]:focus.invalid,
  input[type=time].invalid,
  input[type=time]:focus.invalid,
  input[type=date].invalid,
  input[type=date]:focus.invalid,
  input[type=datetime].invalid,
  input[type=datetime]:focus.invalid,
  input[type=datetime-local].invalid,
  input[type=datetime-local]:focus.invalid,
  input[type=tel].invalid,
  input[type=tel]:focus.invalid,
  input[type=number].invalid,
  input[type=number]:focus.invalid,
  input[type=search].invalid,
  input[type=search]:focus.invalid,
  textarea.materialize-textarea.invalid,
  textarea.materialize-textarea:focus.invalid {
    border-bottom: 1px solid #f44336;
    box-shadow: 0 1px 0 0 #f44336; }
  input:not([type]).invalid + label:after, input:not([type]):focus.invalid + label:after,
  input[type=text].invalid + label:after,
  input[type=text]:focus.invalid + label:after,
  input[type=password].invalid + label:after,
  input[type=password]:focus.invalid + label:after,
  input[type=email].invalid + label:after,
  input[type=email]:focus.invalid + label:after,
  input[type=url].invalid + label:after,
  input[type=url]:focus.invalid + label:after,
  input[type=time].invalid + label:after,
  input[type=time]:focus.invalid + label:after,
  input[type=date].invalid + label:after,
  input[type=date]:focus.invalid + label:after,
  input[type=datetime].invalid + label:after,
  input[type=datetime]:focus.invalid + label:after,
  input[type=datetime-local].invalid + label:after,
  input[type=datetime-local]:focus.invalid + label:after,
  input[type=tel].invalid + label:after,
  input[type=tel]:focus.invalid + label:after,
  input[type=number].invalid + label:after,
  input[type=number]:focus.invalid + label:after,
  input[type=search].invalid + label:after,
  input[type=search]:focus.invalid + label:after,
  textarea.materialize-textarea.invalid + label:after,
  textarea.materialize-textarea:focus.invalid + label:after {
    content: attr(data-error);
    color: #f44336;
    opacity: 1; }
  input:not([type]).validate + label,
  input[type=text].validate + label,
  input[type=password].validate + label,
  input[type=email].validate + label,
  input[type=url].validate + label,
  input[type=time].validate + label,
  input[type=date].validate + label,
  input[type=datetime].validate + label,
  input[type=datetime-local].validate + label,
  input[type=tel].validate + label,
  input[type=number].validate + label,
  input[type=search].validate + label,
  textarea.materialize-textarea.validate + label {
    width: 100%;
    pointer-events: none; }
  input:not([type]) + label:after,
  input[type=text] + label:after,
  input[type=password] + label:after,
  input[type=email] + label:after,
  input[type=url] + label:after,
  input[type=time] + label:after,
  input[type=date] + label:after,
  input[type=datetime] + label:after,
  input[type=datetime-local] + label:after,
  input[type=tel] + label:after,
  input[type=number] + label:after,
  input[type=search] + label:after,
  textarea.materialize-textarea + label:after {
    display: block;
    content: "";
    position: absolute;
    top: 60px;
    opacity: 0;
    transition: .2s opacity ease-out, .2s color ease-out; }

.input-field {
  position: relative;
  margin-top: 1rem; }
  .input-field.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px; }
    .input-field.inline input,
    .input-field.inline .select-dropdown {
      margin-bottom: 1rem; }
  .input-field.col label {
    left: 0; }
  .input-field.col .prefix ~ label,
  .input-field.col .prefix ~ .validate ~ label {
    width: calc(100% - 3rem - 0); }
  .input-field label {
    color: var(--field-label-color);
    position: absolute;
    top: 0.8rem;
    left: 0;
    font-size: 1rem;
    cursor: text;
    transition: .2s ease-out; }
    .input-field label:not(.label-icon).active {
      font-size: var(--field-label-size);
      transform: translateY(-140%); }

/* Search Field */
.input-field input[type=search] {
  display: block;
  line-height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem); }
  .input-field input[type=search]:focus {
    background-color: #fff;
    border: 0;
    box-shadow: none;
    color: #444; }
    .input-field input[type=search]:focus + label i, .input-field input[type=search]:focus ~ .mdi-navigation-close, .input-field input[type=search]:focus ~ .material-icons {
      color: #444; }
  .input-field input[type=search] + label {
    left: 1rem; }
  .input-field input[type=search] ~ .mdi-navigation-close, .input-field input[type=search] ~ .material-icons {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: 2rem;
    transition: .3s color; }

/* Textarea */
textarea {
  width: 100%;
  height: 1.9rem;
  background-color: transparent; }
  textarea.materialize-textarea {
    overflow-y: hidden;
    /* prevents scroll bar flash */
    padding: .8rem 0 1.6rem 0;
    /* prevents text jump on Enter keypress */
    resize: none;
    min-height: 1.9rem; }


/* Radio Buttons
   ========================================================================== */
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0; }

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: .28s ease;
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  user-select: none; }

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease; }

/* Unchecked styles */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%; }

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 2px solid #5a5a5a; }

[type="radio"]:not(:checked) + label:after {
  transform: scale(0); }

/* Checked styles */
[type="radio"]:checked + label:before {
  border: 2px solid transparent; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 1px solid #00a3d1; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #00a3d1; }

[type="radio"]:checked + label:after {
  transform: scale(1.02); }

/* Radio With gap */
[type="radio"].with-gap:checked + label:after {
  transform: scale(0.5); }

/* Focused styles */
[type="radio"].tabbed:focus + label:before {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1); }

/* Disabled Radio With gap */
[type="radio"].with-gap:disabled:checked + label:before {
  border: 2px solid rgba(0, 0, 0, 0.26); }

[type="radio"].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.26); }

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled + label {
  color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.26);
  border-color: #BDBDBD; }

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
form p {
  margin-bottom: 10px;
  text-align: left; }

form p:last-child {
  margin-bottom: 0; }

/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0; }

[type="checkbox"] {
  /* checkbox aspect */ }
  [type="checkbox"] + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */ }
  [type="checkbox"] + label:before, [type="checkbox"]:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 2px;
    transition: .2s; }
  [type="checkbox"]:not(.filled-in) + label:after {
    border: 0;
    transform: scale(0); }
  [type="checkbox"]:not(:checked):disabled + label:before {
    border: none;
    background-color: rgba(0, 0, 0, 0.26); }
  [type="checkbox"].tabbed:focus + label:after {
    transform: scale(1);
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }

[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 1px solid #00a3d1;
  border-bottom: 1px solid #00a3d1;
  transform: rotate(40deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%; }
[type="checkbox"]:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26); }

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 1px solid #00a3d1;
  border-bottom: none;
  transform: rotate(90deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%; }
[type="checkbox"]:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent; }

[type="checkbox"].filled-in + label:after {
  border-radius: 2px; }
[type="checkbox"].filled-in + label:before,
[type="checkbox"].filled-in + label:after {
  content: '';
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1; }
[type="checkbox"].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%; }
[type="checkbox"].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0;
  z-index: 0; }
[type="checkbox"].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }
[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #00a3d1;
  background-color: #00a3d1;
  z-index: 0; }
[type="checkbox"].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1); }
[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #00a3d1;
  border-color: #00a3d1; }
[type="checkbox"].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent; }
[type="checkbox"].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #BDBDBD; }
[type="checkbox"].filled-in:disabled:checked + label:before {
  background-color: transparent; }
[type="checkbox"].filled-in:disabled:checked + label:after {
  background-color: #BDBDBD;
  border-color: #BDBDBD; }

/* Switch
   ========================================================================== */
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none; }

.switch label {
  cursor: pointer; }

.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0; }
  .switch label input[type=checkbox]:checked + .lever {
    background-color: #67cde9; }
    .switch label input[type=checkbox]:checked + .lever:after {
      background-color: #00a3d1;
      left: 24px; }

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: #818181;
  border-radius: 15px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px; }
  .switch label .lever:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: #F1F1F1;
    border-radius: 21px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: -5px;
    top: -3px;
    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease; }

input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 163, 209, 0.1); }

input[type=checkbox]:not(:disabled) ~ .lever:active:after,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08); }

.switch input[type=checkbox][disabled] + .lever {
  cursor: default; }

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
  background-color: #BDBDBD; }

/* Select Field
   ========================================================================== */
select {
  display: none; }

select.browser-default {
  display: block; }

select {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 1.9rem; }

select:disabled {
  color: rgba(0, 0, 0, 0.3); }




/* ==========================================================================
   Aviia Overrides
   ========================================================================== */

/* 10. Forms
   ========================================================================== */
/* Text Inputs + Textarea
   ========================================================================== */
/* Text inputs */
input:not([type]),
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search],
textarea.materialize-textarea {
  background-color: #fff;
  font-weight: 400;
  vertical-align: bottom;
  border-radius: 1px;
  height: 2.7rem;
  box-sizing: border-box; }

.input-field {
  margin-bottom: 1rem; }
  .input-field label {
    white-space: nowrap;
    left: 0.2rem !important;
    top: 0.55rem;
    font-size: 1rem;
    padding-top: 0.1rem; }
    .input-field label:not(.label-icon).active {
      transform: translateY(-50%); }


/* 2. Radio Buttons --> _radio-buttons.scss
   ========================================================================== */
.radio label {
  left: 0 !important; }

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  padding-left: 25px;
  color: #000;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 5px; }

[type="radio"] + label:before,
[type="radio"] + label:after {
  margin: 4px 0; }

/* Unchecked styles */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 1px solid #5a5a5a;
  background: #fff; }

/* CUSTOM CSS CHECKBOXES */
/* Remove default checkbox */
[type="checkbox"] {
  /* checkbox aspect */ }
  [type="checkbox"] + label {
    padding-left: 27px;
    color: #000; }
  [type="checkbox"] + label:before, [type="checkbox"]:not(.filled-in) + label:after {
    border: 1px solid #5a5a5a; }

/* Text Inputs + Textarea
   ========================================================================== */
.input-field label {
  left: 0; }
