$primary-color: #00A1DF;
$success-color: #00A1DF;
$fail-color: #F44336;

.candidate-edit {
    .customTextField {
        margin-top: 0.5rem;
    }

    .actions {
        text-align: right;
        display: grid;
        grid-template-columns: 1fr auto;
        margin-top: 16px;
        grid-gap: 1rem;
        align-items: center;

        .status-message {
            background: transparent;
            color: $fail-color !important;
            line-height: 2rem;
        }
    }

}