.assess {
    .job-tiles {
        width: 100%;
        display: grid;
        padding: 1rem;
        grid-gap: 1rem;
        align-content: start;
        -webkit-box-pack: center;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    }
}