@import "../../styles/common";

.avatar-with-label {
    position: relative;
    height: 24px;
    display: inline-block;
    vertical-align: top;
    top: 2px;

    &.yes {
        width: 46px;
    }

    &.maybe {
        width: 62px;
    }

    &.no {
        width: 44px;
    }

    .react-avatar {
        position: absolute;
        top: -2px;
        left: 0;
    }

    .avatar-label {
        font-size: 10px;
        line-height: 12px;
        margin-left: -8px;
        text-transform: uppercase;
        padding: 3px 4px 1px 8px;
        color: #fff;
        border-radius: 3px;
        font-weight: 600;
        display: inline-block;
        user-select: none;

        position: absolute;
        top: 2px;
        left: 22px;

        &.yes {
            background-color: #4CAF50;
        }
        &.maybe {
            background-color: orange;
        }
        &.no {
            background-color: red;
        }
    }
}