@import "../../../styles/breakpoints";
@import "../../../styles/common";

.company-details {
    padding: 1rem;
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: auto;
    grid-template-areas: "section-first" "section-second";

    --section-gap: 2rem;
    grid-gap: var(--section-gap);

    @include from($screen-desktop) {
        @include contentOverflow($height: $content-height-after-header-and-subheader);

        --section-width: calc((100% - var(--section-gap))/2);
        padding: 2rem;

        grid-template-rows: auto;
        grid-template-columns: repeat(2, var(--section-width));
        grid-template-areas: "section-first section-second";

        @include from($screen-desktop-l-min) {
            --section-width: calc((100% - var(--section-gap))/3);
            grid-template-columns: calc(var(--section-width)) calc(var(--section-width) * 2);
        }
    }

    .column-left {
        grid-area: section-first;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: auto;
        grid-template-areas: "logo";
        align-items: start;
        width: 50%;
    }

    .column-right {
        grid-area: section-second;
        display: grid;
        grid-template-rows: repeat(3, auto) 1fr;
        grid-template-columns: auto;
        grid-template-areas: "phone" "email" "address" "website";
        grid-gap: 1rem;

        .label-value-tile {
            .value {
                text-transform: none;
            }
        }
    }

    .logo {
        grid-area: logo;
    }

    .contact {
        grid-area: contact;
        span {
            display: block;
        }
    }

    .phone {
        grid-area: phone;
    }

    .email {
        grid-area: email;
    }

    .address {
        grid-area: address;
        span {
            display: block;
        }
    }

    .website {
        grid-area: website;
    }

    .contact, .phone, .email, .address, .website {
        border: 0;
        padding-top: 0;
    }
}