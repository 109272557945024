@import "../../../styles/breakpoints";
@import "../../../styles/common";

.candidate-add {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: auto auto;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
        "first-name last-name"
        "email phone"
        "deadline add-button";
    align-items: start;

    &.has-candidate-deadline-timezone-true {
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
        "first-name last-name"
        "email phone"
        "deadline deadline-timezone"
        "add-button add-button";
    }

    @include from($screen-desktop) {
        --full-width: 100%;
        --button-width: 61px;
        --gap-width: 0.5rem;
        --width-rest: calc(var(--full-width) - var(--button-width) - var(--gap-width));
        --column-width: calc((var(--width-rest) - (4 * var(--gap-width)) )/ 17);

        grid-template-rows: auto;
        grid-template-columns:
            calc(var(--column-width) * 3)
            calc(var(--column-width) * 3)
            calc(var(--column-width) * 4)
            calc(var(--column-width) * 4)
            calc(var(--column-width) * 3)
            var(--button-width);

        grid-template-areas: "first-name last-name email phone deadline add-button";

        &.has-candidate-deadline-timezone-true {
            --column-width: calc((var(--width-rest) - (5 * var(--gap-width)) )/ 17);

            grid-template-columns:
            calc(var(--column-width) * 2)
            calc(var(--column-width) * 2)
            calc(var(--column-width) * 4)
            calc(var(--column-width) * 2)
            calc(var(--column-width) * 2)
            calc(var(--column-width) * 5)
            var(--button-width);

            grid-template-areas: "first-name last-name email phone deadline deadline-timezone add-button";

        }
    }

    .first-name, .last-name, .email, .phone, .deadline, .deadline-timezone {
        > div {
            width: auto;
        }

        input {
            height: 44px;
        }
    }

    .first-name {
        grid-area: first-name;
    }

    .last-name {
        grid-area: last-name;
    }

    .email {
        grid-area: email;
    }

    .phone {
        grid-area: phone;
    }

    .deadline {
        grid-area: deadline;
    }

    .deadline-timezone {
        grid-area: deadline-timezone;
        background-color: $default-foreground-color;

        .selection-label {
            height: 18px;
        }
        .selection-value {
            height: 26px;

            &:hover {
                background-color: $default-foreground-color;
            }

            .selection {
                .selection-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .clear {
                    background-color: transparent;
                }
            }
        }
    }

    .add-button {
        grid-area: add-button;
        align-self: start;
        justify-self: end;
    }
}