@import "../../styles/breakpoints";
@import "../../styles/common";

$primary-color: #00A1DF;

.react-manage {
    .sort-and-filter {
        position: relative;

        @include from($screen-desktop) {
            @include sticky($top: $header-height + $sub-header-height, $z-index: 5);
        }

        .sort {
            position: absolute;
            top: -39px;
            right: 3.5rem;
            width: 120px;
            color: $primary-color;
        }

        .filter-icon {
            position: absolute;
            right: 0;
            top: -44px;
            height: 44px;
            width: 50px;
            text-align: center;
            border-left: 1px solid rgba(0,0,0,0.15);
            cursor: pointer;

            &:hover {
                background-color: rgba(0,0,0,0.05);
            }

            img {
                transform: scale(0.5);
                margin-top: 3px;
            }
        }
    }

    .tiles-and-filters {
        display: grid;
        grid-template-columns: 1fr 200px;

        @include from($screen-desktop) {
            height: $content-height-after-header-and-subheader;
            overflow: auto;
        }

        &.filters-hidden {
            grid-template-columns: 1fr;
            .filters {
                display: none;
            }
        }

        .job-tiles {
            width: 100%;
            display: grid;
            padding: 1rem;
            grid-gap: 1rem;
            align-content: start;
            justify-content: center;
            grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

            .job-tile-container {
                &.filtered {
                    display: none;
                }
            }
        }

        .filters {
            position: sticky;
            top: 0;
            height: $content-height-after-header-and-subheader;
            width: 200px;
            background-color: white;
            margin-top: 1px;
            padding: 1rem;
            right: 0;
            border-left: 1px solid rgba(0,0,0,0.1);
            box-shadow: 0 16px 30px 0 #00000080;
            overflow: auto;

            .filter-header {
                font-weight: bold;
            }

            .filter {
                padding-bottom: 1rem;

                .filter-name {
                    font-weight: 400;
                    color: #6a7b83;
                    padding-bottom: 0.25rem;
                    font-size: 14px;
                }

                .location {
                    .firstLine {
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}