@import "../../styles/common";

@keyframes pop {
    0% { opacity: 0; }
    5% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 0; }
}

.send-invite {
    position: relative;
    top: -1px;
    margin-left: 6px;

    > .label {
        text-decoration: underline;
        border: none;
        padding: 0;
        background-color: transparent;

        position: relative;
        top: 1px;
        left: -8px;

        svg {
            g {
                path {
                    fill: $grey-color;
                }
            }
        }

        &.mask {
            color: transparent;
            > * {
                opacity: 0;

            }
        }

        &.disabled-true {
            cursor: default;

            svg {
                g {
                    path {
                        fill: $disabled-color;
                    }
                }
            }
        }

        &.disabled-false {
            &:hover {
                cursor: pointer;

                svg {
                    g {
                        path {
                            fill: $primary-color;
                        }
                    }
                }
            }
        }
    }

    .react-tooltip {
        .description {
            padding-top: 0;
            padding-bottom: 0;
            top: 0px;
            right: 15px;
        }
    }

    .outcome {
        position: absolute;
        width: 100%;
        user-select: none;
        color: #00a3d1;
        display: none;
        top: 1px;
        left: 0;

        border: none;
        padding: 0;
        background-color: transparent;

        &.processing {
            display: inline-block;
        }

        &.success {
            display: inline-block;
            animation: pop 2s normal forwards;
        }

        &.error {
            display: inline-block;
            animation: pop 1s normal forwards;
            color: #f44336;
        }

        &.infinite {
            display: inline-block;
            animation: all 2s ease-in-out;
            text-decoration: underline;
        }
    }
}
