.reporting {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    /* Sigh, Materialize */
    h2 {
        font-size: 1.5em;
    }

    label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        input[type=date] {
            width: 200px;
        }

        input[type=text], select {
            width: 300px;
        }

        select {
            display: block;
            margin-bottom: 15px;
        }
    }

    .reporting-usage {
        margin: 3em;
        width: 400px;
        flex: 0 0 auto;

        .tenant-selection {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: auto auto 1fr;
        }

        .buttons {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
        }
    }
}
