@import "../../../styles/common";

.candidate-live-interview {
    --label-font-size: 16px;
    --value-font-size: 14px;

    padding: 1rem;
    margin-bottom: 1rem;
    background: white;
    border-radius: 6px;
    min-height: 20em;

    @include boxShadow();

    .evaluation-item {
        display: grid;
        --grid-gap: 1rem;
        grid-gap: var(--grid-gap);
        --total-width: calc(100% - var(--grid-gap));
        grid-template-columns: calc(var(--total-width)*2/3) calc(var(--total-width)/3);

        .recording {
            text-align: center;

            display: grid;
            grid-gap: 1rem;
            grid-template-rows: repeat(2, 1fr);
            justify-items: center;

            background-color: #333;
            color: white;

            min-height: 288px;

            .in-progress {
                font-size: 2em;
                align-self: end;
            }

            .wait-message {

            }
        }

        .interview-notes {
            .interview-notes-title {
                font-weight: 600;
                padding-bottom: 0.5rem;
            }
            .evaluator-comment-profile {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-template-rows: auto 1fr;
                grid-gap: 0 0.25rem;
                grid-template-areas:
                    "evaluator-avatar evaluator-name"
                    "blank evaluator-comment";
                margin-bottom: 0.5rem;
                align-items: center;

                .evaluator-name {
                    grid-area: evaluator-name;
                    font-weight: 400;
                    font-size: var(--label-font-size);
                }

                .evaluator-comment {
                    grid-area: evaluator-comment;
                    font-size: var(--value-font-size);
                }
            }
            .evaluator-comment-evaluation {
                textarea {
                    min-height: 20em;
                    height: calc(100% - 16px) !important;
                    resize: none;
                    overflow: auto !important;
                    background-color: $default-foreground-color;
                }
            }
        }
    }
}