@import "../../styles/common";

.interview-structure {
    .actions {
        text-align: right;
        margin-top: 16px;
    }

    .interview_id {
        display: none;
        position: relative;
        bottom: -2rem;
        font-size: 10px;
        color: $grey-color;
        height: 0;
    }
}